import { formatPrice } from "@/utils/formatPrice";
import moment from "moment";

interface Props {
  data?: any[];
}

const tableHead = [
  "Rewarded Date",
  "Invitetee",
  "Staking Profit",
  "Referral Profit",
];

function TableReferralProfit({ data }: Props) {
  return (
    <>
      {/**table pc */}
      <div className="mt-[25px] mb-[30px] sm:block hidden">
        <div className="border-0 border-b border-[#5F5F5F] border-solid pb-[11px] flex">
          {tableHead.map((dt, i) => (
            <div
              key={i}
              className="flex-1 text-center text-[#5F5F5F] text-[16px] w-[20%]"
            >
              {dt}
            </div>
          ))}
        </div>

        {data?.map((dt: any, i: number) => (
          <div key={i}>
            <div className="text-center text-[#fff] text-[16px] border-0 border-b border-[#5F5F5F] border-solid w-full flex items-center py-[15.5px]">
              <div className="px-[15px] w-[25%]">
                <div className="max-w-[100px] mx-auto text-left">
                  {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
              </div>
              <div className="px-[15px] w-[25%]">{dt?.inviteeUserName}</div>
              <div className="px-[15px] w-[25%]">
                {formatPrice(dt?.stakingExpectedProfit, 4)}
              </div>
              <div className="px-[15px] w-[25%]">
                {formatPrice(dt?.amountReward, 4)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/**end table pc */}
      {/**table mobile */}
      <div className="mt-[26px] mb-[28px] border-0 border-t border-solid border-[#2F2F2F] sm:hidden">
        {data?.map((dt: any, i: number) => (
          <div
            className="py-[20px] mt-[10px] border-0 border-solid border-b border-[#2F2F2F]"
            key={i}
          >
            <div className="flex">
              <div className="flex-1">
                <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                  End date
                </div>
                <div className="max-w-[100px] text-[14px]">
                  {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
              </div>
              <div className="flex-1">
                <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                  Invitetee
                </div>
                <div className="text-[14px]">{dt?.inviteeUserName}</div>
              </div>
            </div>
            <div className="flex pt-[15px]">
              <div className="flex-1">
                <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                  Staking Profit
                </div>
                <div className="text-[14px]">
                  {formatPrice(dt?.stakingExpectedProfit, 4)}
                </div>
              </div>
              <div className="flex-1">
                <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                  Referral Profit
                </div>
                <div className="text-[14px]">
                  {formatPrice(dt?.amountReward, 4)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/**end table mobile */}
    </>
  );
}

export default TableReferralProfit;
