import InputBase from "@/components/Input";
import InputPassword from "@/components/InputPassword";
import { Form, message, Select } from "antd";
import Logo from "@/assets/img/logo.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "@/state/hooks";
import { setLoading } from "@/state/globalSlice";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { UrlInternal } from "@/constant/endpointRoute";
import "react-phone-number-input/style.css";
// import PhoneInput, {
//   getCountries,
//   getCountryCallingCode,
// } from "react-phone-number-input";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";

function CountrySelect({ value, onChange, labels }: any) {
  return (
    <></>
    // <select
    //   value={value}
    //   onChange={(event) => onChange(event.target.value || undefined)}
    // >
    //   {getCountries().map((country) => (
    //     <option key={country} value={country}>
    //       {country} +{getCountryCallingCode(country)}
    //     </option>
    //   ))}
    // </select>
    // <SelectCoin
    //   // height={20}
    //   onChange={(value) => onChange(value || undefined)}
    //   value={value}
    //   className="custom-select w-fit max-w-[100px]"
    //   dropdownMatchSelectWidth={false}
    // >
    //   {getCountries()?.map((country) => (
    //     <Select.Option key={country} value={country}>
    //       <div className="text-[#fff] min-w-[5px] max-w-[20px] sm:text-[16px] text-[14px]">
    //         {country} +{getCountryCallingCode(country)}
    //       </div>
    //     </Select.Option>
    //   ))}
    // </SelectCoin>
  );
}

function SignUp() {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSendCode, setIsSendCode] = useState<any[]>([]);
  const [isSendCodePhone, setIsSendCodePhone] = useState<any[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  // const [isVerifyMail, setIsVerifyMail] = useState(false);
  // const [isVerifyPhone, setIsVerifyPhone] = useState(false);

  console.log("haa", searchParams.get("referralCode"));
  useEffect(() => {
    if (searchParams.get("referralCode")) {
      form.setFieldValue("referralCode", searchParams.get("referralCode"));
    }
  }, [searchParams]);

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;

    setDisabledSave(hasErrors);
  };

  const handleSendCodeEmail = async () => {
    const resCheck: any = await sendApiRequest({
      ...apiConfig.auth.checkExist,
      body: {
        fieldCheck: "email",
        valueCheck: form.getFieldValue("email"),
      },
    });
    if (resCheck?.data?.exists) {
      message.error("Email already exists");
    } else {
      dispatch(setLoading(true));
      try {
        const res: any = await sendApiRequest({
          ...apiConfig.auth.requestOTP,
          body: {
            source: form.getFieldValue("email"),
            sourceType: "mail",
          },
        });
        if (res?.result) {
          message.success("Send code email successful");
          setIsSendCode([
            ...isSendCode,
            {
              email: form.getFieldValue("email"),
              isVerify: false,
            },
          ]);
        }
      } catch (error) {
        console.error("Send code email failed:", error);
      }
      dispatch(setLoading(false));
    }
  };
  const handleVerifyCodeEmail = async () => {
    dispatch(setLoading(true));
    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.verifyOTP,
        body: {
          sourceCheck: form.getFieldValue("email"),
          sourceType: "mail",
          otp: form.getFieldValue("verifyCode"),
        },
      });
      if (res?.data?.otpValid) {
        message.success("Verify code successful");
        setIsSendCode((prevState) =>
          prevState.map((item) =>
            item.email === form.getFieldValue("email")
              ? { ...item, isVerify: true }
              : item
          )
        );
        // setIsVerifyMail(true);
      } else {
        message.error("Verify code failed");
      }
    } catch (error) {
      console.error("Send code email failed:", error);
    }
    dispatch(setLoading(false));
  };
  const handleSendCodePhone = async () => {
    const resCheck: any = await sendApiRequest({
      ...apiConfig.auth.checkExist,
      body: {
        fieldCheck: "phone",
        valueCheck: form.getFieldValue("phoneNumber"),
      },
    });
    if (resCheck?.data?.exists) {
      message.error("Phone already exists");
    } else {
      dispatch(setLoading(true));
      try {
        const res: any = await sendApiRequest({
          ...apiConfig.auth.requestOTP,
          body: {
            source: form.getFieldValue("phoneNumber"),
            sourceType: "phone",
          },
        });
        if (res?.result) {
          message.success("Send code phone successful");
          setIsSendCodePhone([
            ...isSendCodePhone,
            {
              phoneNumber: form.getFieldValue("phoneNumber"),
              isVerify: false,
            },
          ]);
        }
      } catch (error) {
        console.error("Send code phone failed:", error);
      }
      dispatch(setLoading(false));
    }
  };
  const handleVerifyCodePhone = async () => {
    dispatch(setLoading(true));
    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.verifyOTP,
        body: {
          sourceCheck: form.getFieldValue("phoneNumber"),
          sourceType: "phone",
          otp: form.getFieldValue("verifyCodePhone"),
        },
      });
      if (res?.data?.otpValid) {
        message.success("Verify code successful");
        // setIsVerifyPhone(true);
        setIsSendCodePhone((prevState) =>
          prevState.map((item) =>
            item.phoneNumber === form.getFieldValue("phoneNumber")
              ? { ...item, isVerify: true }
              : item
          )
        );
      } else {
        message.error("Verify code failed");
      }
    } catch (error) {
      console.error("Send code email failed:", error);
    }
    dispatch(setLoading(false));
  };

  const onFinish = async (values: any) => {
    if (values.password !== values.rePassword) {
      message.error("Password not match");
      return;
    }

    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.registerAuth,
        body: {
          email: values.email,
          password: values.password,
          emailOtp: values.verifyCode,
          referralCode: values.referralCode,
          phone: values.phoneNumber,
          phoneOtp: values.verifyCodePhone,
        },
      });

      if (res?.result) {
        message.success("Sign up successful");
        navigate(UrlInternal.LOGIN);
      }
    } catch (error) {
      console.error("Sign up failed:", error);
    }
  };

  const handlePhoneChange = (value: string, country: any) => {
    // Thêm dấu "+" vào đầu nếu thiếu
    const formattedValue = value.startsWith("+") ? value : `+${value}`;

    // Dùng libphonenumber-js để chuẩn hóa về E.164
    const parsedNumber = parsePhoneNumberFromString(
      formattedValue,
      country?.countryCode
    );
    const e164Number = parsedNumber ? parsedNumber.number : formattedValue;

    form.setFieldsValue({ phoneNumber: e164Number.toString().trim() });
    setPhoneNumber(e164Number.toString().trim());
  };

  return (
    <div className="my-[30px]">
      <div className="sm:flex hidden justify-center mb-[44px]">
        <img src={Logo} alt="img" />
      </div>
      <div className="flex justify-center pb-[50px]">
        <div
          className={`bg-gradient sm:px-[49px] px-[16px] sm:pt-[54px] pb-[62px] overflow-y-auto rounded-[50px] sm:border sm:border-[#FFFFFF40] sm:border-solid sm:w-[550px]`}
        >
          <div className="text-[32px] font-montserrat700 text-center sm:mb-[59px] mb-[56px]">
            Sign Up
          </div>
          <Form
            form={form}
            onFieldsChange={handleFormChange}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
              label="Email"
            >
              <InputBase
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                suffix={
                  <button
                    type="button"
                    onClick={handleSendCodeEmail}
                    disabled={
                      email.length <= 0 ||
                      isSendCode.find((item) => item.email === email)?.email
                    }
                    className="h-[40px] w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] mr-[-16px] cursor-pointer disabled:bg-[#4B4D47]"
                  >
                    Send
                  </button>
                }
              />
            </Form.Item>
            <Form.Item
              label="Verify Code"
              name="verifyCode"
              rules={[
                { required: true, message: "Please input your verify code!" },
              ]}
            >
              <InputBase
                type="text"
                placeholder="Verify Code"
                suffix={
                  <button
                    disabled={
                      isSendCode.find((item) => item.email === email)?.isVerify
                    }
                    onClick={handleVerifyCodeEmail}
                    type="button"
                    className="h-[40px] w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] mr-[-16px] cursor-pointer disabled:bg-[#4B4D47]"
                  >
                    {isSendCode.find((item) => item.email === email)?.isVerify
                      ? "Verified"
                      : "Verify"}
                  </button>
                }
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              rules={[
                { required: true, message: "Please input your phone number!" },
              ]}
              label="Phone Number"
            >
              <div className="border border-solid border-[#FFFFFF59] rounded-[48px] flex w-full items-center h-[60px] pr-[8px] pl-[15px] input-country">
                <PhoneInput
                  country={"us"}
                  value={phoneNumber}
                  // onChange={(value) => {
                  //   console.log("value", value);
                  //   setPhoneNumber(value);
                  //   form.setFieldsValue({ phoneNumber: value });
                  // }}
                  onChange={handlePhoneChange}
                  enableSearch
                  // limitMaxLength={true}
                  prefix="+"
                />
                <button
                  type="button"
                  onClick={handleSendCodePhone}
                  disabled={
                    phoneNumber?.length <= 0 ||
                    isSendCodePhone.find(
                      (item) => item.phoneNumber === phoneNumber
                    )?.phoneNumber
                  }
                  className="h-[40px] min-w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47]"
                >
                  Send
                </button>
                {/* <PhoneInput
                  country="US"
                  value={phoneNumber}
                  onChange={(value) => {
                    console.log("value", value);

                    setPhoneNumber(value);
                    form.setFieldsValue({ phoneNumber: value });
                  }}
                  placeholder="Phone Number"
                  className="flex-1 gap-[12px]"
                  limitMaxLength
                  // displayInitialValueAsLocalNumber={false}
                  // international
                  countrySelectComponent={CountrySelect}
                />
                <button
                  type="button"
                  onClick={handleSendCodePhone}
                  disabled={
                    phoneNumber?.length <= 0 ||
                    isSendCodePhone.find(
                      (item) => item.phoneNumber === phoneNumber
                    )?.phoneNumber
                  }
                  className="h-[40px] w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47]"
                >
                  Send
                </button> */}
              </div>
            </Form.Item>
            <Form.Item
              label="Verify Code"
              name="verifyCodePhone"
              rules={[
                { required: true, message: "Please input your verify code!" },
              ]}
            >
              <InputBase
                type="text"
                placeholder="Verify Code"
                suffix={
                  <button
                    disabled={
                      isSendCodePhone.find(
                        (item) => item.phoneNumber === phoneNumber
                      )?.isVerify
                    }
                    onClick={handleVerifyCodePhone}
                    type="button"
                    className="h-[40px] w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] mr-[-16px] cursor-pointer disabled:bg-[#4B4D47]"
                  >
                    {isSendCodePhone.find(
                      (item) => item.phoneNumber === phoneNumber
                    )?.isVerify
                      ? "Verified"
                      : "Verify"}
                  </button>
                }
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <InputPassword placeholder="Password" />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="rePassword"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <InputPassword placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item
              label="Referral Code"
              name="referralCode"
              // rules={[
              //   { required: true, message: "Please input your referral code!" },
              // ]}
            >
              <InputBase type="text" placeholder="Referral Code" />
            </Form.Item>
            <Form.Item>
              <button
                // disabled={disabledSave}
                type="submit"
                className="sm:mt-[20px] mt-[25px] w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer
              disabled:bg-[#4B4D47] disabled:border-[#4B4D47] disabled:text-[#2C2B2B]"
              >
                Confirm
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
