import moment from "moment";
import ImgUsdt from "@/assets/img/usdt.png";
import Collapse from "./Collapse";
import { formatPrice } from "@/utils/formatPrice";
import { useAppSelector } from "@/state/hooks";
import { coinsSelect } from "@/state/coinSlice";

interface Props {
  data?: any[];
}

const tableHead = ["Earn Date", "Coins", "Amount", "APR", "Duration", "Status"];

function TableEarn({ data }: Props) {
  const coins = useAppSelector(coinsSelect);

  const handleGetIconCoin = (assetSymbol: string) => {
    const coin = coins.find((coin: any) => coin.assetSymbol === assetSymbol);

    return coin?.assetIcon;
  };

  return (
    <>
      {/**table pc */}
      <div className="mt-[25px] mb-[30px] sm:block hidden">
        <div className="border-0 border-b border-[#5F5F5F] border-solid pb-[11px] flex">
          {tableHead.map((dt, i) => (
            <div
              key={i}
              className="flex-1 text-center text-[#5F5F5F] text-[16px] w-[20%]"
            >
              {dt}
            </div>
          ))}
        </div>

        {data?.map((dt, i) => (
          <div key={i}>
            <div className="text-center text-[#fff] text-[16px] border-0 border-b border-[#5F5F5F] border-solid w-full flex items-center py-[15.5px]">
              <div className="px-[15px] w-[16.6666%]">
                <div className="max-w-[100px] mx-auto text-left">
                  {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
              </div>
              <div className="flex gap-[10px] items-center px-[15px] w-[16.6666%] justify-center">
                <img
                  src={handleGetIconCoin(dt?.assetType)}
                  alt="usdt"
                  className="max-w-[28px] w-full aspect-square"
                />
                <div>USDT</div>
              </div>
              <div className="px-[15px] w-[16.6666%]">
                {formatPrice(dt?.stakingAmount)}
              </div>
              <div className="px-[15px] w-[16.6666%]">
                {formatPrice(dt?.apr * 100)}%
              </div>
              <div className="px-[15px] w-[16.6666%]">
                {dt?.daysDuration} days
              </div>
              <div className="px-[15px] w-[16.6666%]">
                {dt?.status === 100 ? "Rewarded" : "Earning"}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/**end table pc */}
      {/**table mobile */}
      <div className="mt-[26px] mb-[28px] border-0 border-t border-solid border-[#2F2F2F] sm:hidden">
        {data?.map((dt, i) => (
          <Collapse key={i} dt={dt} />
        ))}
      </div>
      {/**end table mobile */}
    </>
  );
}

export default TableEarn;
