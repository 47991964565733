import { useRef } from "react";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import NextIcon from "@/assets/images/home/CaretRight.png";
import PrevIcon from "@/assets/images/home/CaretLeft.png";
import { formatPrice } from "@/utils/formatPrice";
import TagRank from "../TagRank";

type Props = {
  data: any;
  dataClick: any;
  onClick: (data: any) => void;
};

function SliderStartEarning({ data, dataClick, onClick }: Props) {
  const swiperRef: any = useRef();

  return (
    <div className="relative mb-[32px]">
      <Swiper
        className="swiper-hightlight"
        pagination={false}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        slidesPerView={data?.length > 3 ? 3.3 : 3}
        spaceBetween={12}
        // breakpoints={{
        //   320: {
        //     slidesPerView: data?.length === 1 ? 1 : 1.2,
        //     spaceBetween: 12,
        //   },
        //   1024: {
        //     slidesPerView: 3,
        //     spaceBetween: 12,
        //     // slidesPerGroupSkip: 4,
        //   },
        // }}
      >
        {data?.map((item: any, key: number) => (
          <SwiperSlide key={key}>
            <div
              className={`bg-gradient-hightlight px-[16px] py-[12px] rounded-[12px] border border-solid flex flex-col h-full cursor-pointer ${
                item?.id === dataClick?.id
                  ? "border-[#B0F127] border-[1.5px]"
                  : "border-[#FFFFFF40]"
              }`}
              onClick={() => onClick(item)}
            >
              <div className="text-[#EAECEF] text-[14px] font-montserrat500">
                {item?.daysDuration} days
              </div>
              <div className="text-[#B0F127] font-montserrat700 text-[18px] mt-[4px]">
                {formatPrice(item?.apr * 100)}%
              </div>
              {item?.limitMembershipLevel > 0 && (
                <div className="mt-[4px]">
                  <TagRank
                    level={item?.limitMembershipLevel}
                    limitMembershipGradeCondition={
                      item?.limitMembershipGradeCondition
                    }
                    className="!justify-start"
                  />
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {data?.length > 3 && (
        <div className="md:block hidden">
          <div
            onClick={() => swiperRef.current?.slidePrev()}
            className="absolute top-1/2 left-[-30px] -translate-y-1/2 cursor-pointer"
          >
            <img src={PrevIcon} alt="prev" className="max-w-[16px]" />
          </div>
          <div
            onClick={() => swiperRef.current?.slideNext()}
            className="absolute top-1/2 right-[-30px] -translate-y-1/2 cursor-pointer"
          >
            <img src={NextIcon} alt="next" className="max-w-[16px]" />
          </div>
        </div>
      )}
    </div>
  );
}

export default SliderStartEarning;
