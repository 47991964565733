import benefits from "@/assets/images/benefits.png";
import bgr from "@/assets/images/bgr-benefits.png";
import bgrMobile from "@/assets/images/bgr-benefits-mobile.png";

function Benefits() {
  return (
    <div
      className="bg-[#141414] bg-right bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${bgr})` }}
    >
      <div className="max-w-[1364px] px-4 w-full flex flex-col md:flex-row items-center justify-between pt-[86px] md:pt-0 m-auto gap-3">
        <div className="flex-1 md:pl-[26px]">
          <div className="text-[35px] md:text-[54px] font-[600] md:text-left text-center mb-5">
            Defylo’s <span className="text-[#B0F127]">Multi-Layered</span>{" "}
            Security Approach
          </div>
          <div className="text-[16px] md:text-[18px] font-[400] text-[#C0C0C0] md:text-left text-center">
            Defylo protects your assets through real-time monitoring, secure
            systems, and trusted connections with global exchanges — all powered
            by algorithms that have delivered results for years.
          </div>
        </div>
        <div className="w-full max-w-[788px] md:mr-[-86px] mt-[43px] md:mt-0">
          <img src={benefits} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Benefits;
