import sendApiRequest from "@/axios/requestApi";
import NotFound from "@/components/NotFound";
import { apiConfig } from "@/constant/apiConfig";
import { formatPrice } from "@/utils/formatPrice";
import moment from "moment";
import { useEffect, useState } from "react";

interface Props {
  data?: any[];
}

const tableHead = ["Membership NFT", "Price", "Extra Profit", "Purchased Date"];

function TableMembershipNFT() {
  const [dataTable, setDataTable] = useState<any>({});

  const getListMembership = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.membershipNft.getList,
        body: {
          page: 1,
          size: 30,
          querySearch: "",
        },
      });
      if (resp.result) {
        setDataTable(resp.data);
      }
    } catch (error) {
      setDataTable({});
    }
  };

  useEffect(() => {
    getListMembership();
  }, []);

  return (
    <div className="xl:px-0 px-[16px]">
      <div className="sm:text-[32px] text-[24px] font-montserrat700 leading-none">
        Membership NFT
      </div>

      {dataTable?.datas?.length > 0 ? (
        <>
          {/**table pc */}
          <div className="mt-[36px] mb-[164px] sm:block hidden">
            <div className="border-0 border-b border-[#252525] border-solid pb-[11px] flex">
              {tableHead.map((dt, i) => (
                <div
                  key={i}
                  className="flex-1 text-center text-[#5F5F5F] text-[16px] w-[20%]"
                >
                  {dt}
                </div>
              ))}
            </div>

            {dataTable?.datas?.map((dt: any, i: number) => (
              <div key={i}>
                <div className="text-center text-[#fff] text-[16px] border-0 border-b border-[#252525] border-solid w-full flex items-center py-[15.5px]">
                  <div className="px-[15px] w-[25%]">{dt?.productName}</div>
                  <div className="px-[15px] w-[25%]">
                    {formatPrice(dt?.buyAmount, 4)}
                  </div>
                  <div className="px-[15px] w-[25%]">
                    {formatPrice(dt?.bonusStakingIncrease * 100, 4)}%
                  </div>
                  <div className="px-[15px] w-[25%]">
                    {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/**end table pc */}
          {/**table mobile */}
          <div className="mb-[87px] sm:hidden">
            {dataTable?.datas?.map((dt: any, i: number) => (
              <div
                className="py-[20px] border-0 border-solid border-b border-[#2F2F2F]"
                key={i}
              >
                <div className="flex">
                  <div className="flex-1">
                    <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                      Membership NFT
                    </div>
                    <div className="max-w-[100px] text-[14px]">
                      {dt?.productName}
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                      Price
                    </div>
                    <div className="text-[14px]">
                      {formatPrice(dt?.buyAmount, 4)}
                    </div>
                  </div>
                </div>
                <div className="flex pt-[15px]">
                  <div className="flex-1">
                    <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                      Extra Profit
                    </div>
                    <div className="text-[14px]">
                      {formatPrice(dt?.bonusStakingIncrease * 100, 4)}%
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                      Purchased Date
                    </div>
                    <div className="text-[14px]">
                      {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/**end table mobile */}
        </>
      ) : (
        <NotFound className="!py-[30px]" />
      )}
    </div>
  );
}

export default TableMembershipNFT;
