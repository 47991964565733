import ModalBase from "@/components/ModalBase";
import ImgBg from "@/assets/images/success-convert.png";

interface Props {
  isVisible: boolean;
  setCancel: () => void;
  fromAmount: string;
  toAmount: string;
  rate: string;
  fee: string;
}

function ModalSuccessConvert({
  isVisible,
  setCancel,
  fee,
  fromAmount,
  toAmount,
  rate,
}: Props) {
  return (
    <ModalBase isVisible={isVisible} setCancel={setCancel} title="Successful">
      <div className="flex items-center justify-center gap-[25px] mt-[48px] sm:mb-[25px] mb-[20px]">
        <img src={ImgBg} alt="img" className="sm:max-w-max max-w-[76px]" />
        <div className="text-[#B0F127] sm:text-[45px] text-[40px] font-montserrat700">
          {fromAmount}
        </div>
      </div>

      <div className=" mb-[30px] flex flex-col gap-[15px] leading-none">
        <div className="flex items-center justify-between">
          <div className="font-montserrat500 text-[#9F9F9F]">You received</div>
          <div className="sm:text-[19px] font-montserrat500">{toAmount}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="font-montserrat500 text-[#9F9F9F]">Rate</div>
          <div className="sm:text-[19px] font-montserrat500">{rate}</div>
        </div>
        <div className="flex items-center justify-between">
          <div className="font-montserrat500 text-[#9F9F9F]">Fee</div>
          <div className="sm:text-[19px] font-montserrat500">{fee}</div>
        </div>
      </div>

      <button
        onClick={setCancel}
        type="button"
        className="sm:mb-0 mb-[30px] w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] text-[18px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
      >
        Close
      </button>
    </ModalBase>
  );
}

export default ModalSuccessConvert;
