import InputBase from "@/components/Input";
import InputPassword from "@/components/InputPassword";
import { Form, message } from "antd";
import Logo from "@/assets/img/logo.png";
import StartEarning from "@/components/StartEarning";
import Earning from "@/components/Earning";
import GetProfit from "@/components/GetProfit";
import WithDraw from "@/components/WithDraw";
import Deposit from "@/components/Deposit";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/state/hooks";
import { setLoading } from "@/state/globalSlice";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { handleSignIn } from "@/state/authSlice";
import { UrlInternal } from "@/constant/endpointRoute";
import { useState } from "react";

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onFinish = async (values: any) => {
    dispatch(setLoading(true));
    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.loginAuth,
        body: {
          userName: values.email,
          password: values.password,
        },
      });
      if (res?.result) {
        dispatch(
          handleSignIn({
            isAuth: true,
            token: res?.data?.accessToken,
            dataUser: res?.data,
          })
        );
        message.success("Login successful");
        navigate(UrlInternal.HOME);
      }
    } catch (error) {
      dispatch(
        handleSignIn({
          isAuth: true,
          token: null,
          dataUser: {},
        })
      );
      message.error("Login failed");
      console.error("Login failed:", error);
    }
    dispatch(setLoading(false));
  };

  return (
    <div className="flex items-center justify-center h-screen flex-col">
      <div className="sm:flex hidden justify-center mb-[44px]">
        <img src={Logo} alt="img" />
      </div>

      <div
        className={`bg-gradient sm:px-[49px] px-[16px] sm:pt-[54px] pb-[80px] overflow-y-auto rounded-[50px] sm:border sm:border-[#FFFFFF40] sm:border-solid sm:w-[500px]`}
      >
        <div className="text-[32px] font-montserrat700 text-center sm:mb-[49px] mb-[86px]">
          Login
        </div>
        <Form onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <InputBase type="email" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <InputPassword placeholder="Password" />
          </Form.Item>

          <div className="flex items-center justify-end mt-[5px] leading-none">
            <div
              className="text-[#C0C0C0] text-[14px] font-montserrat500 pr-[10px] border-0 border-r border-solid border-[#C0C0C0] cursor-pointer"
              onClick={() => navigate(UrlInternal.FORGOT_PASSWORD)}
            >
              Forgot Password
            </div>
            <div
              className="text-[#C0C0C0] text-[14px] font-montserrat500 pl-[10px] cursor-pointer"
              onClick={() => navigate(UrlInternal.FORGOT_ID)}
            >
              Forgot Email
            </div>
          </div>

          <Form.Item>
            <button
              type="submit"
              className="sm:mt-[35px] mt-[25px] w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              Login
            </button>
          </Form.Item>
        </Form>

        <div className="text-[16px] font-montserrat700 text-[#A4A4A4] text-center">
          Don’t you have an account?{" "}
          <span
            className="text-[#B0F127] cursor-pointer"
            onClick={() => navigate(UrlInternal.SIGNUP)}
          >
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
}

export default Login;
