import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { UrlInternal } from "@/constant/endpointRoute";
import { isAuthSelect } from "@/state/authSlice";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Balance from "./Balance";
import EstimateBalance from "./EstimateBalance";
import Filter from "./Filter";
import HightProduct from "./HightProduct";
import ListCoin from "./ListCoin";
import { dataBalanceSelect, getDataBalance } from "@/state/dataBalanceSlice";

function Earn() {
  // const [dataBalance, setDataBalance] = useState<any>({});
  const isAuth = useAppSelector(isAuthSelect);
  const dataBalance = useAppSelector(dataBalanceSelect);
  const navigate = useNavigate();
  const [dataHightLight, setDataHightLight] = useState<any>([]);
  const dispatch = useAppDispatch();

  // const getListStatistic = async () => {
  //   try {
  //     const resp: any = await sendApiRequest({
  //       ...apiConfig.user.getStatistic,
  //     });
  //     if (resp?.result) {
  //       setDataBalance(resp.data);
  //     }
  //   } catch (error) {
  //     console.log();
  //     setDataBalance({});
  //   }
  // };

  const getListProducts = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.products.getList,
        body: {
          page: 1,
          size: 9999,
          onlyHighlight: 1,
        },
      });
      if (resp?.result) {
        setDataHightLight(resp.data?.datas);
      }
    } catch (error) {
      setDataHightLight([]);
    }
  };

  useEffect(() => {
    getListProducts();

    dispatch(getDataBalance());
  }, []);

  return (
    <div className="bg-[#141414]" id="Earn">
      <div className="max-w-[1312px] px-4 py-[80px] md:py-[160px] w-full bg-[#141414] m-auto">
        <div className="text-[35px] md:text-[54px] md:leading-[66px] font-[600] text-center mb-[45px]">
          Earn up to <span className="text-[#B0F127]">60% APR</span> on your
          crypto
        </div>

        {dataBalance?.userId && isAuth && (
          <>
            <EstimateBalance dataBalance={dataBalance} />
            <Balance dataBalance={dataBalance} />
          </>
        )}

        {!isAuth && (
          <div className="flex justify-center items-center gap-[24px] mb-[50px]">
            <button
              onClick={() => navigate(UrlInternal.LOGIN)}
              type="button"
              className="max-w-[180px] sm:h-[60px] h-[36px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] sm:flex-1 sm:text-[18px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
            >
              Sign in
            </button>
            <button
              onClick={() => navigate(UrlInternal.SIGNUP)}
              type="button"
              className="max-w-[180px] sm:h-[60px] h-[36px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] sm:flex-1 sm:text-[18px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              Sign up
            </button>
          </div>
        )}

        {dataHightLight?.length > 0 && (
          <>
            <div className="text-[24px] md:text-[31px] md:leading-[40px] font-[600] mt-[45px] mb-[28px]">
              Highlights
            </div>
            <div>
              <HightProduct
                data={dataHightLight}
                getListProducts={getListProducts}
              />
            </div>
          </>
        )}

        <div className="text-[24px] md:text-[31px] md:leading-[40px] font-[600] mt-[40px]">
          Popular Products
        </div>
        <div className="my-6">
          <Filter />
        </div>
        <div>
          <ListCoin />
        </div>
      </div>
    </div>
  );
}

export default Earn;
