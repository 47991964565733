// src/store/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface CoinState {
  data: any;
}

const initialState: CoinState = {
  data: [],
};

const authSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    setListCoin: (state, action: PayloadAction<CoinState>) => {
      state.data = action.payload;
    },
    deleteListCoin: (state) => {
      state.data = [];
    },
  },
});

export const { setListCoin, deleteListCoin } = authSlice.actions;

export const coinsSelect = (state: RootState) => state.coins.data;

export default authSlice.reducer;
