import moment from "moment";
import Collapse from "./Collapse";
import { formatPrice } from "@/utils/formatPrice";

interface Props {
  data: any;
}

function TableConvert({ data }: Props) {
  return (
    <>
      {/**table pc */}
      <div className="mt-[25px] mb-[30px] md:block hidden">
        <div className="border-0 border-b border-[#5F5F5F] border-solid pb-[11px] flex">
          <div className="flex text-center text-[#5F5F5F] text-[16px] flex-1">
            <div className="w-[11%]">Date</div>
            <div className="w-[17%]">Coins</div>
            <div className="w-[15%]">From</div>
            <div className="w-[15%]">To</div>
            <div className="w-[28%]">Rate</div>
            <div className="w-[14%]">Fee</div>
          </div>
        </div>

        {data?.map((dt: any, i: number) => (
          <div key={i}>
            <div className="text-center text-[#fff] text-[16px] border-0 border-b border-[#5F5F5F] border-solid w-full flex items-center py-[15.5px] break-words">
              <div className="lg:px-0 px-[10px] w-[11%]">
                <div className="max-w-[100px] mx-auto text-left">
                  {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
              </div>
              <div className="lg:px-0 px-[10px] w-[17%]">
                <div className="flex justify-center items-center">
                  <div className="max-w-[82px] w-full h-[40px] leading-[40px] border-0 border-r border-solid border-[#2E2E2E]">
                    {dt?.fromAsset}
                  </div>
                  <div className="max-w-[82px] w-full h-[40px] leading-[40px]">
                    {dt?.toAsset}
                  </div>
                </div>
              </div>
              <div className="lg:px-0 px-[10px] w-[15%]">
                {formatPrice(dt?.fromAmount, 4)} {dt?.fromAsset}
              </div>
              <div className="lg:px-0 px-[10px] w-[15%]">
                {formatPrice(dt?.toAmount, 4)} {dt?.toAsset}
              </div>
              <div className="lg:px-[20px] px-0 w-[28%] text-left mx-auto">
                <div>
                  {formatPrice(1, 4)} {dt?.fromAsset}={" "}
                  {formatPrice(1 * dt?.ratio, 8)} {dt?.toAsset}
                </div>
                <div>
                  {formatPrice(1, 4)} {dt?.toAsset}={" "}
                  {formatPrice(1 * dt?.ratio, 8)} {dt?.fromAsset}
                </div>
              </div>
              <div className="lg:px-0 px-[10px] w-[14%]">
                {formatPrice(dt?.feeAmount, 4)} {dt?.feeAsset}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/**end table pc */}
      {/**table mobile */}
      <div className="mt-[26px] mb-[28px] md:hidden">
        {data?.map((dt: any, i: number) => (
          <Collapse key={i} dt={dt} />
        ))}
      </div>
      {/**end table mobile */}
    </>
  );
}

export default TableConvert;
