import ImgTop from "@/assets/img/top.png";
import ImgBottom from "@/assets/img/bottom.png";
import moment from "moment";
import ImgUsdt from "@/assets/img/usdt.png";
import { useState } from "react";
import ImgBnb from "@/assets/img/bnb.png";
import ImgCopy from "@/assets/img/Copy.png";
import { message } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatPrice } from "@/utils/formatPrice";

interface Props {
  dt: any;
}

function Collapse({ dt }: Props) {
  const [isShow, setIsShow] = useState(false);

  const handleStatus = (status: string) => {
    switch (status) {
      case "WAITING":
        return (
          <div className="text-[#060606] text-[13px] px-[8px] py-[2px] bg-[#F1BB27] rounded-[4px] inline-block">
            Waiting
          </div>
        );
      case "COMPLETE":
        return (
          <div className="text-[#060606] text-[13px] px-[8px] py-[2px] bg-[#B0F127] rounded-[4px] inline-block">
            Complete
          </div>
        );

      default:
        return;
    }
  };

  return (
    <div className="py-[20px] border-0 border-solid border-b border-[#2F2F2F]">
      <div className="cursor-pointer" onClick={() => setIsShow(!isShow)}>
        <div className="mb-[8px]">
          {handleStatus(dt?.status === "WAITING" ? "WAITING" : "COMPLETE")}
        </div>

        <div className="flex justify-between items-center">
          <div className="flex items-center gap-[10px]">
            <img
              src={dt?.assetIcon}
              alt="usdt"
              className="max-w-[28px] w-full aspect-square"
            />
            <div className="text-[16px] text-[#EAECEF] font-montserrat500">
              {dt?.symbol}
            </div>
          </div>
          <div className="text-[20px] font-montserrat600">
            {formatPrice(dt?.receivedAmount)}
          </div>
        </div>
        <div className="flex justify-between items-center mt-[8px]">
          <div className="text-[#C0C0C0] text-[13px]">
            {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
          </div>
          <div className="flex items-center">
            <img src={!isShow ? ImgBottom : ImgTop} alt="top" />
          </div>
        </div>
      </div>
      {isShow && (
        <div className="p-[16px] mt-[15px] flex flex-col gap-[15px] bg-[#141414] rounded-[12px]">
          <div className="flex justify-between items-center leading-[28px]">
            <div className="text-[13px] text-[#C0C0C0]">Network</div>
            <div className="flex gap-[10px] items-center justify-center">
              <img
                src={dt?.networkIcon}
                alt="bnb"
                className="max-w-[28px] w-full aspect-square"
              />
              <div>{dt?.networkSymbol}</div>
            </div>
          </div>
          <div className="flex justify-between items-center leading-[28px]">
            <div className="text-[13px] text-[#C0C0C0]">Withdraw Fee</div>
            <div className="text-[16px]">{formatPrice(dt?.withdrawFee, 4)}</div>
          </div>
          <div className="flex justify-between items-center leading-[28px]">
            <div className="text-[13px] text-[#C0C0C0]">From</div>
            <div className="text-[16px] underline">
              {dt?.fromAddress && (
                <>
                  {dt?.fromAddress?.substring(0, 5)}...
                  {dt?.fromAddress?.substring(dt?.fromAddress?.length - 5)}
                </>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center leading-[28px]">
            <div className="text-[13px] text-[#C0C0C0]">To</div>
            <div className="text-[16px] underline">
              {dt?.toAddress && (
                <>
                  {dt?.toAddress?.substring(0, 5)}...
                  {dt?.toAddress?.substring(dt?.toAddress?.length - 5)}
                </>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center leading-[28px]">
            <div className="text-[13px] text-[#C0C0C0]">TxID</div>
            {dt?.txHash && (
              <div className="flex gap-[10px] items-center">
                <div className="text-[#27DDF1]">
                  {dt?.txHash?.substring(0, 5)}...
                  {dt?.txHash?.substring(dt?.txHash?.length - 5)}
                </div>

                <CopyToClipboard
                  text={dt?.txHash}
                  onCopy={() => message.success("Copied")}
                >
                  <img
                    src={ImgCopy}
                    alt="copy"
                    className="cursor-pointer mx-auto"
                  />
                </CopyToClipboard>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Collapse;
