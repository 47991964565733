import { ConfigProvider, DatePicker, DatePickerProps } from "antd";
import "./style.scss";

import ImgCalendar from "@/assets/images/calendar.png";
import ImgClose from "@/assets/images/close.svg?react";
import dayjs from "dayjs";

interface Props {
  getData?: (e: string) => void;
  value?: string | null;
  format?: string;
}

function DatePickerCustom({ getData, value, format }: Props) {
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    // console.log({ date, dateString });
    getData?.(String(dateString));
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            activeBg: "transparent",
            activeBorderColor: "#FFFFFF40",
            hoverBorderColor: "#FFFFFF40",
            activeShadow: undefined,
            colorBgContainer: "transparent",
            colorBorder: "#FFFFFF40",
            colorTextPlaceholder: "#EAECEF",
            borderRadius: 18,
            controlHeight: 48,
            fontSize: 16,
            colorText: "#EAECEF",
            colorPrimary: "#B0F127",
            colorTextHeading: "#fff",
            colorIcon: "#9C9C9C",
          },
        },
      }}
    >
      <DatePicker
        onChange={onChange}
        className="sm:max-w-[168px] max-w-[153px]"
        suffixIcon={<img src={ImgCalendar} alt="img" />}
        allowClear={{
          clearIcon: <ImgClose />,
        }}
        value={value ? dayjs(value, format) : null}
        format={format}
        // open
      />
    </ConfigProvider>
  );
}

export default DatePickerCustom;
