import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { dataUserSelect, isAuthSelect } from "@/state/authSlice";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { formatPrice } from "@/utils/formatPrice";
import { Form, message } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import CheckRank from "../CheckRank";
import InputBase from "../Input";
import ModalBase from "../ModalBase";
import SliderStartEarning from "../SliderStartEarning";
import TooltipIcon from "../TooltipIcon";
import { useNavigate } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import { getDataBalance } from "@/state/dataBalanceSlice";

interface Props {
  isVisible: boolean;
  setCancel?: () => void;
  data: any;
  onSuccess?: () => void;
}

function StartEarning({ isVisible, setCancel, data, onSuccess }: Props) {
  const [form] = Form.useForm();
  const [amount, setAmount] = useState(0);
  const isAuth = useAppSelector(isAuthSelect);
  const dataUser = useAppSelector(dataUserSelect);
  const [dataActive, setDataActive] = useState(data);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const balanceUsdt = useMemo(() => {
    const findItem = dataUser?.balances?.find(
      (i: { asset_type: string }) => i?.asset_type === "USDT"
    );
    return findItem?.balance || 0;
  }, [dataUser]);

  const handleCancel = () => {
    form.resetFields();
    setCancel?.();
    setAmount(0);
  };

  useEffect(() => {
    if (data) {
      setDataActive(data);
      form.setFieldsValue({
        productName: data?.productName,
      });
    }
  }, [data]);

  const onFinish = async (values: any) => {
    if (
      values.amount < dataActive?.minStaking ||
      (dataActive?.maxStaking && values.amount > dataActive?.maxStaking) ||
      values.amount > dataActive?.limitTotalStaking - dataActive?.totalStaking
    ) {
      if (
        values.amount >
        dataActive?.limitTotalStaking - dataActive?.totalStaking
      ) {
        message.error("You cannot stake over the stake limit");
      } else {
        message.error("The amount entered does not satisfy the condition");
      }
    } else {
      try {
        const resp: any = await sendApiRequest({
          ...apiConfig.modal.startEarning,
          body: {
            productId: dataActive?.id,
            amount: values.amount,
          },
        });
        if (resp?.result) {
          dispatch(getDataBalance());

          message.success("Start earning successful");
          handleCancel();
          onSuccess?.();
          setCancel?.();
        }
      } catch (error) {
        console.log();
      }
    }
  };

  return (
    <ModalBase
      isVisible={isVisible}
      setCancel={handleCancel}
      title="Start Earning"
    >
      <div className="pt-[40px] sm:pb-0 pb-[50px]">
        <SliderStartEarning
          dataClick={dataActive}
          data={data?.listStartEarning}
          // data={
          //   data?.listStartEarning
          //     ? [...data.listStartEarning, ...data.listStartEarning]
          //     : []
          // }
          onClick={(value) => setDataActive(value)}
        />

        <Form onFinish={onFinish} form={form}>
          <div className="flex flex-col gap-[15px] leading-none">
            <div className="flex items-center justify-between">
              <div className="text-[#9F9F9F] mb-[8px] text-[16px]">
                Product Name
              </div>
              <div className="text-[18px] text-[#fff] font-montserrat600">
                {dataActive?.productName}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-[#9F9F9F] text-[16px]">Staking volume</div>
              <div className="text-[18px] text-[#fff]">
                <span className="font-montserrat600 text-[#B0F127]">
                  {formatPrice(dataActive?.totalStaking)}
                </span>
                /{formatPrice(dataActive?.limitTotalStaking)}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-[#9F9F9F] text-[16px]">Start time</div>
              <div className="flex items-center text-[18px] text-[#fff] font-montserrat600 gap-[15px]">
                <div>{moment(dataActive?.startAt).format("YYYY/MM/DD")}</div>
                <div>{moment(dataActive?.startAt).format("HH:mm")}</div>
              </div>
            </div>
          </div>
          <div className="text-[#9F9F9F] mb-[8px] text-[16px] mt-[25px]">
            Amount (Min: {formatPrice(dataActive?.minStaking)}{" "}
            {dataActive?.assetType}
            {dataActive?.maxStaking && (
              <>
                ; Max: {formatPrice(dataActive?.maxStaking)}{" "}
                {dataActive?.assetType}
              </>
            )}
            )
          </div>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: "Please input your amount!" }]}
          >
            <InputBase
              type="text"
              suffix={
                <div className="text-[#fff] text-[18px] font-montserrat600">
                  {dataActive?.assetType}
                </div>
              }
              // onInput={(e: React.FormEvent<HTMLInputElement>) => {
              //   const inputValue = e.currentTarget.value;
              //   const filteredValue = inputValue.replace(/[^0-9]/g, ""); // Loại bỏ ký tự không phải số
              //   if (filteredValue !== inputValue) {
              //     e.currentTarget.value = filteredValue; // Chỉ cập nhật nếu có thay đổi
              //   }
              // }}
              onInput={(e: React.FormEvent<HTMLInputElement>) => {
                const inputValue = e.currentTarget.value;
                // Loại bỏ tất cả ký tự không phải số và dấu '.'
                let filteredValue = inputValue.replace(/[^0-9.]/g, "");
                // Đảm bảo chỉ có một dấu '.' duy nhất
                const parts = filteredValue.split(".");
                if (parts.length > 2) {
                  filteredValue = parts[0] + "." + parts.slice(1).join("");
                }
                // Cập nhật giá trị của input nếu có sự thay đổi
                if (filteredValue !== inputValue) {
                  e.currentTarget.value = filteredValue;
                }
              }}
              onChange={(e) => {
                setAmount(Number(e.target.value));
              }}
            />
          </Form.Item>
          {balanceUsdt < amount && (
            <div className="mt-[-7px] text-[#FF6262] font-montserrat500 text-[13px]">
              * There is not enough asset in your balance
            </div>
          )}
          <div className="text-[#9F9F9F] text-[16px] mt-[30px] leading-none">
            Profit
          </div>
          <div className="pb-[30px]">
            <div className="text-[#fff] text-[16px] pt-[5px] mb-[18px] sm:leading-none flex items-center justify-between flex-wrap">
              <div>- Expected reward for {dataActive?.daysDuration} days</div>
              <div className="flex gap-[10px] items-center">
                <div className="font-montserrat600 sm:inline block sm:pl-0 pl-[10px]">
                  {formatPrice(
                    (dataActive?.apr * amount * dataActive?.daysDuration) / 365,
                    8
                  )}{" "}
                  {dataActive?.assetType}
                </div>
                <TooltipIcon text="This is the estimated profit you will receive after completing the earning period for this product" />
              </div>
            </div>
            {dataUser?.membershipLevel > 0 && (
              <>
                <div className="text-[#fff] text-[16px] mb-[18px] sm:leading-none flex items-center justify-between flex-wrap">
                  <div className="flex items-center gap-[5px]">
                    <div>- Membership reward for </div>
                    <CheckRank rank={dataUser?.membershipLevel} />
                  </div>
                  <div className="flex gap-[10px] items-center">
                    <div className="font-montserrat600 sm:inline block sm:pl-0 pl-[10px]">
                      {formatPrice(
                        ((dataActive?.apr * amount * dataActive?.daysDuration) /
                          365) *
                          dataUser?.currentStakingBonusPercent,
                        8
                      )}{" "}
                      {dataActive?.assetType}
                    </div>
                    <TooltipIcon text="As a membership benefit, you receive additional rewards based on your rank" />
                  </div>
                </div>
                <div className="text-[#fff] text-[16px] sm:leading-none flex items-center justify-between flex-wrap">
                  <div>- Total expected reward</div>
                  <div className="flex gap-[10px] items-center">
                    <div className="font-montserrat700 text-[20px] text-[#B0F127] sm:inline block sm:pl-0 pl-[10px]">
                      {formatPrice(
                        ((dataActive?.apr * amount * dataActive?.daysDuration) /
                          365) *
                          dataUser?.currentStakingBonusPercent +
                          (dataActive?.apr *
                            amount *
                            dataActive?.daysDuration) /
                            365,
                        8
                      )}{" "}
                      {dataActive?.assetType}
                    </div>
                    <TooltipIcon text="This is the sum of your expected staking reward and your membership bonus reward" />
                  </div>
                </div>
              </>
            )}

            {/* <div className="text-[#9F9F9F] text-[16px] mt-[30px] leading-none">
              Point
            </div>
            <div className="text-[#fff] text-[16px] pt-[5px] mb-[8px] sm:leading-none flex items-center justify-between flex-wrap">
              <div>- Expected point for {dataActive?.daysDuration} days:</div>
              <div className="font-montserrat600">
                {dataActive?.percentPointReward *
                  dataActive?.daysDuration *
                  amount}{" "}
                Points
              </div>
            </div> */}

            {dataActive?.canCancel && (
              <div className="text-[#FF6262] text-[16px] my-[10px]">
                This product can be stopped during earning.
              </div>
            )}
          </div>

          <div className="flex gap-[20px]">
            <button
              onClick={handleCancel}
              type="button"
              className="w-full h-[60px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              Cancel
            </button>
            {dataUser?.membershipLevel < dataActive?.limitMembershipLevel ? (
              <button
                onClick={() => navigate(UrlInternal.MEMBERSHIP_NFT)}
                type="button"
                className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer
                disabled:bg-[#4B4D47] disabled:border-[#4B4D47] disabled:cursor-not-allowed"
              >
                Upgrade
              </button>
            ) : (
              <>
                {moment(dataActive?.startAt).isBefore(moment()) && isAuth && (
                  <button
                    disabled={
                      dataActive?.totalStaking === dataActive?.limitTotalStaking
                    }
                    type="submit"
                    className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer
                disabled:bg-[#4B4D47] disabled:border-[#4B4D47] disabled:cursor-not-allowed"
                  >
                    Confirm
                  </button>
                )}
              </>
            )}
          </div>
        </Form>
      </div>
    </ModalBase>
  );
}

export default StartEarning;
