import ImgCopy from "@/assets/img/Copy.png";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { Form, message, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ModalBase from "../ModalBase";
import SelectCustom from "../SelectCustom";
import QRCode from "react-qr-code";

interface Props {
  isVisible: boolean;
  setCancel?: () => void;
}

function Deposit({ isVisible, setCancel }: Props) {
  const [form] = Form.useForm();
  const [dataCoin, setDataCoin] = useState<any>([]);
  const [dataNetwork, setDataNetwork] = useState<any>([]);
  const [depositAddress, setDepositAddress] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const getListCoin = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.deposit.getListCoin,
        body: {
          page: 1,
          size: 999,
          type: "DEPOSIT",
          query: "",
        },
      });

      console.log("resp", resp);
      if (resp?.datas?.length > 0) {
        setDataCoin(resp?.datas);
      }
    } catch (error) {
      console.log();
    }
  };
  const getListNetwork = async (symbol: string) => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.deposit.getListNetwork,
        body: {
          page: 1,
          size: 999,
          type: "DEPOSIT",
          symbol: symbol,
        },
      });
      if (resp?.datas?.length > 0) {
        setDataNetwork(resp?.datas);
      }
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    if (isVisible) {
      getListCoin();
    }
  }, [isVisible]);

  const getDepositAddress = async (network: string) => {
    setIsLoading(true);
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.deposit.getDepositAddress,
        body: {
          network: network,
          symbol: form.getFieldValue("coin"),
        },
      });
      console.log("resp", resp);
      if (resp.data) {
        setDepositAddress(resp.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log();
    }
  };

  return (
    isVisible && (
      <ModalBase
        isVisible={isVisible}
        title="Deposit"
        setCancel={() => {
          form.resetFields();
          setCancel?.();
          setDepositAddress("");
        }}
      >
        <div className="pt-[49px] sm:pb-0 pb-[30px]">
          <Form form={form}>
            <div className="text-[#9F9F9F] mb-[8px] text-[16px]">
              Select coin
            </div>
            <Form.Item name="coin">
              <SelectCustom
                height={60}
                placeholder={"Select coins"}
                onChange={(value) => {
                  form.setFieldValue("coin", value);
                  getListNetwork(value);
                }}
              >
                {dataCoin?.map((dt: any, i: number) => (
                  <Select.Option key={i} value={dt.assetSymbol}>
                    <div className="flex justify-center items-center gap-[10px] option-select">
                      <img
                        src={dt.assetIcon}
                        alt="bnb"
                        className="max-w-[28px] w-full aspect-square"
                      />
                      <div className="text-[#fff] min-w-[50px]">
                        {dt.assetSymbol}
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </SelectCustom>
            </Form.Item>
            <div className="text-[#9F9F9F] mb-[8px] text-[16px]">
              Select network
            </div>
            <Form.Item name="network">
              <SelectCustom
                height={60}
                placeholder={"Select network"}
                onChange={(value) => {
                  form.setFieldValue("network", value);
                  getDepositAddress(value);
                }}
              >
                {dataNetwork?.map((dt: any, i: number) => (
                  <Select.Option key={i} value={dt.type}>
                    <div className="flex justify-center items-center gap-[10px] option-select">
                      <img
                        src={dt.icon}
                        alt="bnb"
                        className="max-w-[28px] w-full aspect-square"
                      />
                      <div className="text-[#fff]">{dt.nativeSymbol}</div>
                    </div>
                  </Select.Option>
                ))}
              </SelectCustom>
            </Form.Item>
            {isLoading && (
              <div className="flex justify-between items-center">
                <Spin className="mx-auto" />
              </div>
            )}
            {depositAddress && (
              <>
                <div className="text-[#9F9F9F] mb-[8px] text-[16px]">
                  Deposit Address
                </div>
                <div className="rounded-[18px] bg-[#141414] p-[16px] mb-[30px] flex items-center gap-[20px]">
                  <div className="h-auto mx-auto max-w-[100px] w-full bg-white p-[5px] rounded-[5px]">
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={depositAddress}
                      viewBox={`0 0 256 256`}
                    />
                  </div>

                  <div className="flex-1 min-w-[40%]">
                    {/* <div className="text-[#C0C0C0] mb-[8px]">My Address:</div> */}
                    <div className="flex gap-[8px] items-center">
                      <div className="font-montserrat600 text-[16px] text-[#fff] break-words min-w-[40%]">
                        {depositAddress}
                      </div>
                      <CopyToClipboard
                        text={depositAddress}
                        onCopy={() => message.success("Copied")}
                      >
                        <img
                          src={ImgCopy}
                          alt="copy"
                          className="cursor-pointer mx-auto"
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="flex gap-[20px]">
              <button
                onClick={() => {
                  form.resetFields();
                  setCancel?.();
                  setDepositAddress("");
                }}
                type="button"
                className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
              >
                Confirm
              </button>
            </div>
          </Form>
        </div>
      </ModalBase>
    )
  );
}

export default Deposit;
