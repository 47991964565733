import { ConfigProvider, Pagination, PaginationProps } from "antd";
import ImgPrev from "@/assets/img/prev.png";
import ImgNext from "@/assets/img/next.png";

import "./style.scss";

interface Props extends PaginationProps {
  title?: string;
}

function PaginationCustom(Props: Props) {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Pretendard-400",
          // colorPrimaryBorder: "transparent",
          colorPrimary: "#B0F127",
          colorText: "#fff",
          fontSize: 16,
          borderRadius: 0,
          colorBgContainer: "transparent",
          padding: 10,
        },
      }}
    >
      <Pagination
        prevIcon={
          <div className="flex items-center h-full justify-end">
            <img src={ImgPrev} alt="prev" />
          </div>
        }
        nextIcon={
          <div className="flex items-center h-full justify-start">
            <img src={ImgNext} alt="next" />
          </div>
        }
        {...Props}
      />
    </ConfigProvider>
  );
}

export default PaginationCustom;
