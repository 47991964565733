import { Outlet } from "react-router-dom";
import ImgBg from "@/assets/img/bg-absolute.png";

function LayoutPrimary() {
  return (
    <div className="bg-[#000] h-full relative sm:pt-0 pt-[97px]">
      <img
        src={ImgBg}
        alt="bg"
        className="absolute lg:right-[8%] right-0 top-0 z-0 md:block hidden"
      />

      <div className="relative">
        <Outlet />
      </div>
    </div>
  );
}

export default LayoutPrimary;
