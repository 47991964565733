import ModalBase from "@/components/ModalBase";
import { useAppSelector } from "@/state/hooks";
import { formatPrice } from "@/utils/formatPrice";
import { useMemo, useState } from "react";
import { dataUserSelect } from "@/state/authSlice";
import { useNavigate } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import CopyToClipboard from "react-copy-to-clipboard";
import Copy from "@/assets/images/Copy.svg";
import { message } from "antd";
import eyeShow from "@/assets/img/eye-show.png";
import eyeHidden from "@/assets/img/eye-hidden.png";
import rightImg from "@/assets/img/right.png";
import { envConfig } from "@/constant/envConfig";

function MyAccount({
  onClose,
  onReplace,
  onDeposit,
  onWithdraw,
  isVisible,
}: any) {
  const dataUser = useAppSelector(dataUserSelect);
  const navigate = useNavigate();
  const [isEye, setIsEye] = useState(true);

  const balanceUsdt = useMemo(() => {
    const findItem = dataUser?.balances?.find(
      (i: { asset_type: string }) => i?.asset_type === "USDT"
    );
    return findItem?.balance || 0;
  }, [dataUser?.balances]);

  return (
    <ModalBase isVisible={isVisible} title="My account" setCancel={onClose}>
      <div className="mb-7">
        <div className="flex items-center mt-[34px] justify-between">
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0] ">
            My account
          </div>
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0] ">
            {dataUser?.email}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0]">
            UID
          </div>
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0] flex items-center gap-[9px]">
            {isEye ? (
              "***********************"
            ) : (
              <>
                {dataUser?.secretKeyBeInvited?.substring(0, 6)}...
                {dataUser?.secretKeyBeInvited?.substring(
                  dataUser?.secretKeyBeInvited.length - 9,
                  dataUser?.secretKeyBeInvited.length
                )}
              </>
            )}
            <span onClick={() => setIsEye(!isEye)}>
              <img src={isEye ? eyeShow : eyeHidden} alt="img" />
            </span>
            <CopyToClipboard
              text={dataUser?.secretKeyBeInvited}
              onCopy={() => message.success("Copied")}
            >
              <img src={Copy} alt="copy" className="cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0]">
            Referral code
          </div>
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0] flex gap-1">
            {dataUser?.referralCode}
            <CopyToClipboard
              text={dataUser?.referralCode}
              onCopy={() => message.success("Copied")}
            >
              <img src={Copy} alt="copy" className="cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-[18px] font-[400] text-[#C0C0C0]">
            My Balance
          </div>
          <div className="text-[32px] font-[600] text-[#B0F127]">
            ${formatPrice(balanceUsdt)}
          </div>
        </div>
        <div className="flex flex-col gap-[20px] mt-[25px]">
          <button
            onClick={onDeposit}
            type="button"
            className="w-full h-[50px] bg-[#B0F127] text-[#000000] border border-solid border-[#B0F127] text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            Deposit
          </button>
          <button
            onClick={onWithdraw}
            type="button"
            className="w-full h-[50px] bg-[#B0F127] text-[#000000] border border-solid border-[#B0F127] text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            Withdraw
          </button>
          <button
            onClick={() => {
              navigate(UrlInternal.CONVERT);

              onReplace();
            }}
            type="button"
            className="w-full h-[50px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            Convert
          </button>
          <button
            onClick={() => {
              navigate({
                pathname: UrlInternal.HISTORY_EARN_PROFIT,
                search: `tab=DEPOSIT`,
              });

              onReplace();
            }}
            type="button"
            className="w-full h-[50px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            History
          </button>
        </div>
        {dataUser?.isAgency && (
          <div
            className="mt-[35px] flex justify-center"
            onClick={() => window.open(envConfig.DEFYLO_AGENCY)}
          >
            <div className="pb-[5px] border-0 border-b border-solid border-[#fff] justify-center items-center gap-[8px] inline-flex cursor-pointer">
              <div className="text-[18px] font-montserrat600 leading-none">
                Go to agency page
              </div>
              <img src={rightImg} alt="img" />
            </div>
          </div>
        )}
      </div>
    </ModalBase>
  );
}

export default MyAccount;
