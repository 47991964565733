import SubFooter from "@/components/SubFooter";
import Banner from "./components/Banner";
import Buy from "./components/Buy";
import Service from "./components/Service";
import StandsOut from "./components/StandsOut";
import Earn from "./Earn";
import Benefits from "./components/Benefits";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MembershipNft from "./components/MembershipNft";

function Home() {
  const { state } = useLocation();

  useEffect(() => {
    const positionScrollY = sessionStorage.getItem("positionScrollY");
    if (positionScrollY && state?.isBack) {
      setTimeout(() => {
        window.scrollTo(0, Number(sessionStorage.getItem("positionScrollY")));
      }, 0);
    }
  }, []);

  return (
    <div className="bg-[#000000]">
      <div className="w-full">
        <Banner />
        <MembershipNft />
        <Earn />
        <Service />
        <Buy />
        <StandsOut />
        <Benefits />
        <SubFooter />
      </div>
    </div>
  );
}

export default Home;
