import IconChecked from "@/assets/images/component/checked.svg";
import IconUnChecked from "@/assets/images/component/unchecked.svg";
import { useEffect, useState } from "react";

interface Props {
  onClick: (value: boolean) => void;
  content?: string;
  defaultValue?: boolean;
}

function Checkbox({ onClick, content, defaultValue }: Props) {
  const [isChecked, setIsChecked] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setIsChecked(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div
      className="flex items-center gap-[8px] cursor-pointer"
      onClick={() => {
        setIsChecked(!isChecked);
        onClick(!isChecked);
      }}
    >
      <img src={isChecked ? IconChecked : IconUnChecked} alt="img" />
      {content && (
        <div className="font-montserrat700 text-[#fff] text-[16px]">
          {content}
        </div>
      )}
    </div>
  );
}

export default Checkbox;
