import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "..";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";

export interface dataBalanceState {
  data: any | null;
}

const initialState: dataBalanceState = {
  data: null,
};

export const dataBalanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {
    setDataBalance: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setDataBalance } = dataBalanceSlice.actions;

export const getDataBalance = (): AppThunk => async (dispatch) => {
  try {
    const resp: any = await sendApiRequest({
      ...apiConfig.user.getStatistic,
    });
    if (resp?.result) {
      dispatch(setDataBalance(resp?.data));
    } else {
      dispatch(setDataBalance(null));
    }
  } catch (error) {
    console.log(error);
  }
};

export const dataBalanceSelect = (state: RootState) => state.balance.data;

export default dataBalanceSlice.reducer;
