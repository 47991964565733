import React from "react";
import FaFacebook from "@/assets/images/facebook.svg?react";
import FaTwitter from "@/assets/images/twitter.svg?react";
import FaLinkedin from "@/assets/images/linkin.svg?react";
import FaYoutube from "@/assets/images/youtube.svg?react";
import Logo from "@/assets/images/logo.svg?react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white py-9 pb-16 max-lg:py-8 border-[1px] border-black border-t-[#2F2F2F] border-solid">
      <div className="max-w-[1312px] mx-auto flex justify-between items-center px-6 max-lg:flex-col max-lg:gap-5 flex-row">
        {/* Left - Logo */}
        <div className="text-xl font-bold">
          <Logo className="w-[241px] h-[41px] max-lg:w-40 max-lg:h-8" />
        </div>

        {/* Center - Copyright */}
        <div className="text-lg">Copyright &copy; DeFylo | 2025</div>

        {/* Right - Social Icons */}
        {/* <div className="flex space-x-4">
                    <a href="#" className="hover:text-blue-500" aria-label="Facebook">
                        <FaFacebook />
                    </a>
                    <a href="#" className="hover:text-blue-400" aria-label="Twitter">
                        <FaTwitter />
                    </a>
                    <a href="#" className="hover:text-blue-700" aria-label="LinkedIn">
                        <FaLinkedin />
                    </a>
                    <a href="#" className="hover:text-red-600" aria-label="YouTube">
                        <FaYoutube />
                    </a>
                </div> */}
      </div>
    </footer>
  );
};

export default Footer;
