import { Form, message, Select, SelectProps } from "antd";
import ModalBase from "@/components/ModalBase";
import SelectCustom from "@/components/SelectCustom";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { coinsSelect } from "@/state/coinSlice";
import {
  filtersSelect,
  setAssetType,
  setCanStaking,
  setDurtions,
  setRank,
} from "@/state/filterSlice";
import { useEffect } from "react";
import Checkbox from "@/components/Checkbox";

function FilterMobile({ onClose, dataDurations, dataLevel }: any) {
  const dataListCoin = useAppSelector(coinsSelect);
  const dispatch = useAppDispatch();
  const dataFilters = useAppSelector(filtersSelect);

  const [form] = Form.useForm();

  useEffect(() => {
    if (dataFilters) {
      form.setFieldsValue({
        daysDuration: dataFilters?.daysDuration,
        assetType: dataFilters?.assetType,
        membershipRank: dataFilters?.limitMembershipGrade,
      });
    }
  }, [dataFilters]);

  const onFinish = async (values: any) => {
    dispatch(setDurtions(values?.daysDuration || ""));
    dispatch(setAssetType(values?.assetType || ""));
    dispatch(setRank(values?.membershipRank || ""));

    const valueFilter: any = values?.canStaking ? 1 : null;
    dispatch(setCanStaking(valueFilter));

    onClose();
  };

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  return (
    <ModalBase isVisible title="Filter" setCancel={onClose}>
      <div className="pt-[49px] sm:pb-0 pb-[30px]">
        <Form form={form} onFinish={onFinish}>
          <div className="text-[#9F9F9F] mb-[8px] text-[16px]">
            All Durations
          </div>
          <Form.Item name="daysDuration">
            <SelectCustom
              height={60}
              placeholder={"All Durations"}
              onChange={(value) => {
                form.setFieldValue("daysDuration", value);
              }}
            >
              {dataDurations?.map((dt: any, i: any) => (
                <Select.Option key={i} value={dt.value}>
                  <div className="flex justify-center items-center gap-[10px] option-select">
                    <div className="text-[#fff]">{dt.label}</div>
                  </div>
                </Select.Option>
              ))}
            </SelectCustom>
          </Form.Item>
          <div className="text-[#9F9F9F] mb-[8px] text-[16px]">All Coins</div>
          <Form.Item name="assetType">
            <SelectCustom
              height={60}
              placeholder={"All Coins"}
              onChange={(value) => {
                form.setFieldValue("assetType", value);
              }}
            >
              <Select.Option value="">
                <div className="flex justify-center items-center gap-[10px] option-select">
                  <div className="text-[#fff]">All Coins</div>
                </div>
              </Select.Option>
              {dataListCoin?.map((dt: any, i: any) => (
                <Select.Option key={i} value={dt.assetSymbol}>
                  <div className="flex justify-center items-center gap-[10px] option-select">
                    <div className="text-[#fff]">{dt.assetSymbol}</div>
                  </div>
                </Select.Option>
              ))}
            </SelectCustom>
          </Form.Item>
          <div className="text-[#9F9F9F] mb-[8px] text-[16px]">
            Membership Rank
          </div>
          <Form.Item name="membershipRank">
            <SelectCustom
              height={60}
              placeholder={"Membership Rank"}
              onChange={(value) => {
                form.setFieldValue("membershipRank", value);
              }}
            >
              <Select.Option value="">
                <div className="flex justify-center items-center gap-[10px] option-select">
                  <div className="text-[#fff]">All Membership Rank</div>
                </div>
              </Select.Option>
              {dataLevel?.map((dt: any, i: any) => (
                <Select.Option key={i} value={dt.grade}>
                  <div className="flex justify-center items-center gap-[10px] option-select">
                    <div className="text-[#fff]">{dt.gradeName}</div>
                  </div>
                </Select.Option>
              ))}
            </SelectCustom>
          </Form.Item>

          <Form.Item name="canStaking">
            <Checkbox
              defaultValue={dataFilters?.canStaking}
              content="Earnable Products"
              onClick={(value: any) => {
                form.setFieldValue("canStaking", value);
              }}
            />
          </Form.Item>

          <div className="flex gap-[20px] mt-[50px]">
            <button
              onClick={onReset}
              type="button"
              className="w-full h-[60px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              Reset
            </button>
            <button
              type="submit"
              className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              Confirm
            </button>
          </div>
        </Form>
      </div>
    </ModalBase>
  );
}

export default FilterMobile;
