import ImgTop from "@/assets/img/top.png";
import ImgBottom from "@/assets/img/bottom.png";
import moment from "moment";
import ImgUsdt from "@/assets/img/usdt.png";
import { useState } from "react";
import { formatPrice } from "@/utils/formatPrice";
import { useAppSelector } from "@/state/hooks";
import { coinsSelect } from "@/state/coinSlice";
import TooltipIcon from "@/components/TooltipIcon";

interface Props {
  dt: any;
}

function Collapse({ dt }: Props) {
  const [isShow, setIsShow] = useState(false);
  const coins = useAppSelector(coinsSelect);
  const membershipProfit =
    dt?.status === 100
      ? dt?.bonusMembershipReceived
      : dt?.expectedBonusMembership;

  const handleGetIconCoin = (assetSymbol: string) => {
    const coin = coins.find((coin: any) => coin.assetSymbol === assetSymbol);

    return coin?.assetIcon;
  };

  return (
    <div className="py-[12px] border-0 border-solid border-b border-[#2F2F2F] px-[16px] ">
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => setIsShow(!isShow)}
      >
        <div className="leading-0 max-w-[100px]">
          {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
        </div>
        <div className="flex items-center gap-[10px]">
          <img
            src={handleGetIconCoin(dt?.assetType)}
            alt="usdt"
            className="max-w-[28px] w-full aspect-square"
          />
          <div className="text-[16px] text-[#EAECEF] font-montserrat500">
            USDT
          </div>
        </div>
        <div className="flex items-center">
          <img src={!isShow ? ImgBottom : ImgTop} alt="top" />
        </div>
      </div>
      {isShow && (
        <div className="pb-[14px] pt-[12px] mt-[10px] border-0 border-solid border-t border-[#2F2F2F]">
          <div className="flex">
            <div className="flex-1">
              <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                Product Profit
              </div>
              <div className="text-[16px]">
                {formatPrice(dt?.expectedProfit, 4)}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-[12px] text-[#C0C0C0] pb-[4px]">Amount</div>
              <div className="text-[16px]">
                {formatPrice(dt?.stakingAmount)}
              </div>
            </div>
          </div>
          <div className="flex pt-[16px]">
            <div className="flex-1">
              <div className="text-[12px] text-[#C0C0C0] pb-[4px] flex items-center gap-[5px]">
                Membership Profit
                <span>
                  <TooltipIcon text="As a membership benefit, you receive additional rewards based on your rank" />
                </span>
              </div>
              <div className="text-[16px]">
                {formatPrice(membershipProfit, 4)}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                Duration
              </div>
              <div className="text-[16px]">{dt?.daysDuration} days</div>
            </div>
          </div>
          <div className="flex pt-[16px]">
            <div className="flex-1">
              <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                Total Profit
              </div>
              <div className="text-[16px]">
                {formatPrice(dt?.expectedProfit + membershipProfit, 4)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Collapse;
