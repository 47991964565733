import Logo from "@/assets/img/logo.png";
import sendApiRequest from "@/axios/requestApi";
import InputBase from "@/components/Input";
import InputPassword from "@/components/InputPassword";
import { apiConfig } from "@/constant/apiConfig";
import { UrlInternal } from "@/constant/endpointRoute";
import { setLoading } from "@/state/globalSlice";
import { useAppDispatch } from "@/state/hooks";
import { Form, message } from "antd";
import { useState } from "react";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import ImgEmail from "@/assets/images/auth/findid.png";

function ForgotPassword() {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSendCode, setIsSendCode] = useState<any[]>([]);
  const [isSendCodePhone, setIsSendCodePhone] = useState<any[]>([]);
  const [isFindPassword, setIsFindPassword] = useState(false);

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;

    setDisabledSave(hasErrors);
  };

  const handleSendCodeEmail = async () => {
    dispatch(setLoading(true));
    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.requestOTP,
        body: {
          source: form.getFieldValue("email"),
          sourceType: "mail",
        },
      });
      if (res?.result) {
        message.success("Send code email successful");
        setIsSendCode([
          ...isSendCode,
          {
            email: form.getFieldValue("email"),
            isVerify: false,
          },
        ]);
      }
    } catch (error) {
      console.error("Send code email failed:", error);
    }
    dispatch(setLoading(false));
  };
  const handleVerifyCodeEmail = async () => {
    dispatch(setLoading(true));
    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.verifyOTP,
        body: {
          sourceCheck: form.getFieldValue("email"),
          sourceType: "mail",
          otp: form.getFieldValue("verifyCode"),
        },
      });
      if (res?.data?.otpValid) {
        message.success("Verify code successful");
        setIsSendCode((prevState) =>
          prevState.map((item) =>
            item.email === form.getFieldValue("email")
              ? { ...item, isVerify: true }
              : item
          )
        );
        // setIsVerifyMail(true);
      } else {
        message.error("Verify code failed");
      }
    } catch (error) {
      console.error("Send code email failed:", error);
    }
    dispatch(setLoading(false));
  };

  const onFinish = async (values: any) => {
    if (values.password !== values.rePassword) {
      message.error("Password not match");
      return;
    }

    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.findPassword,
        body: {
          email: values.email,
          newPassword: values.password,
          emailOtp: values.verifyCode,
        },
      });

      if (res?.result) {
        message.success("forgot password successful");
        setIsFindPassword(true);
      }
    } catch (error) {
      console.error("forgot password failed:", error);
    }
  };

  return (
    <div className="my-[30px]">
      <div className="sm:flex hidden justify-center mb-[44px]">
        <img src={Logo} alt="img" />
      </div>
      <div className="flex justify-center pb-[50px]">
        <div
          className={`bg-gradient sm:px-[49px] px-[16px] sm:pt-[54px] pb-[62px] overflow-y-auto rounded-[50px] sm:border sm:border-[#FFFFFF40] sm:border-solid sm:w-[550px]`}
        >
          {isFindPassword ? (
            <div className="text-center">
              <img src={ImgEmail} alt="ImgEmail" className="mx-auto" />
              <div className="my-[40px] leading-none">
                <div className="text-[32px] font-montserrat700">
                  Password has been changed
                </div>
                <div className="font-montserrat500 text-[#C0C0C0] mt-[16px]">
                  Please log in with the new password
                </div>
              </div>
              <button
                onClick={() => navigate(UrlInternal.LOGIN)}
                type="button"
                className="w-full max-w-[200px] h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
              >
                OK
              </button>
            </div>
          ) : (
            <>
              <div className="text-[32px] font-montserrat700 text-center sm:mb-[59px] mb-[56px]">
                Forgot Password
              </div>
              <Form
                form={form}
                onFieldsChange={handleFormChange}
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                  label="Email"
                >
                  <InputBase
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    suffix={
                      <button
                        type="button"
                        onClick={handleSendCodeEmail}
                        disabled={
                          email.length <= 0 ||
                          isSendCode.find((item) => item.email === email)?.email
                        }
                        className="h-[40px] w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] mr-[-16px] cursor-pointer disabled:bg-[#4B4D47]"
                      >
                        Send
                      </button>
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Verify Code"
                  name="verifyCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your verify code!",
                    },
                  ]}
                >
                  <InputBase
                    type="text"
                    placeholder="Verify Code"
                    suffix={
                      <button
                        disabled={
                          isSendCode.find((item) => item.email === email)
                            ?.isVerify
                        }
                        onClick={handleVerifyCodeEmail}
                        type="button"
                        className="h-[40px] w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] mr-[-16px] cursor-pointer disabled:bg-[#4B4D47]"
                      >
                        {isSendCode.find((item) => item.email === email)
                          ?.isVerify
                          ? "Verified"
                          : "Verify"}
                      </button>
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="New Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <InputPassword placeholder="New Password" />
                </Form.Item>
                <Form.Item
                  label="Confirm New Password"
                  name="rePassword"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <InputPassword placeholder="Confirm New Password" />
                </Form.Item>

                <Form.Item>
                  <button
                    // disabled={disabledSave}
                    type="submit"
                    className="sm:mt-[20px] mt-[25px] w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer
              disabled:bg-[#4B4D47] disabled:border-[#4B4D47] disabled:text-[#2C2B2B]"
                  >
                    Confirm
                  </button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
