export enum UrlInternal {
  HOME = "/",

  HISTORY_EARN_PROFIT = "/history/earn-profit",
  // HISTORY_REFERRAL_PROFIT = "/history/referral-profit",
  HISTORY_DEPOSIT_WITHDRAW = "/history/deposit-withdraw",
  LOGIN = "/login",
  SIGNUP = "/signup",
  CONVERT = "/convert",
  CONVERT_HISTORY = "/convert-history",
  FORGOT_ID = "/auth/forgot-id",
  FORGOT_PASSWORD = "/auth/forgot-password",
  MYPAGE = "/mypage",
  MY_ACCOUNT = "/my-account",
  MEMBERSHIP_NFT = "/membership-nft",
}
