import { Tooltip } from "antd";
import Icon from "@/assets/img/icon-hover.png";

interface Props {
  text: string;
}

function TooltipIcon({ text }: Props) {
  return (
    <Tooltip placement="right" title={text} arrow={true} color="#2F2F2F">
      <img src={Icon} alt="icon" />
    </Tooltip>
  );
}

export default TooltipIcon;
