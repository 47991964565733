import { Button } from "antd";
import React from "react";
import investing from "@/assets/images/investing.png";
import { scrollToSection } from "@/utils/scrollToSection";

const dataFooterNew = [
  {
    title: "About Us",
    item: [
      {
        title: "About Us",
        href: "about",
      },
      {
        title: "Services",
        href: "Service",
      },
      {
        title: "Key Features",
        href: "KeyFeatures",
      },
      {
        title: "Outstanding Features",
        href: "OutstandingFeatures",
      },
    ],
  },
  {
    title: "Products",
    item: [
      {
        title: "Earning",
        href: "Earn",
      },
    ],
  },
  {
    title: "Support center",
    item: [
      {
        title: "Email : support@defylo.com",
        href: "",
      },
      // {
      //   title: "Telegram",
      //   href: "",
      // },
    ],
  },
];

const dataFooter = [
  {
    title: "Company",
    item: [
      {
        title: "About Us",
        href: "",
      },
      {
        title: "Our Team",
        href: "",
      },
      {
        title: "Careers",
        href: "",
      },
      {
        title: "Blog",
        href: "",
      },
    ],
  },
  {
    title: "Products",
    item: [
      {
        title: "Exchange",
        href: "",
      },
      {
        title: "Staking",
        href: "",
      },
      {
        title: "Earning",
        href: "",
      },
      {
        title: "Buy Crypto",
        href: "",
      },
    ],
  },
  {
    title: "Get Started",
    item: [
      {
        title: "Sign Up",
        href: "",
      },
      {
        title: "FAQs",
        href: "",
      },
      {
        title: "Support Center",
        href: "",
      },
      {
        title: "Terms of Service",
        href: "",
      },
      {
        title: "Privacy Policy",
        href: "",
      },
    ],
  },
  {
    title: "Connect",
    item: [
      {
        title: "Facebook",
        href: "",
      },
      {
        title: "Twitter",
        href: "",
      },
      {
        title: "LinkedIn",
        href: "",
      },
      {
        title: "Instagram",
        href: "",
      },
    ],
  },
];
const SubFooter: React.FC = () => {
  const handleClick = (url: string) => {
    scrollToSection(url);
  };

  return (
    <footer className="bg-black text-white py-9 pb-16 max-lg:py-8" id="support">
      <div className="max-w-[1364px] px-4 mx-auto flex justify-between px-6 md:flex-row flex-col gap-16 md:gap-5 flex-row">
        <div className="md:max-w-[550px] bg-[#B0F127] rounded-[18px]  flex-1">
          <div className="md:pt-[35px] md:px-[36px] px-4 pt-10">
            <h2 className="text-[#060606] font-[600] text-[30px] md:text-[36px]">
              Now Is the Time
            </h2>
            <div className="md:py-[10px] py-[8px] text-[#2F2F2F] font-montserrat600 md:text-[18px] text-[16px]">
              Global arbitrage is no longer just for the pros.
            </div>
            <div className="text-[#2F2F2F] font-montserrat600 text-[16px]">
              With Defylo, anyone can participate — even with a small starting
              amount.
            </div>
            <div className="text-[#2F2F2F] font-montserrat600 text-[16px]">
              We handle the complexity with expert systems and infrastructure.
            </div>
            <div className="text-[#2F2F2F] font-montserrat600 text-[16px]">
              You enjoy the profits, we take care of the rest.
            </div>
          </div>

          <div className="max-w-[501px] ml-auto mt-[-45px]">
            <img src={investing} className="max-h-[283px] ml-auto" />
          </div>
        </div>
        <div className="md:max-w-[550px] flex-1">
          <div className="flex flex-wrap xl:gap-y-[96px] gap-y-12">
            {dataFooterNew.map((v: any, index) => {
              return (
                <div key={index} className="w-1/2 min-w-0">
                  <div className="text-[20px] font-[500] mb-5">{v?.title}</div>
                  <div className="flex flex-col  gap-3">
                    {v?.item?.map((k: any, i: any) => {
                      return (
                        <div
                          key={i}
                          className="text-[16px] font-[400] text-[#9AA19E]"
                          onClick={() => {
                            if (k?.href) {
                              handleClick(k?.href);
                            }
                          }}
                        >
                          {k?.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SubFooter;
