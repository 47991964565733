import { tokenSelect } from "@/state/authSlice";
import { useAppSelector } from "@/state/hooks";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const token = useAppSelector(tokenSelect);

  const isAuthenticated = !!token;

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
