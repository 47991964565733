import Flexible from "@/assets/images/Flexible.png";
import staking from "@/assets/images/staking.png";
import StandsOutImage from "@/assets/images/StandsOut.png";
import InterestRates from "@/assets/images/InterestRates.png";
import rewards from "@/assets/images/rewards.png";
import optimization from "@/assets/images/optimization.png";

import minus from "@/assets/images/minus.svg";
import plus from "@/assets/images/plus.svg";
import { useState } from "react";

const data = [
  {
    title: "Securely Profit from Global Price Differences",
    des: "We offer 30-50% higher interest rates, maximizing your earnings.",
    icon: InterestRates,
  },
  {
    title: "Use AI to Capture Price Gaps and Maximize Returns Automatically.",
    des: "Long-term staking are rewarded with special incentives such as bonus tokens and VIP benefits.",
    icon: optimization,
  },
  {
    title: "With automation and risk controls, we ensure profitability.",
    des: "Our platform is optimized for mobile, allowing users to manage their assets anytime, anywhere.",
    icon: rewards,
  },
];
function StandsOut() {
  const [isActiveRate, setIsActiveRate] = useState(false);
  const [isActiveRewards, setIsActiveRewards] = useState(false);
  const [isActiveOptimization, setIsActiveOptimization] = useState(false);

  return (
    <div
      className="max-w-[1312px] px-4 pb-[65px] pt-[100px] md:pt-[240px] md:pb-[240px] w-full m-auto"
      id="OutstandingFeatures"
    >
      <div className="text-[35px] md:text-[54px] font-[600] max-w-[664px] m-auto mb-3 md:mb-5 text-center">
        A Smarter Way to Grow with{" "}
        <span className=" text-[#B0F127]">DeFi & AI</span>
      </div>
      <div className="max-w-[1080px] mx-auto md:text-[18px] text-[16px] text-[#C0C0C0] text-center md:mb-[48px] mb-[24px]">
        DeFylo is an AI-powered DeFi ecosystem designed to maximize yield, user
        engagement, and secure automated staking services. We simplify complex
        DeFi strategies into automated, secure, and rewarding experiences for
        users of all levels.
      </div>
      <div className="grid">
        <div className="grid md:grid-cols-[1fr_1fr] border border-[#2F2F2F] border-solid rounded-[18px] ">
          <div className="bg-blue-500 text-white border-0 border-r-[1px] border-[#2F2F2F] border-solid px-6 py-8 md:px-9">
            <div className="max-w-[567px] m-auto">
              <img src={StandsOutImage} className="" />
            </div>
          </div>
          <div>
            {data?.map((item, index) => {
              const checkActive =
                (index === 0 && isActiveRate) ||
                (index === 1 && isActiveRewards) ||
                (index === 2 && isActiveOptimization);
              return (
                <div
                  key={index}
                  className="xl:py-[48px] xl:px-[60px] py-[28px] px-[16px] text-white border-0 border-b-[1px] border-[#2F2F2F] border-solid cursor-pointer"
                  // onClick={() => {
                  //   if (index === 0) {
                  //     setIsActiveRate(!isActiveRate);
                  //   }
                  //   if (index === 1) {
                  //     setIsActiveRewards(!isActiveRewards);
                  //   }
                  //   if (index === 2) {
                  //     setIsActiveOptimization(!isActiveOptimization);
                  //   }
                  // }}
                >
                  <div className="flex xl:flex-row flex-col h-full xl:items-center gap-4 gap-6 min-h-[110px]">
                    <div
                      className={`min-w-[64px] w-[64px] h-[64px] xl:min-w-[100px] xl:h-[100px] flex items-center justify-center ${
                        !checkActive ? "bg-[#B0F127] " : "bg-[#141414] "
                      } rounded-[50%]`}
                    >
                      <img
                        src={item?.icon}
                        className="xl:max-w-[65px] max-w-[42px] xl:max-h-[121px] max-h-[77px]"
                      />
                    </div>

                    <div className="flex-1 w-full">
                      <div className="text-[20px] md:text-[24px] font-[600] mb-[13px] flex items-center justify-between">
                        {item?.title}
                        {/* <div className="transition-transform duration-300 mr-6">
                          {checkActive ? (
                            <img src={minus} />
                          ) : (
                            <img src={plus} />
                          )}
                        </div> */}
                      </div>
                      {checkActive && (
                        <div className="xl:text-[18px] text-[16px] font-[400] text-[#C0C0C0]">
                          {item?.des}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StandsOut;
