// src/store/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface DurtionsState {
  daysDuration: any;
}
interface AssetTypeState {
  assetType: any;
}
interface RankTypeState {
  limitMembershipGrade: any;
}
interface CanStakingState {
  canStaking: any;
}

interface State {
  daysDuration: any;
  querySearch: any;
  assetType: any;
  limitMembershipGrade: any;
  canStaking: any;
}
const initialState: State = {
  daysDuration: "",
  querySearch: "",
  assetType: "",
  limitMembershipGrade: "",
  canStaking: null,
};

const authSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setDurtions: (state, action: PayloadAction<DurtionsState>) => {
      state.daysDuration = action.payload;
    },
    setAssetType: (state, action: PayloadAction<AssetTypeState>) => {
      state.assetType = action.payload;
    },
    setSearch: (state, action: PayloadAction<AssetTypeState>) => {
      state.querySearch = action.payload;
    },
    setRank: (state, action: PayloadAction<RankTypeState>) => {
      state.limitMembershipGrade = action.payload;
    },
    setCanStaking: (state, action: PayloadAction<CanStakingState>) => {
      state.canStaking = action.payload;
    },
  },
});

export const { setDurtions, setAssetType, setSearch, setRank, setCanStaking } =
  authSlice.actions;

export const filtersSelect = (state: RootState) => state.filters;

export default authSlice.reducer;
