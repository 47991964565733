import ImgArrowLeft from "@/assets/img/arrow-left.png";
import PaginationCustom from "@/components/PaginationCustom";
import { PaginationProps, Select } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatPrice } from "@/utils/formatPrice";
import { UrlInternal } from "@/constant/endpointRoute";
import NotFound from "@/components/NotFound";
import TableConvert from "./TableConvert";
import SelectCustom from "@/components/SelectCustom";
import DatePickerCustom from "@/components/DatepickerCustom";
import { useAppSelector } from "@/state/hooks";
import { coinsSelect } from "@/state/coinSlice";

function ConvertHistory() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const filterAsset = searchParams.get("filterAsset");
  const pageSize = 10;
  const [dataSource, setDataSource] = useState<any>({});
  const navigate = useNavigate();
  const dataCoin = useAppSelector(coinsSelect);

  const onChange: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
  };

  const getData = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.convert.listConvert,
        body: {
          page: Number(page) || 1,
          size: pageSize,
          querySearch: "",
          startDate: startDate,
          endDate: endDate,
          filterAsset: filterAsset,
        },
      });
      if (resp.result) {
        setDataSource(resp.data);
      }
    } catch (error) {
      setDataSource({});
    }
  };

  useEffect(() => {
    getData();
  }, [searchParams]);

  return (
    <div className="px-[16px]">
      <div
        className={`bg-gradient sm:px-[49px] sm:pt-[54px] pb-[80px] overflow-y-auto sm:rounded-[50px] sm:border sm:border-[#FFFFFF40] sm:border-solid max-w-[1000px] w-full mx-auto sm:mt-[47px] mb-[55px]`}
      >
        <div className="flex items-center justify-between sm:mt-0 mt-[16.5px] mb-[33px]">
          <div
            className="min-w-[24px]"
            onClick={() => {
              navigate(UrlInternal.CONVERT, {
                state: {
                  isBack: true,
                },
              });
            }}
          >
            <img src={ImgArrowLeft} alt="img" className="sm:hidden" />
          </div>
          <div className="sm:text-[32px] text-[20px] font-montserrat700 text-center">
            Convert History
          </div>
          <div className="min-w-[24px]"></div>
        </div>

        <div className="flex md:gap-[24px] gap-[20px] md:flex-row flex-col">
          <div className="flex gap-[10px] items-center xs:justify-normal justify-between">
            <DatePickerCustom
              getData={(value) => {
                searchParams.set("page", "1");
                searchParams.set("startDate", value);
                setSearchParams(searchParams);
              }}
              value={startDate}
              format="YYYY-MM-DD"
            />
            <div className="font-montserrat500">To</div>
            <DatePickerCustom
              getData={(value) => {
                searchParams.set("page", "1");
                searchParams.set("endDate", value);
                setSearchParams(searchParams);
              }}
              value={endDate}
              format="YYYY-MM-DD"
            />
          </div>
          <div className="flex gap-[22px] xs:justify-normal justify-between">
            <div className="sm:min-w-[168px] min-w-[153px]">
              <SelectCustom
                height={48}
                placeholder={"Coins"}
                onChange={(value) => {
                  searchParams.set("page", "1");
                  searchParams.set("filterAsset", value);
                  setSearchParams(searchParams);
                }}
                dropdownMatchSelectWidth={false}
                value={filterAsset}
              >
                {dataCoin?.map((dt: any, i: number) => (
                  <Select.Option key={i} value={dt.assetSymbol}>
                    <div className="flex justify-center items-center gap-[10px] option-select">
                      <img
                        src={dt.assetIcon}
                        alt="bnb"
                        className="max-w-[28px] w-full aspect-square"
                      />
                      <div className="text-[#fff] min-w-[50px]">
                        {dt.assetSymbol}
                      </div>
                    </div>
                  </Select.Option>
                ))}
              </SelectCustom>
            </div>

            <button
              type="button"
              className="sm:min-w-[129px] xs:min-w-[153px] px-[15px] h-[48px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] text-[18px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
              onClick={() => setSearchParams({})}
            >
              Refresh
            </button>
          </div>
        </div>

        {dataSource?.datas?.length >= 0 ? (
          <>
            <TableConvert data={dataSource?.datas} />

            <PaginationCustom
              current={Number(page)}
              onChange={onChange}
              total={dataSource?.pageable?.totalElement || 0}
              pageSize={pageSize}
            />
          </>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
}

export default ConvertHistory;
