import { Form, message } from "antd";
import ModalBase from "../ModalBase";
import InputBase from "../Input";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { useEffect } from "react";
import { formatPrice } from "@/utils/formatPrice";
import { useAppSelector } from "@/state/hooks";
import { dataUserSelect } from "@/state/authSlice";
import TooltipIcon from "../TooltipIcon";
import CheckRank from "../CheckRank";

interface Props {
  isVisible: boolean;
  setCancel?: () => void;
  data: any;
  onSuccess?: () => void;
}

function GetProfit({ isVisible, setCancel, data, onSuccess }: Props) {
  const [form] = Form.useForm();
  const dataUser = useAppSelector(dataUserSelect);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        productName: data?.productName,
        amount: data?.stakingAmount,
      });
    }
  }, [data]);

  const onFinish = async (values: any) => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.modal.getProfit,
        body: {
          id: data?.stakingId,
        },
      });
      if (resp?.result) {
        message.success("Get profit successful");
        onSuccess?.();
        setCancel?.();
      }
    } catch (error) {
      console.log();
    }
  };

  return (
    <ModalBase isVisible={isVisible} setCancel={setCancel} title="Get Profit">
      <div className="pt-[49px] sm:pb-0 pb-[30px]">
        <Form onFinish={onFinish} form={form}>
          <div className="text-[#9F9F9F] mb-[8px] text-[16px]">
            Product Name
          </div>
          <Form.Item
            name="productName"
            rules={[
              { required: true, message: "Please input your product name!" },
            ]}
          >
            <InputBase type="text" readOnly />
          </Form.Item>
          <div className="text-[#9F9F9F] mb-[8px] text-[16px] mt-[15px]">
            Amount
          </div>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: "Please input your amount!" }]}
          >
            <InputBase
              type="text"
              suffix={
                <div className="text-[#fff] text-[18px] font-montserrat600">
                  USDT
                </div>
              }
              readOnly
            />
          </Form.Item>
          <div className="text-[#9F9F9F] text-[16px] mt-[15px] leading-none">
            Profit
          </div>
          <div className="pb-[30px] flex flex-col gap-[18px]">
            <div className="text-[#fff] text-[16px] pt-[5px] sm:leading-none flex items-center justify-between flex-wrap">
              <div>- Expected reward for {data?.daysDuration} days</div>
              <div className="flex gap-[10px] items-center">
                <div className="font-montserrat600 sm:inline block sm:pl-0 pl-[10px]">
                  {formatPrice(data?.stakingExpectedProfit, 4)}
                  {data?.assetType}
                </div>
                <TooltipIcon text="This is the estimated profit you will receive after completing the earning period for this product" />
              </div>
            </div>
            {dataUser?.membershipLevel > 0 && (
              <>
                <div className="text-[#fff] text-[16px] sm:leading-none flex items-center justify-between flex-wrap">
                  <div className="flex items-center gap-[5px]">
                    <div>- Membership reward for </div>
                    <CheckRank rank={dataUser?.membershipLevel} />
                  </div>
                  <div className="flex gap-[10px] items-center">
                    <div className="font-montserrat600 sm:inline block sm:pl-0 pl-[10px]">
                      {formatPrice(
                        data?.stakingExpectedProfit *
                          dataUser?.currentStakingBonusPercent,
                        4
                      )}{" "}
                      {data?.assetType}
                    </div>
                    <TooltipIcon text="As a membership benefit, you receive additional rewards based on your rank" />
                  </div>
                </div>
                <div className="text-[#fff] text-[16px] sm:leading-none flex items-center justify-between flex-wrap">
                  <div>- Total expected reward</div>
                  <div className="flex gap-[10px] items-center">
                    <div className="font-montserrat700 text-[20px] text-[#B0F127] sm:inline block sm:pl-0 pl-[10px]">
                      {formatPrice(
                        data?.stakingExpectedProfit *
                          dataUser?.currentStakingBonusPercent +
                          data?.stakingExpectedProfit,
                        4
                      )}{" "}
                      {data?.assetType}
                    </div>
                    <TooltipIcon text="This is the sum of your expected staking reward and your membership bonus reward" />
                  </div>
                </div>
              </>
            )}

            <div className="text-[#fff] text-[16px] sm:leading-none flex items-center justify-between flex-wrap">
              <div>- Stop earning : </div>
              <div className="flex gap-[10px] items-center">
                <div className="text-[20px] text-[#F12727] font-montserrat600 sm:inline block sm:pl-0 pl-[10px]">
                  {formatPrice(data?.nontermProfit || 0, 4)} USDT
                </div>
                <TooltipIcon text="This is the amount you will receive if you stop earning right now" />
              </div>
            </div>
          </div>
          {/* <div className="text-[#fff] text-[16px] pt-[5px] sm:leading-none">
            - {data?.daysDuration} day total reward :{" "}
            <span className="text-[20px] text-[#B0F127] font-montserrat600 sm:inline block">
              {formatPrice(data?.stakingExpectedProfit, 4)} USDT
            </span>
          </div> */}
          {/* <div className="text-[#9F9F9F] text-[16px] leading-none">Point</div>
          <div className="text-[#fff] text-[16px] pt-[5px] mb-[8px] sm:leading-none flex items-center justify-between flex-wrap">
            <div>- Expected point for {data?.daysDuration} days:</div>
            <div className="font-montserrat600">
              {data?.percentPointReward *
                data?.daysDuration *
                data?.stakingAmount}{" "}
              Points
            </div>
          </div>
          <div className="text-[#fff] text-[16px] pt-[5px] mb-[8px] sm:leading-none flex items-center justify-between flex-wrap">
            <div>- Points received up to now:</div>
            <div className="font-montserrat600">
              {data?.stakingPointReceived} Points
            </div>
          </div> */}

          <div className="text-[#FF6262] text-[16px] pb-[30px] pt-[5px]">
            * Get profit may take up to 48 hours to process.
          </div>

          <div className="flex gap-[20px]">
            <button
              onClick={setCancel}
              type="button"
              className="w-full h-[60px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              Cancel
            </button>
            <button
              disabled={data?.stakingStatus === 2}
              type="submit"
              className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
            >
              Request
            </button>
          </div>
        </Form>
      </div>
    </ModalBase>
  );
}

export default GetProfit;
