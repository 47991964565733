import InputBase from "@/components/Input";
import InputPassword from "@/components/InputPassword";
import { Form, message } from "antd";
import Logo from "@/assets/img/logo.png";
import StartEarning from "@/components/StartEarning";
import Earning from "@/components/Earning";
import GetProfit from "@/components/GetProfit";
import WithDraw from "@/components/WithDraw";
import Deposit from "@/components/Deposit";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/state/hooks";
import { setLoading } from "@/state/globalSlice";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { handleSignIn } from "@/state/authSlice";
import { UrlInternal } from "@/constant/endpointRoute";
import { useState } from "react";
// import PhoneInput, {
//   getCountries,
//   getCountryCallingCode,
// } from "react-phone-number-input";
import ImgEmail from "@/assets/images/auth/findid.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";

// function CountrySelect({ value, onChange, labels }: any) {
//   return (
//     <select
//       value={value}
//       onChange={(event) => onChange(event.target.value || undefined)}
//     >
//       {getCountries().map((country) => (
//         <option key={country} value={country}>
//           {country} +{getCountryCallingCode(country)}
//         </option>
//       ))}
//     </select>
//   );
// }

function ForgotId() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSendCodePhone, setIsSendCodePhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [form] = Form.useForm();
  const [emailFind, setEmailFind] = useState<string>();

  const onFinish = async (values: any) => {
    dispatch(setLoading(true));
    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.findId,
        body: {
          phone: values?.phoneNumber,
          phoneOtp: values?.verifyCodePhone,
        },
      });
      if (res?.result) {
        setEmailFind(res?.data?.email);
        message.success("Forgot Email successful");
      }
    } catch (error) {
      // message.error("Forgot Email failed");
    }
    dispatch(setLoading(false));
  };

  const handleSendCodePhone = async () => {
    setIsSendCodePhone(true);
    dispatch(setLoading(true));
    try {
      const res: any = await sendApiRequest({
        ...apiConfig.auth.requestOTP,
        body: {
          source: form.getFieldValue("phoneNumber"),
          sourceType: "phone",
        },
      });
      if (res?.result) {
        message.success("Send code phone successful");
      }
    } catch (error) {
      console.error("Send code phone failed:", error);
    }
    dispatch(setLoading(false));
  };

  const handlePhoneChange = (value: string, country: any) => {
    // Thêm dấu "+" vào đầu nếu thiếu
    const formattedValue = value.startsWith("+") ? value : `+${value}`;

    // Dùng libphonenumber-js để chuẩn hóa về E.164
    const parsedNumber = parsePhoneNumberFromString(
      formattedValue,
      country?.countryCode
    );
    const e164Number = parsedNumber ? parsedNumber.number : formattedValue;

    form.setFieldsValue({ phoneNumber: e164Number.toString().trim() });
    setPhoneNumber(e164Number.toString().trim());
  };

  return (
    <div className="flex items-center justify-center h-screen flex-col">
      <div className="sm:flex hidden justify-center mb-[44px]">
        <img src={Logo} alt="img" />
      </div>

      <div
        className={`bg-gradient sm:px-[49px] px-[16px] sm:pt-[54px] pb-[80px] overflow-y-auto rounded-[50px] sm:border sm:border-[#FFFFFF40] sm:border-solid sm:w-[550px]`}
      >
        {emailFind ? (
          <div className="text-center">
            <img src={ImgEmail} alt="ImgEmail" className="mx-auto" />
            <div className="my-[40px] text-[16px] font-montserrat600">
              Your Email :{" "}
              <span className="text-[20px] text-[#B0F127]">{emailFind}</span>
            </div>
            <button
              onClick={() => navigate(UrlInternal.LOGIN)}
              type="button"
              className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              Close
            </button>
          </div>
        ) : (
          <>
            <div className="text-[32px] font-montserrat700 text-center sm:mb-[49px] mb-[86px]">
              Forgot Email
            </div>
            <Form onFinish={onFinish} form={form} layout="vertical">
              <Form.Item
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
                label="Phone Number"
              >
                <div className="border border-solid border-[#FFFFFF59] rounded-[48px] flex w-full items-center h-[60px] pr-[8px] pl-[15px] input-country">
                  {/* <PhoneInput
                    country="US"
                    value={phoneNumber}
                    onChange={(value) => {
                      console.log("value", value);

                      setPhoneNumber(value);
                      form.setFieldsValue({ phoneNumber: value });
                    }}
                    placeholder="Phone Number"
                    className="flex-1 gap-[12px]"
                    limitMaxLength
                    // displayInitialValueAsLocalNumber={false}
                    // international
                    countrySelectComponent={CountrySelect}
                  /> */}
                  <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    // onChange={(value) => {
                    //   console.log("value", value);
                    //   setPhoneNumber(value);
                    //   form.setFieldsValue({ phoneNumber: value });
                    // }}
                    onChange={handlePhoneChange}
                    enableSearch
                    // limitMaxLength={true}
                    prefix="+"
                  />
                  <button
                    type="button"
                    onClick={handleSendCodePhone}
                    disabled={phoneNumber?.length <= 0 || isSendCodePhone}
                    className="h-[40px] min-w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47]"
                  >
                    Send
                  </button>
                </div>
              </Form.Item>
              <Form.Item
                label="Verify Code"
                name="verifyCodePhone"
                rules={[
                  { required: true, message: "Please input your verify code!" },
                ]}
              >
                <InputBase
                  type="text"
                  placeholder="Verify Code"
                  // suffix={
                  //   <button
                  //     onClick={handleVerifyCodePhone}
                  //     type="button"
                  //     className="h-[40px] w-[85px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 border-0 rounded-[48px] mr-[-16px] cursor-pointer disabled:bg-[#4B4D47]"
                  //   >
                  //     Verify
                  //   </button>
                  // }
                />
              </Form.Item>
              <Form.Item>
                <button
                  type="submit"
                  className="sm:mt-[20px] mt-[25px] w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
                >
                  Find Email
                </button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </div>
  );
}

export default ForgotId;
