import ImgBottom from "@/assets/img/bottom.png";
import ImgCopy from "@/assets/img/Copy.png";
import ImgTop from "@/assets/img/top.png";
import { formatPrice } from "@/utils/formatPrice";
import { message } from "antd";
import moment from "moment";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
  dt: any;
}

function Collapse({ dt }: Props) {
  const [isShow, setIsShow] = useState(false);

  return (
    <div className="py-[20px] border-0 border-solid border-b border-[#2F2F2F]">
      <div className="cursor-pointer" onClick={() => setIsShow(!isShow)}>
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center">
            <div className="pr-[10px] h-[20px] leading-[20px] border-0 border-r border-solid border-[#2E2E2E]">
              {dt?.fromAsset}
            </div>
            <div className="pl-[10px] h-[20px] leading-[20px]">
              {dt?.toAsset}
            </div>
          </div>
          <div className="text-[18px] font-montserrat600">
            {formatPrice(dt?.fromAmount, 4)} {dt?.fromAsset}
          </div>
        </div>
        <div className="flex justify-between items-center mt-[8px]">
          <div className="text-[#C0C0C0] text-[13px]">
            {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
          </div>
          <div className="flex items-center">
            <img src={!isShow ? ImgBottom : ImgTop} alt="top" />
          </div>
        </div>
      </div>
      {isShow && (
        <div className="p-[16px] mt-[15px] flex flex-col gap-[15px] bg-[#141414] rounded-[12px]">
          <div className="flex justify-between items-center">
            <div className="text-[13px] text-[#C0C0C0]">To</div>
            <div className="underline">
              {formatPrice(dt?.toAmount, 4)} {dt?.toAsset}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-[13px] text-[#C0C0C0]">Rate</div>
            <div className="text-right">
              <div>
                {formatPrice(1, 8)} {dt?.fromAsset}={" "}
                {formatPrice(1 * dt?.ratio, 8)} {dt?.toAsset}
              </div>
              <div>
                {formatPrice(1, 8)} {dt?.toAsset}={" "}
                {formatPrice(1 * dt?.ratio, 8)} {dt?.fromAsset}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[13px] text-[#C0C0C0]">Fee</div>
            <div>
              {formatPrice(dt?.feeAmount, 4)} {dt?.feeAsset}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Collapse;
