import RankSilver from "@/assets/images/mypage/silver.svg";
import RankGold from "@/assets/images/mypage/gold.svg";
import RankDiamond from "@/assets/images/mypage/diamond.svg";
import RankBlack from "@/assets/images/mypage/black.svg";

const dataRank = [RankSilver, RankGold, RankDiamond, RankBlack];

interface Props {
  rank: number;
}
function CheckRank({ rank }: Props) {
  return rank > 0 && <img src={dataRank[rank - 1]} alt="img" />;
}

export default CheckRank;
