import IconClose from "@/assets/img/x.png";

interface Props {
  title?: string;
  children?: React.ReactNode;
  isVisible: boolean;
  setCancel?: () => void;
  className?: string;
  classNameContent?: string;
  onConfirm?: () => void;
  textCancel?: string;
  textOk?: string;
  isIconClose?: boolean;
}

function ModalBase({
  title,
  children,
  isVisible,
  setCancel,
  className,
  classNameContent,
  onConfirm,
  textCancel,
  textOk,
  isIconClose,
}: Props) {
  return (
    isVisible && (
      <div
        className="fixed z-[10000000] overflow-y-auto top-0 w-full left-0"
        // className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
        id="modal"
      >
        <div className="flex sm:items-center items-end justify-center text-center sm:block sm:p-0 text-[#fff]">
          <div className="fixed inset-0 transition-opacity">
            <div
              className="absolute inset-0 bg-[#000] opacity-85"
              onClick={setCancel}
            />
          </div>
          <span className="inline-block align-middle h-screen">&#8203;</span>
          <div
            className={`inline-flex flex-col align-center rounded-[2px] text-left shadow-xl transform transition-all align-middle w-full
            max-h-[80vh] relative sm:max-w-[500px] ${className}`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {isIconClose && (
              <div className="flex justify-center absolute xs:right-[37px] right-[16px] top-[37px] z-10">
                <div onClick={setCancel} className="cursor-pointer">
                  <img
                    src={IconClose}
                    alt="icon"
                    className="xs:max-w-max max-w-[16px]"
                  />
                </div>
              </div>
            )}
            <div
              className={`bg-gradient-modal sm:px-[49px] px-[16px] sm:pt-[54px] pt-[46px] sm:pb-[68px] pb-[9px] overflow-y-auto sm:rounded-[50px] rounded-t-[50px] sm:border sm:border-[#FFFFFF40] sm:border-solid ${classNameContent}`}
            >
              {title && (
                <div className="text-[32px] font-montserrat700 text-center">
                  {title}
                </div>
              )}
              <div>{children}</div>
              {(textOk || textCancel) && (
                <div className="flex gap-[20px]">
                  {textOk && (
                    <button
                      type="button"
                      className="w-full h-[60px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
                      onClick={onConfirm}
                    >
                      {textOk}
                    </button>
                  )}
                  {textCancel && (
                    <button
                      type="button"
                      className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
                      onClick={setCancel}
                    >
                      {textCancel}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ModalBase;
