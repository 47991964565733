import { formatPrice } from "@/utils/formatPrice";
import Eye from "@/assets/images/Eye.svg";
import Calculator from "@/assets/images/Calculator.svg";
import CaretRight from "@/assets/images/CaretRight.svg";
import { UrlInternal } from "@/constant/endpointRoute";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "antd";
import ImgCopy from "@/assets/img/Copy-white.png";
import { useAppSelector } from "@/state/hooks";
import { dataUserSelect } from "@/state/authSlice";

interface Props {
  dataBalance: any;
}

function Balance({ dataBalance }: Props) {
  const navigate = useNavigate();

  const dataUser = useAppSelector(dataUserSelect);
  const refLink = `${window.location.origin}${UrlInternal.SIGNUP}?referralCode=${dataBalance?.referralCode}`;

  const dataMyBalance = dataBalance?.balances?.find?.(
    (item: any) => item.asset_type === "USDT"
  );

  const DataInfo = [
    // {
    //   label: "My Balance",
    //   value: dataMyBalance?.balance || 0,
    // },
    {
      label: "My Holdings",
      value: dataBalance?.totalStakingUsd || 0,
    },
    {
      label: "Total Profit",
      value: dataBalance?.totalProfitStakingUsd || 0,
      routes: UrlInternal.HISTORY_EARN_PROFIT,
    },
    {
      label: "Referral Profit",
      value: dataBalance?.totalProfitReferral || 0,
      routes: UrlInternal.HISTORY_EARN_PROFIT,
      searchParams: "REFERRAL_PROFIT_GENERAL",
    },
    {
      label: "Points",
      value: dataBalance?.point,
    },
  ];

  return (
    <div className="bg-[linear-gradient(180deg,_#2E2E2E_0%,_rgba(46,_46,_46,_0)_100%)] lg:py-8 lg:px-[32px] py-[28px] px-[15px] border-solid border-white/25 border-[1px] rounded-[18px] flex lg:gap-[64px] gap-[20px] lg:flex-row flex-col">
      <div className="lg:border-0 border-0 border-b border-solid border-[#FFFFFF40] lg:pb-0 pb-[20px]">
        <div className="lg:pr-[64px] flex flex-col border-0 lg:border-r border-solid border-[#FFFFFF40] w-fit sm:mx-0 mx-auto">
          <div className="sm:text-[22px] text-[20px] font-montserrat600">
            {dataUser?.userName}
          </div>
          <div className="flex items-center gap-[28px] mt-[8px]">
            <div className="whitespace-nowrap text-[#C0C0C0] text-[14px] min-w-[202px]">
              Ref code: {dataBalance?.referralCode}
            </div>
            <CopyToClipboard
              text={dataBalance?.referralCode}
              onCopy={() => message.success("Copied")}
            >
              <img
                src={ImgCopy}
                alt="copy"
                className="cursor-pointer mx-auto"
              />
            </CopyToClipboard>
          </div>
          <div className="flex items-center gap-[28px]">
            <div className="flex items-center gap-[8px] mt-[5px] min-w-[202px]">
              <div className="whitespace-nowrap text-[14px] text-[#C0C0C0]">
                Ref link:{" "}
              </div>
              <div className="text-[#50F3FF] truncate flex-1  max-w-[145px] text-[14px]">
                {refLink}
              </div>
            </div>
            <CopyToClipboard
              text={refLink}
              onCopy={() => message.success("Copied")}
            >
              <img
                src={ImgCopy}
                alt="copy"
                className="cursor-pointer mx-auto"
              />
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full sm:gap-[36px] gap-[20px] sm:flex-row flex-col">
        {/* <div className="grid lg:grid-cols-[1fr_1fr_1fr_1fr] md:grid-cols-[1fr_1fr] grid-cols-[1fr]  justify-between w-full gap-8"> */}
        {DataInfo?.map((v, index) => {
          return (
            <div key={index} className="">
              <div
                className="md:text-[16px] text-[14px] text-[#C0C0C0] font-[400] flex items-center justify-center lg:justify-start md:gap-2 gap-[5px] cursor-pointer"
                onClick={() => {
                  if (v?.routes) {
                    const scrollPosition = window.scrollY || window.pageYOffset;
                    sessionStorage.setItem(
                      "positionScrollY",
                      scrollPosition.toString()
                    );
                    navigate({
                      pathname: v?.routes,
                      search: createSearchParams({
                        tab: v?.searchParams ?? "",
                      }).toString(),
                    });
                  }
                }}
              >
                {v?.label}
                {index === 0 && (
                  <>
                    <span className="cursor-pointer hover:opacity-80 max-w-4 lg:max-w-[21px]">
                      <img src={Eye} />
                    </span>
                    <span className="cursor-pointer hover:opacity-80 max-w-4 lg:max-w-[21px">
                      <img src={Calculator} />
                    </span>
                  </>
                )}
                {(index === 1 || index === 2) && (
                  <>
                    <span className="cursor-pointer hover:opacity-80 max-w-4 lg:max-w-[21px">
                      <img src={CaretRight} />
                    </span>
                  </>
                )}
              </div>
              <div className="text-[25px] md:text-[30px] font-[600] lg:text-left text-center">
                {index !== 3 && <span className="text-[#B0F127]">$</span>}
                {formatPrice(v?.value)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Balance;
