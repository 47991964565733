import ApproximateEquals from "@/assets/images/ApproximateEquals.png";
import Deposit from "@/components/Deposit";
import SelectCoin from "@/components/SelectCoin";
import WithDraw from "@/components/WithDraw";
import { UrlInternal } from "@/constant/endpointRoute";
import { formatPrice } from "@/utils/formatPrice";
import { Select } from "antd";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  dataBalance: any;
}

function EstimateBalance({ dataBalance }: Props) {
  const defaultCoin = dataBalance?.balances[0]?.asset_type;
  const [coinSelected, setCoinSelected] = useState(defaultCoin);
  const navigate = useNavigate();
  const [isDeposit, setIsDeposit] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);

  const getDataBalance = useMemo(() => {
    return dataBalance?.balances?.find(
      (dt: any) => dt.asset_type === (coinSelected || defaultCoin)
    );
  }, [coinSelected, dataBalance]);

  return (
    <>
      <WithDraw isVisible={isWithdraw} setCancel={() => setIsWithdraw(false)} />
      <Deposit isVisible={isDeposit} setCancel={() => setIsDeposit(false)} />

      <div className="sm:mb-10 mb-[36px] flex justify-between lg:items-center lg:flex-row flex-col gap-[16px]">
        <div>
          <div className="flex items-center gap-[11px]  mb-[10px]">
            <div className="font-montserrat600 sm:text-[18px] text-[14px] text-[#B0F127] ">
              Your Estimated Balance
            </div>
            {/* <img src={ImgRight} alt="right" /> */}
          </div>
          <div className="flex gap-[13px] items-end leading-none">
            <div className="sm:text-[36px] text-[24px] font-montserrat600">
              {formatPrice(getDataBalance?.balance || 0)}
            </div>
            <div className="flex gap-[14px]">
              <SelectCoin
                height={20}
                onChange={(value) => {
                  setCoinSelected(value);
                }}
                value={coinSelected || defaultCoin}
                className="custom-select"
                dropdownMatchSelectWidth={false}
              >
                {dataBalance?.balances?.map((dt: any, i: number) => (
                  <Select.Option key={i} value={dt.asset_type}>
                    <div className="text-[#fff] min-w-[5px] max-w-[20px] sm:text-[16px] text-[14px]">
                      {dt.asset_type}
                    </div>
                  </Select.Option>
                ))}
              </SelectCoin>

              <div className="flex gap-[4px] items-center">
                <img src={ApproximateEquals} alt="ApproximateEquals" />
                <div className="font-montserrat600 sm:text-[16px] text-[14px]">
                  ${formatPrice(getDataBalance?.balanceUSD || 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-[12px] flex-wrap">
          <button
            onClick={() => setIsDeposit(true)}
            type="button"
            className="sm:w-[129px] w-[calc(50%_-_6px)] sm:h-[50px] h-[36px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] sm:flex-1 sm:text-[18px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
          >
            Deposit
          </button>
          <button
            onClick={() => setIsWithdraw(true)}
            type="button"
            className="sm:w-[129px] w-[calc(50%_-_6px)] sm:h-[50px] h-[36px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] sm:flex-1 sm:text-[18px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
          >
            Withdraw
          </button>
          <button
            onClick={() => navigate(UrlInternal.CONVERT)}
            type="button"
            className="sm:w-[129px] w-[calc(50%_-_6px)] sm:h-[50px] h-[36px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] sm:flex-1 sm:text-[18px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            Convert
          </button>
          <button
            onClick={() => navigate(UrlInternal.HISTORY_EARN_PROFIT)}
            type="button"
            className="sm:w-[129px] w-[calc(50%_-_6px)] sm:h-[50px] h-[36px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] sm:flex-1 sm:text-[18px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            History
          </button>
        </div>
      </div>
    </>
  );
}

export default EstimateBalance;
