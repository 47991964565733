import Banner from "@/assets/images/membership/banner.svg";
// import Banner from "@/assets/images/membership/banner.png";
import BannerMobile from "@/assets/images/membership/banner_mobile.svg";
// import BannerMobile from "@/assets/images/membership/banner_mobile.png";
// import BannerMobileX2 from "@/assets/images/membership/banner_mobile_x2.png";
import { UrlInternal } from "@/constant/endpointRoute";
import { useNavigate } from "react-router-dom";

function MembershipNft() {
  const navigate = useNavigate();

  return (
    <div
      className="cursor-pointer"
      onClick={() => navigate(UrlInternal.MEMBERSHIP_NFT)}
    >
      <img src={Banner} alt="img" className="md:block hidden w-full" />
      <img src={BannerMobile} alt="img" className="md:hidden block w-full" />
      {/* <img
        src={BannerMobileX2}
        alt="img"
        className="md:hidden xs:block hidden"
      />
      <img src={BannerMobile} alt="img" className="xs:hidden block w-full" /> */}
    </div>
  );
}

export default MembershipNft;
