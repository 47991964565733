import "./index.scss";

interface Props {
  level: number;
  limitMembershipGradeCondition: string;
  className?: string;
}

function TagRank({ level, limitMembershipGradeCondition, className }: Props) {
  const handleRenderBg = () => {
    switch (level) {
      case 1:
        return "bg-silver text-[#C0C0C0]";
      case 2:
        return "bg-gold text-[#EFB90B]";
      case 3:
        return "bg-diamond text-[#27F1F1]";
      case 4:
        return "bg-black text-[#FFFFFF]";
      default:
        return "";
    }
  };

  const handleRenderLevel = () => {
    switch (level) {
      case 1:
        return "Silver";
      case 2:
        return "Gold";
      case 3:
        return "Diamond";
      case 4:
        return "Black";
      default:
        return "";
    }
  };

  return (
    <div className={`tag-rank flex justify-end ${className}`}>
      <div
        className={`px-[10px] py-[3px] text-center rounded-[5px] text-[10px] font-montserrat700 ${handleRenderBg()}`}
      >
        {handleRenderLevel()}{" "}
        {limitMembershipGradeCondition === "EQUALS" ? "Only" : "+"}
      </div>
    </div>
  );
}

export default TagRank;
