import EyeOff from "@/assets/img/eye-off.png";
import Eye from "@/assets/img/eye.png";
import { Input, InputProps } from "antd";

interface Props extends InputProps {
  children?: React.ReactNode;
}

function InputPassword({ ...rest }: Props) {
  return (
    <Input.Password
      className="!bg-transparent border border-solid border-[#FFFFFF59] h-[60px] rounded-[48px] px-[25px] text-[#fff] text-[18px] placeholder:text-[#FFFFFFCC] hover:border-[#FFFFFF59]
      focus-within:border-[#FFFFFF59]"
      iconRender={(visible) =>
        visible ? <img alt="img" src={Eye} /> : <img alt="img" src={EyeOff} />
      }
      {...rest}
    />
  );
}

export default InputPassword;
