import { HttpProps } from "@/axios/requestApi";

export const pageSizeDefault = 10;

export const apiConfig = {
  auth: {
    loginAuth: {
      method: "post",
      endPoint: `/auth/sign-in`,
    } as HttpProps,
    registerAuth: {
      method: "post",
      endPoint: `/auth/sign-up`,
    } as HttpProps,
    requestOTP: {
      method: "post",
      endPoint: `/auth/request-otp`,
    } as HttpProps,
    verifyOTP: {
      method: "post",
      endPoint: `/auth/check-otp`,
    } as HttpProps,
    checkExist: {
      method: "post",
      endPoint: `/auth/check-exists`,
    } as HttpProps,
    findId: {
      method: "post",
      endPoint: `/auth/find-id`,
    } as HttpProps,
    findPassword: {
      method: "post",
      endPoint: `/auth/recovery-pw `,
    } as HttpProps,
  },
  deposit: {
    getListNetwork: {
      method: "get",
      endPoint: `/cashier/networks`,
    } as HttpProps,
    getListCoin: {
      method: "get",
      endPoint: `/cashier/coins`,
    } as HttpProps,
    getDepositAddress: {
      method: "post",
      endPoint: `/cashier/deposit/address`,
    } as HttpProps,
    requestWithdraw: {
      method: "post",
      endPoint: `/cashier/withdraw/request`,
    } as HttpProps,

    getListDepositHistory: {
      method: "get",
      endPoint: `/cashier/deposit/history`,
    } as HttpProps,
    getListWithdrawHistory: {
      method: "get",
      endPoint: `/cashier/withdraw/history`,
    } as HttpProps,
    getWithdrawFee: {
      method: "get",
      endPoint: `/cashier/withdraw/fee`,
    } as HttpProps,
  },

  referralProfit: {
    getList: {
      method: "get",
      endPoint: `/referral/profit-history`,
    } as HttpProps,
  },
  earnProfit: {
    getList: {
      method: "get",
      endPoint: `/staking/my-staking`,
    } as HttpProps,
  },

  coins: {
    getList: {
      method: "get",
      endPoint: `/staking/list-asset`,
    } as HttpProps,
  },
  products: {
    getList: {
      method: "get",
      endPoint: `/staking/products`,
    } as HttpProps,
  },

  modal: {
    startEarning: {
      method: "post",
      endPoint: `/staking/staking`,
    } as HttpProps,
    getProfit: {
      method: "post",
      endPoint: `/staking/request-withdraw`,
    } as HttpProps,
  },
  user: {
    getStatistic: {
      method: "get",
      endPoint: `/user/balance-statistic`,
    } as HttpProps,
    details: {
      method: "get",
      endPoint: `/user/details`,
    } as HttpProps,
    getLevel: {
      method: "get",
      endPoint: `/membership/grades`,
    } as HttpProps,
  },

  convert: {
    getInfo: {
      method: "get",
      endPoint: `/convert/exchange-info`,
    } as HttpProps,
    onConvert: {
      method: "post",
      endPoint: `/convert/convert-asset`,
    } as HttpProps,
    listConvert: {
      method: "get",
      endPoint: `/convert/convert-history`,
    } as HttpProps,
  },

  membershipNft: {
    getList: {
      method: "get",
      endPoint: `/membership/my-inventory`,
    } as HttpProps,
    getListProduct: {
      method: "get",
      endPoint: `/membership/list-product`,
    } as HttpProps,
    buyNft: {
      method: "post",
      endPoint: `/membership/buy-membership`,
    } as HttpProps,
  },
};
