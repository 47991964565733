import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface NextActionState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setLoading } = globalSlice.actions;
export const isLoadingSelect = (state: RootState) => state.global.isLoading;

export default globalSlice.reducer;
