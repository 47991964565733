import ImgBtnConvert from "@/assets/images/button-convert.png";
import ImgArrowLeft from "@/assets/img/arrow-left.png";
import sendApiRequest from "@/axios/requestApi";
import SelectCoin from "@/components/SelectCoin";
import { apiConfig } from "@/constant/apiConfig";
import { UrlInternal } from "@/constant/endpointRoute";
import { Input, message, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalSuccessConvert from "./ModalSuccessConvert";
import { formatPrice } from "@/utils/formatPrice";

function Convert() {
  const [dataListInfo, setDataListInfo] = useState<any>({});
  const [fromSelected, setFromSelected] = useState<string>();
  const [toSelected, setToSelected] = useState<string>();
  const navigate = useNavigate();
  const [isSuccessConvert, setIsSuccessConvert] = useState(false);
  const [amount, setAmount] = useState("0");
  const [dataToReverse, setDataToReverse] = useState<string>();

  const getListInfo = async (isInterval?: boolean) => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.convert.getInfo,
      });

      if (resp?.result) {
        setDataListInfo(resp?.data);

        if (!isInterval) {
          const defaultSelected = resp?.data?.assets[0]?.assetType;
          setFromSelected(defaultSelected);
        }
      }
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    getListInfo();

    const intervalId = setInterval(() => {
      getListInfo(true); // Gọi hàm mỗi 10 giây
    }, 10000); // 10 giây (10000ms)

    // Dọn dẹp khi component unmount
    return () => clearInterval(intervalId);
  }, []);

  const getIconCoin = (symbol: string) => {
    const findItem = dataListInfo?.assets?.find(
      (dt: any) => dt?.assetType === symbol
    );
    return findItem?.icon;
  };

  const getBalanceFrom = () => {
    const findItem = dataListInfo?.balances?.find(
      (dt: any) => dt?.assetType === fromSelected
    );
    return findItem?.balance;
  };
  const getBalanceTo = () => {
    const findItem = dataListInfo?.balances?.find(
      (dt: any) => dt?.assetType === toSelected
    );
    return findItem?.balance;
  };

  const getListCoinTo = useMemo(() => {
    if (!dataListInfo?.exchangeInfo) return [];

    const findItem = dataListInfo?.exchangeInfo?.filter(
      (dt: any) => dt?.fromAsset === fromSelected
    );

    if (!dataToReverse) {
      setToSelected(findItem[0]?.toAsset);
    } else {
      setToSelected(dataToReverse);
    }

    setDataToReverse(undefined);

    return findItem;
  }, [fromSelected]);

  const getDataRateFree = () => {
    const findItem = dataListInfo?.exchangeInfo?.find(
      (dt: any) => dt?.fromAsset === fromSelected && dt?.toAsset === toSelected
    );
    return findItem || {};
  };

  const handleConvert = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.convert.onConvert,
        body: {
          fromAsset: fromSelected,
          toAsset: toSelected,
          amount: Number(amount),
        },
      });

      if (resp?.result) {
        setIsSuccessConvert(true);
      }
    } catch (error) {
      message.error("Convert failed");
    }
  };

  const handleReverse = () => {
    setDataToReverse(fromSelected);
    setFromSelected(toSelected);
    setAmount("0");
    // setAmount((Number(amount) * getDataRateFree()?.ratio).toString());
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Loại bỏ tất cả ký tự không phải số và dấu '.'
    let filteredValue = inputValue.toString().replace(/[^0-9.]/g, "");

    // Đảm bảo chỉ có một dấu '.'
    const parts = filteredValue.split(".");
    if (parts.length > 2) {
      filteredValue = parts[0] + "." + parts.slice(1).join("");
    }

    // if (filteredValue && parseFloat(filteredValue) > getBalanceFrom()) {
    //   return; // Không cập nhật state nếu lớn hơn MAX_VALUE
    // }

    // Cập nhật state
    setAmount(filteredValue);
  };

  return (
    <>
      <ModalSuccessConvert
        // isVisible
        isVisible={isSuccessConvert}
        setCancel={() => {
          setAmount("0");
          getListInfo(true);
          setIsSuccessConvert(false);
        }}
        fromAmount={
          formatPrice(Number(amount), 4) + " " + getDataRateFree()?.fromAsset
        }
        toAmount={
          formatPrice(Number(amount) * getDataRateFree()?.ratio, 8) +
          " " +
          getDataRateFree()?.toAsset
        }
        rate={`${formatPrice(1, 8)} ${
          getDataRateFree()?.fromAsset
        }= ${formatPrice(1 * getDataRateFree()?.ratio, 8)} ${
          getDataRateFree()?.toAsset
        }`}
        fee={
          formatPrice(
            getDataRateFree()?.convertFeePercent *
              Number(amount) *
              getDataRateFree()?.ratio,
            8
          ) +
          " " +
          getDataRateFree()?.toAsset
        }
      />

      <div className="px-[16px]">
        <div
          className={`bg-gradient sm:px-[49px] sm:pt-[54px] pb-[80px] overflow-y-auto sm:rounded-[50px] sm:border sm:border-[#FFFFFF40] sm:border-solid max-w-[1000px] w-full mx-auto sm:mt-[47px] mb-[55px]`}
        >
          <div className="flex items-center justify-between sm:mt-0 mt-[16.5px] mb-[33px]">
            <div
              className="min-w-[24px] cursor-pointer"
              // onClick={() => navigate(UrlInternal.HOME)}
              onClick={() => {
                navigate(UrlInternal.HOME, {
                  state: {
                    isBack: true,
                  },
                });
              }}
            >
              <img src={ImgArrowLeft} alt="img" />
            </div>
            <div className="sm:text-[32px] text-[20px] font-montserrat700 text-center">
              Defylo Convert
            </div>
            <div className="min-w-[24px]"></div>
          </div>

          <div className="max-w-[556px] mx-auto">
            <div
              className="text-right underline text-[#B0F127] text-[14px] font-montserrat500 sm:mt-[27px] sm:mb-[15px] mb-[18px] cursor-pointer"
              onClick={() => navigate(UrlInternal.CONVERT_HISTORY)}
            >
              Convert History
            </div>
            <div className="flex flex-col gap-[15px] relative">
              <img
                src={ImgBtnConvert}
                alt="img"
                className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer"
                onClick={handleReverse}
              />

              <div className="bg-[#181818] p-[30px] flex gap-[35px] rounded-[30px]">
                <div className="flex flex-col justify-between">
                  <div className="text-[#9F9F9F]">From</div>
                  <div className="h-[60px] flex flex-col justify-end">
                    <div className="pb-[5px] border-0 border-b border-solid border-[#FFFFFF33]">
                      <SelectCoin
                        height={20}
                        onChange={(value) => {
                          setFromSelected(value);
                        }}
                        value={fromSelected}
                        className="custom-select"
                        dropdownMatchSelectWidth={false}
                      >
                        {dataListInfo?.assets?.map((dt: any, i: number) => (
                          <Select.Option key={i} value={dt.assetType}>
                            <div className="flex justify-center items-center gap-[10px] option-select">
                              <img
                                src={dt?.icon}
                                alt="bnb"
                                className="max-w-[28px] w-full aspect-square"
                              />
                              <div className="text-[#fff] sm:min-w-[104px] min-w-[59px]">
                                {dt?.assetType}
                              </div>
                            </div>
                          </Select.Option>
                        ))}
                      </SelectCoin>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-[#9F9F9F] text-right sm:max-w-full max-w-[140px] ml-auto">
                    Available Balance: {formatPrice(getBalanceFrom(), 4)}{" "}
                    {getDataRateFree()?.fromAsset}
                  </div>
                  <Input
                    placeholder="Enter amount"
                    className="!bg-transparent p-0 rounded-none border-0 border-b border-solid border-[#FFFFFF59] h-[60px] text-[#B0F127] text-[19px] placeholder:text-[#FFFFFFCC] text-right hover:border-0 hover:border-b hover:border-[#FFFFFF59] 
                    focus-within:border-0 focus-within:border-b focus-within:border-[#FFFFFF59] focus:shadow-none"
                    // onInput={(e: React.FormEvent<HTMLInputElement>) => {
                    //   const inputValue = e.currentTarget.value;
                    //   // Loại bỏ tất cả ký tự không phải số và dấu '.'
                    //   let filteredValue = inputValue.replace(/[^0-9.]/g, "");
                    //   // Đảm bảo chỉ có một dấu '.' duy nhất
                    //   const parts = filteredValue.split(".");
                    //   if (parts.length > 2) {
                    //     filteredValue =
                    //       parts[0] + "." + parts.slice(1).join("");
                    //   }
                    //   // Cập nhật giá trị của input nếu có sự thay đổi
                    //   if (filteredValue !== inputValue) {
                    //     e.currentTarget.value = filteredValue;
                    //   }
                    // }}
                    // onChange={(e) => {
                    //   setAmount(Number(e.target.value));
                    // }}
                    onChange={handleChange}
                    value={amount}
                  />
                </div>
              </div>
              <div className="bg-[#181818] p-[30px] flex gap-[35px] rounded-[30px]">
                <div className="flex flex-col justify-between">
                  <div className="text-[#9F9F9F]">To</div>
                  <div className="h-[60px] flex flex-col justify-end">
                    <div className="pb-[5px] border-0 border-b border-solid border-[#FFFFFF33]">
                      <SelectCoin
                        height={20}
                        onChange={(value) => {
                          setToSelected(value);
                        }}
                        value={toSelected}
                        className="custom-select"
                        dropdownMatchSelectWidth={false}
                      >
                        {getListCoinTo?.map((dt: any, i: number) => (
                          <Select.Option key={i} value={dt.toAsset}>
                            <div className="flex justify-center items-center gap-[10px] option-select">
                              <img
                                src={getIconCoin(dt.toAsset)}
                                alt="bnb"
                                className="max-w-[28px] w-full aspect-square"
                              />
                              <div className="text-[#fff] sm:min-w-[104px] min-w-[59px]">
                                {dt.toAsset}
                              </div>
                            </div>
                          </Select.Option>
                        ))}
                      </SelectCoin>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-[#9F9F9F] text-right sm:max-w-full max-w-[140px] ml-auto">
                    Available Balance: {formatPrice(getBalanceTo(), 4)}{" "}
                    {getDataRateFree()?.toAsset}
                  </div>
                  <Input
                    placeholder="Enter amount"
                    className="!bg-transparent rounded-none border-0 border-b border-solid border-[#FFFFFF59] h-[60px] text-[#B0F127] text-[19px] placeholder:text-[#FFFFFFCC] text-right hover:border-0 hover:border-b hover:border-[#FFFFFF59] 
                    focus-within:border-0 focus-within:border-b focus-within:border-[#FFFFFF59] focus:shadow-none cursor-not-allowed"
                    // onInput={(e: React.FormEvent<HTMLInputElement>) => {
                    //   const inputValue = e.currentTarget.value;
                    //   const filteredValue = inputValue.replace(/[^0-9]/g, ""); // Loại bỏ ký tự không phải số
                    //   const validValue = filteredValue.replace(
                    //     /^(\d*\.\d*)\..*$/,
                    //     "$1"
                    //   ); // Chỉ giữ một dấu chấm
                    //   if (validValue !== inputValue) {
                    //     e.currentTarget.value = validValue;
                    //   }
                    // }}
                    readOnly
                    value={formatPrice(
                      Number(amount) * getDataRateFree()?.ratio,
                      8
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="mt-[20px] mb-[40px] flex flex-col gap-[10px] leading-none">
              <div className="flex items-center justify-between">
                <div className="font-montserrat500 text-[#9F9F9F]">Rate</div>
                <div className="sm:text-[19px] font-montserrat500">
                  {formatPrice(1, 8)} {getDataRateFree()?.fromAsset}={" "}
                  {formatPrice(1 * getDataRateFree()?.ratio, 8)}{" "}
                  {getDataRateFree()?.toAsset}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="font-montserrat500 text-[#9F9F9F]">Fee</div>
                <div className="sm:text-[19px] font-montserrat500">
                  {formatPrice(
                    getDataRateFree()?.convertFeePercent *
                      Number(amount) *
                      getDataRateFree()?.ratio,
                    8
                  )}{" "}
                  {getDataRateFree()?.toAsset}
                </div>
              </div>
            </div>

            {amount && parseFloat(amount) > getBalanceFrom() && (
              <div className="mt-[-20px] mb-[30px] text-[#FF6262]">
                You have entered a quantity that exceeds the balance.
              </div>
            )}

            <button
              type="button"
              className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] text-[18px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
              disabled={!Number(amount) || Number(amount) <= 0}
              onClick={handleConvert}
            >
              Convert
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Convert;
