import { Select, SelectProps } from "antd";
import "./style.scss";

import IconDownClose from "@/assets/icons/select-down.svg?react";
// import IconDownOpen from "@assets/icons/DownOpen.svg"

import { ReactNode, useState } from "react";

interface Props extends SelectProps<any> {
  iconWhenClose?: ReactNode;
  iconWhenOpen?: ReactNode;
  width?: number | null;
  height?: number;
}

function SelectCoin({
  options,
  suffixIcon,
  iconWhenClose,
  width,
  height,
  iconWhenOpen,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Select
      options={options}
      style={{
        width: width ? `${width}px` : "100%",
        height: height ? `${height}px` : "100%",
      }}
      suffixIcon={
        iconWhenClose || iconWhenOpen ? (
          isOpen ? (
            iconWhenOpen
          ) : (
            iconWhenClose
          )
        ) : isOpen ? (
          <div className="rotate-180 transform-gpu">
            <IconDownClose />
          </div>
        ) : (
          <IconDownClose />
        )
      }
      onDropdownVisibleChange={(open: boolean) => setIsOpen(open)}
      {...props}
      className="select-coin"
      dropdownClassName={"custom-dropdown"}
    />
  );
}

export default SelectCoin;
