import { LocalStoreKey } from "@/constant/localStoreKey";
import { clearAllData, store } from "@/state";
import { handleSignOut } from "@/state/authSlice";
import { message } from "antd";
import axios, { AxiosResponse } from "axios";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({ window });

const axiosClient = axios.create({
  timeout: 300 * 1000,
  headers: {
    "content-type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  async (config: any) => {
    // const token = localStorage.getItem(LocalStoreKey.TOKEN);
    const token = store.getState().auth.token;

    if (config.headers) {
      if (config.requiredToken && token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        // const token = localStorage.getItem(LocalStoreKey.TOKEN);
        // if (token !== null) {
        //   localStorage.removeItem(LocalStoreKey.TOKEN);
        // }
        const token = store.getState().auth.token;
        if (token) {
          // Dispatch action logout khi token không hợp lệ
          // store.dispatch(handleSignOut());
          clearAllData();

          history.push("/auth");
          window.location.reload();
        }
      } else {
        // const data: any = dataCode;
        const errData = error.response.data;
        // message.info(data[errData.errorCode?.trim()]);
        if (
          !["stk_404", "result_404", "invalid_data"].includes(
            errData?.errorCode
          )
        ) {
          message.error(errData?.message);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
