import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "..";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";

export interface WithdrawState {
  data: any | null;
}

const initialState: WithdrawState = {
  data: null,
};

export const withdrawSlice = createSlice({
  name: "withdraw",
  initialState,
  reducers: {
    setDataWithdraw: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setDataWithdraw } = withdrawSlice.actions;

export const getDataWithdrawList =
  (page: string | number, pageSize: number): AppThunk =>
  async (dispatch) => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.deposit.getListWithdrawHistory,
        body: {
          page: Number(page),
          size: pageSize,
          query: "",
          startTime: "",
          endTime: "",
        },
      });
      if (resp?.datas?.length > 0) {
        dispatch(setDataWithdraw(resp));
      } else {
        dispatch(setDataWithdraw(null));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const withdrawSelect = (state: RootState) => state.withdraw.data;

export default withdrawSlice.reducer;
