import Flexible from "@/assets/images/Flexible.png";
import staking from "@/assets/images/staking.png";
import unstaking from "@/assets/images/unstaking.png";
import invest from "@/assets/images/invest.png";
import payments from "@/assets/images/payments.png";

function Buy() {
  return (
    <div
      className="max-w-[1312px] px-4 py-0 md:mt-[240px] mt-[65px] w-full m-auto"
      id="KeyFeatures"
    >
      <div className="flex justify-between mb-10 md:mb-[64px] md:flex-row flex-col gap-[24px]">
        <div className="text-[35px] md:text-[54px] font-[600] max-w-[668px] leading-[42px] md:leading-[66px] text-center md:text-left px-2 md:px-0">
          Why <span className=" text-[#B0F127]">DeFylo</span>?
        </div>
        <div>
          <div className="text-white text-[16px] md:text-[18px] font-[600] mb-[12px] md:text-right text-center">
            Did You Know Crypto Prices Vary by Country?
          </div>
          <div className="text-[#D8D8D8] md:text-right text-center font-montserrat500 max-w-[500px]">
            Defylo is a global strategy platform that turns market
            inefficiencies into profit by leveraging USDT premiums across
            borders.
          </div>
        </div>
      </div>
      <div className="grid">
        {/* Hàng 1 */}
        <div className="grid md:grid-cols-[2fr_1fr] ">
          <div className="bg-blue-500 text-white border border-[#2F2F2F] border-solid border-b-0 rounded-tl-[18px] rounded-tr-[18px] md:rounded-tr-[0] ">
            <div className="md:px-8 px-4 md:pt-[48px] pt-[36px] mb-[38px] md:mb-0">
              <div className="text-[18px] md:text-[28px] font-[600] md:text-left text-center">
                Buy USDT in lower-priced markets <br />→ Sell in higher-priced
                markets
              </div>
            </div>

            <div className="max-w-[564px] ml-auto">
              <img src={Flexible} className="" />
            </div>
          </div>
          <div className="bg-green-500 px-[30px] py-[68px] text-white border md:border-l-0 border-b-0 border-[#2F2F2F] border-solid md:rounded-tr-[18px]">
            <div className="flex flex-col h-full justify-center items-center gap-4">
              <img src={staking} className="w-[113px] mb-[9px]" />
              <div className="text-[18px] md:text-[28px] font-[600] text-center">
                Our automated system ensures lightning-fast, accurate trades —
                with no manual intervention
              </div>
            </div>
          </div>
        </div>

        {/* Hàng 2 */}
        <div className="grid md:grid-cols-3">
          <div className="bg-purple-500 px-[30px] py-[68px] text-white border border-[#2F2F2F] border-b-0 md:border-b-[1px] border-solid md:rounded-bl-[18px]">
            <div className="flex flex-col h-full justify-center items-center gap-4">
              <img src={unstaking} className="w-[113px] mb-[9px]" />
              <div className="text-[18px] md:text-[28px] font-[600] text-center md:max-w-[350px] max-w-[250px]">
                Stable Profits Through Price Arbitrage
              </div>
            </div>
          </div>
          <div className="bg-orange-500 px-[30px] py-[68px] text-white border border-[#2F2F2F] border-b-0 md:border-b-[1px] border-solid md:border-l-0 ">
            <div className="flex flex-col h-full justify-center items-center gap-4">
              <img src={invest} className="w-[113px] mb-[9px]" />
              <div className="text-[18px] md:text-[28px] font-[600] text-center md:max-w-[250px] max-w-[200px]">
                AI-Powered Timing. Global Precision. Maximum Profits.
              </div>
            </div>
          </div>
          <div className="bg-red-500 px-[30px] py-[68px] text-white border border-[#2F2F2F] border-solid rounded-br-[18px] rounded-bl-[18px] md:rounded-bl-[0] md:border-l-0 ">
            <div className="flex flex-col h-full justify-center items-center gap-4">
              <img src={payments} className="w-[113px] mb-[9px]" />
              <div className="text-[18px] md:text-[28px] font-[600] text-center md:max-w-fit max-w-[200px]">
                24/7 Smart Investing, Powered by AI
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buy;
