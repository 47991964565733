import React, { useEffect, useState } from "react";
import CaretDown from "@/assets/images/CaretDown.svg";
import ImgUsdt from "@/assets/img/usdt.png";
import { styled } from "styled-components";
import { useAppSelector } from "@/state/hooks";
import { coinsSelect } from "@/state/coinSlice";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { dataUserSelect, isAuthSelect, tokenSelect } from "@/state/authSlice";
import { useNavigate } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import StartEarning from "@/components/StartEarning";
import Earning from "@/components/Earning";
import GetProfit from "@/components/GetProfit";
import { filtersSelect } from "@/state/filterSlice";
import moment from "moment";
import Progress from "@/components/Progress";
import CheckRank from "@/components/CheckRank";
import NotFound from "@/components/NotFound";
import { formatPrice } from "@/utils/formatPrice";
import TagRank from "@/components/TagRank";

const Wrapper = styled.div`
  width: 100%;
  /* background-color: red; */
`;

// const data = [
//   { id: 1, name: "USDC", icon: ImgUsdt, apr: 28, product: "7 days earn" },
//   { id: 2, name: "USDT", icon: ImgUsdt, apr: 32, product: "7 days earn" },
//   { id: 3, name: "FDUSD", icon: ImgUsdt, apr: 25, product: "7 days earn" },
//   { id: 4, name: "BNB", icon: ImgUsdt, apr: 25, product: "7 days earn" },
// ];

const ListCoin: React.FC = () => {
  const navigate = useNavigate();
  const dataListCoin = useAppSelector(coinsSelect);
  const dataFilters = useAppSelector(filtersSelect);
  const dataUser = useAppSelector(dataUserSelect);
  const isAuth = useAppSelector(isAuthSelect);

  const [isActive, setIsActive] = useState("");
  const [dataProducts, setDataProducts] = useState([]);
  const [tableParams] = useState({
    page: 1,
    size: 999,
  });
  const [totalElement, setTotalElement] = useState(null);

  const [isStartEarning, setIsStartEarning] = useState({
    open: false,
    data: {},
  });
  const [isGetProfit, setIsGetProfit] = useState({
    open: false,
    data: {},
  });
  const [isEarning, setIsEarning] = useState({
    open: false,
    data: {},
  });

  const getListProducts = async (params?: any) => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.products.getList,
        body: {
          notHighlight: 1,
          ...tableParams,
          ...params,
        },
      });
      if (resp?.result) {
        const data = resp.data?.additionalProperties?.map((item: any) => {
          const findItem = dataListCoin?.find(
            (v: { assetSymbol: any }) => v?.assetSymbol === item?.assetType
          );
          return {
            ...item,
            icon: findItem?.assetIcon,
          };
        });
        setTotalElement(resp?.data?.pageable?.totalElement || null);
        setDataProducts(data);
      }
    } catch (error) {
      setDataProducts([]);
      setTotalElement(null);
    }
  };

  useEffect(() => {
    if (dataListCoin?.length > 0) getListProducts(dataFilters);
  }, [dataListCoin, isAuth, dataFilters]);

  const getListStartEarning = (dataProduct: any) => {
    const dtFilter: any = dataProducts.find(
      (item: any) => item?.assetType === dataProduct?.assetType
    );

    const stakingEndTime = new Date(dataProduct?.userStakingEndTime);
    const timeNow = new Date();

    const listStartEarning = dtFilter?.listProduct?.filter(
      (item: any) =>
        dataUser?.membershipLevel < item?.limitMembershipLevel ||
        (!(
          item?.stakingStatus === 2 ||
          (item?.stakingStatus === 1 &&
            stakingEndTime.getTime() <= timeNow.getTime())
        ) &&
          moment(item?.startAt).isBefore(moment()))
    );

    setIsStartEarning({
      open: true,
      data: {
        ...dataProduct,
        listStartEarning: listStartEarning,
      },
    });
  };

  const renderButton = (item: any) => {
    if (dataUser?.membershipLevel < item?.limitMembershipLevel) {
      return (
        <button
          className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
          onClick={
            () =>
              !isAuth ? navigate(UrlInternal.LOGIN) : getListStartEarning(item)
            // : navigate(UrlInternal.MEMBERSHIP_NFT)
          }
        >
          Upgrade
        </button>
      );
    }
    const stakingEndTime = new Date(item?.userStakingEndTime);
    const timeNow = new Date();
    if (
      item?.stakingStatus === 2 ||
      (item?.stakingStatus === 1 &&
        stakingEndTime.getTime() <= timeNow.getTime())
    )
      return (
        <button
          onClick={() =>
            !isAuth
              ? navigate(UrlInternal.LOGIN)
              : setIsGetProfit({ open: true, data: item })
          }
          className="sm:w-[129px] w-full text-[14px] font-semibold border border-solid border-[#fff] text-[#fff] cursor-pointer hover:opacity-70 bg-[transparent] h-[32px] px-5 rounded-full"
        >
          Get profit
        </button>
      );
    if (
      item?.stakingStatus === 1 &&
      stakingEndTime.getTime() > timeNow.getTime()
    )
      return (
        <button
          onClick={() =>
            !isAuth
              ? navigate(UrlInternal.LOGIN)
              : setIsEarning({ open: true, data: item })
          }
          className="sm:w-[129px] w-full text-[14px] font-semibold border border-solid border-[#B0F127] text-[#B0F127] cursor-pointer hover:opacity-70 bg-[transparent] h-[32px] px-5 rounded-full"
        >
          Earning
        </button>
      );
    return (
      <>
        {moment(item?.startAt).isBefore(moment()) ? (
          <button
            className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
            onClick={() =>
              !isAuth ? navigate(UrlInternal.LOGIN) : getListStartEarning(item)
            }
            disabled={item?.limitTotalStaking === item?.totalStaking}
          >
            Start earning
          </button>
        ) : (
          <button
            className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#fff] h-[32px] rounded-full"
            onClick={() =>
              !isAuth
                ? navigate(UrlInternal.LOGIN)
                : setIsStartEarning({ open: true, data: item })
            }
          >
            Coming soon
          </button>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      <StartEarning
        isVisible={isStartEarning.open}
        setCancel={() => setIsStartEarning({ open: false, data: {} })}
        data={isStartEarning.data}
        onSuccess={() => getListProducts()}
      />
      <Earning
        isVisible={isEarning.open}
        setCancel={() => setIsEarning({ open: false, data: {} })}
        data={isEarning.data}
        onSuccess={() => getListProducts()}
      />
      <GetProfit
        isVisible={isGetProfit.open}
        setCancel={() => setIsGetProfit({ open: false, data: {} })}
        data={isGetProfit.data}
        onSuccess={() => getListProducts()}
      />
      {dataProducts?.length > 0 ? (
        <div className="grid w-full text-white">
          {/* Header */}
          <div className="lg:grid grid-cols-[1fr_1fr_52px] lg:grid-cols-[350px_1fr_1fr_1fr_1fr] bg-[#121212] font-bold hidden">
            <div className="py-3 px-6">Coins</div>
            <div className="py-3 px-6">Est. APR</div>
            <div className="py-3 px-6">Duration</div>
            <div className="py-3 px-6">Subscription Amount</div>
            <div></div>
          </div>

          {/* Rows pc*/}
          <div className="lg:grid grid-rows-[auto] hidden">
            {dataProducts.map((item: any, index) => {
              const isFirst = index === 0;
              // const isLast = index === data?.length - 1;
              const isLast = index === dataProducts?.length - 1;
              const borderClass = `border-[#2F2F2F] border border-solid ${
                isFirst ? "border-t rounded-t-[5px]" : "border-t-0"
              } ${isLast ? "rounded-b-[5px]" : ""}`;
              return (
                <div
                  key={item.assetType}
                  className={`grid grid-cols-[1fr_24px] grid-rows-[auto_auto] lg:grid-cols-[350px_1fr_1fr_1fr_1fr] transition duration-200 ${borderClass}`}
                >
                  <div className={`py-5 px-6 flex items-center gap-[10px]`}>
                    <img src={item?.icon} alt="img" className="w-[28px]" />
                    <div>{item.assetType}</div>
                  </div>
                  <div
                    className={`text-[#B0F127] text-[15px] py-5 px-6 hidden lg:block`}
                  >
                    {item?.minMaxApr}
                  </div>
                  <div className={`py-5 px-6 hidden lg:block`}>
                    {item?.minMaxDaysDuration}
                  </div>
                  <div className={`py-5 px-6 hidden lg:block`}></div>
                  <div
                    className="cursor-pointer hover:opacity-70 flex items-center justify-center"
                    onClick={() =>
                      setIsActive((prev) => {
                        if (item?.assetType === prev) return "";
                        return item?.assetType;
                      })
                    }
                  >
                    <img
                      src={CaretDown}
                      className={`transition-transform duration-300 ${
                        isActive === item?.assetType ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </div>

                  {isActive === item?.assetType && (
                    <>
                      {item?.listProduct?.map((itemSub: any) => (
                        <div
                          className="col-span-6 lg:block hidden bg-[#222222]"
                          key={itemSub?.id}
                        >
                          <div className="grid grid-cols-[1fr_1fr_52px] grid-rows-[auto_auto] md:grid-cols-[350px_1fr_1fr_1fr_1fr]">
                            <div className="flex items-center gap-[8px] py-5 px-6">
                              <div className="text-[#EAECEF] font-montserrat500">
                                {itemSub?.productName}
                              </div>
                              {itemSub?.limitMembershipLevel > 0 && (
                                <TagRank
                                  level={itemSub?.limitMembershipLevel}
                                  limitMembershipGradeCondition={
                                    itemSub?.limitMembershipGradeCondition
                                  }
                                />
                              )}
                            </div>
                            <div
                              className={`text-[#B0F127] text-[15px] py-5 px-6`}
                            >
                              {formatPrice(itemSub?.apr * 100)}%
                            </div>
                            <div className={`py-5 px-6 hidden md:block`}>
                              {itemSub?.daysDuration} days
                            </div>
                            <div
                              className={`px-6 hidden md:flex flex-col justify-center`}
                            >
                              <Progress
                                value={itemSub?.totalStaking}
                                valueTarget={itemSub?.limitTotalStaking}
                                isComingSoon={moment(itemSub?.startAt).isAfter(
                                  moment()
                                )}
                              />
                            </div>
                            <div
                              className={`px-6 hidden md:flex justify-center items-center`}
                            >
                              {renderButton(itemSub)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              );
            })}
          </div>

          {/**Rows mobile */}
          <div className="lg:hidden block">
            {dataProducts.map((item: any, index) => {
              const isFirst = index === 0;
              // const isLast = index === data?.length - 1;
              const isLast = index === dataProducts?.length - 1;
              const borderClass = `border-[#2F2F2F] border border-solid ${
                isFirst ? "border-t rounded-t-[5px]" : "border-t-0"
              } ${isLast ? "rounded-b-[5px]" : ""}`;

              return (
                <div key={item?.id}>
                  <div className={`p-4 ${borderClass}`}>
                    <div className="flex justify-between items-center">
                      <div className={`flex items-center gap-[10px]`}>
                        <img src={item?.icon} alt="img" className="w-[28px]" />
                        <div>{item.assetType}</div>
                      </div>
                      <div
                        className="cursor-pointer hover:opacity-70 flex items-center justify-center"
                        onClick={() =>
                          setIsActive((prev) => {
                            if (item?.assetType === prev) return "";
                            return item?.assetType;
                          })
                        }
                      >
                        <img
                          src={CaretDown}
                          className={`transition-transform duration-300 ${
                            isActive === item?.assetType
                              ? "rotate-180"
                              : "rotate-0"
                          }`}
                        />
                      </div>
                    </div>
                    <div className="flex mt-[12px]">
                      <div className="flex-1">
                        <div className="text-[#C0C0C0] text-[12px] pb-[5px]">
                          Est. APR
                        </div>
                        <div className="text-[#B0F127] font-montserrat700">
                          {item?.minMaxApr}
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="text-[#C0C0C0] text-[12px] pb-[5px]">
                          Duration
                        </div>
                        <div className="text-[#EAECEF] font-montserrat700">
                          {item?.minMaxDaysDuration}
                        </div>
                      </div>
                    </div>
                  </div>

                  {isActive === item?.assetType && (
                    <>
                      {item?.listProduct?.map((itemSub: any) => (
                        <div
                          className="bg-[#222222] border-0 border-t border-b border-solid border-[#2F2F2F] p-4 pb-5"
                          key={itemSub?.id}
                        >
                          <div className="flex items-center gap-[8px]">
                            <div className="text-[#EAECEF] font-montserrat500">
                              {itemSub?.productName}
                            </div>
                            {itemSub?.limitMembershipLevel > 0 && (
                              <TagRank
                                level={itemSub?.limitMembershipLevel}
                                limitMembershipGradeCondition={
                                  itemSub?.limitMembershipGradeCondition
                                }
                              />
                            )}
                          </div>
                          <div className="flex my-[12px]">
                            <div className="flex-1">
                              <div className="text-[#C0C0C0] text-[12px] pb-[5px]">
                                Est. APR
                              </div>
                              <div className="text-[#B0F127] font-montserrat700">
                                {formatPrice(itemSub?.apr * 100)}%
                              </div>
                            </div>
                            <div className="flex-1">
                              <div className="text-[#C0C0C0] text-[12px] pb-[5px]">
                                Duration
                              </div>
                              <div className="text-[#EAECEF] font-montserrat700">
                                {itemSub?.daysDuration} days
                              </div>
                            </div>
                          </div>
                          <Progress
                            value={itemSub?.totalStaking}
                            valueTarget={itemSub?.limitTotalStaking}
                            isComingSoon={moment(itemSub?.startAt).isAfter(
                              moment()
                            )}
                          />
                          <div className="mt-3">{renderButton(itemSub)}</div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NotFound />
      )}
      {/* {totalElement && totalElement > dataProducts?.length && (
        <div
          className=" text-[16px] font-[500] underline text-center mt-8"
          onClick={() =>
            getListProducts({
              ...dataFilters,
              size: undefined,
            })
          }
        >
          View More
        </div>
      )} */}
    </Wrapper>
  );
};

export default ListCoin;
