import moment from "moment";
import ImgUsdt from "@/assets/img/usdt.png";
import ImgBnb from "@/assets/img/bnb.png";
import ImgCopy from "@/assets/img/Copy.png";
import Collapse from "./Collapse";
import { message } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatPrice } from "@/utils/formatPrice";

const tableHead = ["Date", "Coins", "Network", "Amount", "From", "To", "TxID"];

interface Props {
  data: any;
}

function TableDeposit({ data }: Props) {
  return (
    <>
      {/**table pc */}
      <div className="mt-[25px] mb-[30px] md:block hidden">
        <div className="border-0 border-b border-[#5F5F5F] border-solid pb-[11px] flex">
          {tableHead.map((dt, i) => (
            <div
              key={i}
              className="flex-1 text-center text-[#5F5F5F] text-[16px] w-[20%]"
            >
              {dt}
            </div>
          ))}
        </div>

        {data?.map((dt: any, i: number) => (
          <div key={i}>
            <div className="text-center text-[#fff] text-[16px] border-0 border-b border-[#5F5F5F] border-solid w-full flex items-center py-[15.5px] break-words">
              <div className="lg:px-0 px-[10px] w-[14.2857143%]">
                <div className="max-w-[100px] mx-auto text-left">
                  {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
              </div>
              <div className="flex gap-[10px] items-center lg:px-0 px-[10px] w-[14.2857143%] justify-center">
                <img
                  src={dt?.assetIcon}
                  alt="usdt"
                  className="max-w-[28px] w-full aspect-square"
                />
                <div>{dt?.symbol}</div>
              </div>
              <div className="flex gap-[10px] items-center lg:px-0 px-[10px] w-[14.2857143%] justify-center">
                <img
                  src={dt?.networkIcon}
                  alt="bnb"
                  className="max-w-[28px] w-full aspect-square"
                />
                <div>{dt?.networkSymbol}</div>
              </div>
              <div className="lg:px-0 px-[10px] w-[14.2857143%]">
                {formatPrice(dt?.amount || 0)}
              </div>
              <div className="lg:px-0 px-[10px] w-[14.2857143%]">
                {dt?.fromAddress && (
                  <>
                    {dt?.fromAddress?.substring(0, 5)}...
                    {dt?.fromAddress?.substring(dt?.fromAddress?.length - 5)}
                  </>
                )}
              </div>
              <div className="lg:px-0 px-[10px] w-[14.2857143%]">
                {dt?.toAddress && (
                  <>
                    {dt?.toAddress?.substring(0, 5)}...
                    {dt?.toAddress?.substring(dt?.toAddress?.length - 5)}
                  </>
                )}
              </div>
              <div className="lg:px-0 px-[10px] w-[14.2857143%] lg:flex gap-[10px] items-center">
                {dt?.txHash && (
                  <>
                    <div
                      className="text-[#27DDF1] underline cursor-pointer"
                      onClick={() => window.open(dt?.txHashUrl)}
                    >
                      {dt?.txHash?.substring(0, 5)}...
                      {dt?.txHash?.substring(dt?.txHash?.length - 5)}
                    </div>
                    <CopyToClipboard
                      text={dt?.txHash}
                      onCopy={() => message.success("Copied")}
                    >
                      <img
                        src={ImgCopy}
                        alt="copy"
                        className="cursor-pointer mx-auto"
                      />
                    </CopyToClipboard>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/**end table pc */}
      {/**table mobile */}
      <div className="mt-[26px] mb-[28px] md:hidden">
        {data?.map((dt: any, i: number) => (
          <Collapse key={i} dt={dt} />
        ))}
      </div>
      {/**end table mobile */}
    </>
  );
}

export default TableDeposit;
