import ApproximateEquals from "@/assets/images/ApproximateEquals.png";
import Calculator from "@/assets/images/Calculator.svg";
import CaretRight from "@/assets/images/CaretRight.svg";
import Eye from "@/assets/images/Eye.svg";
import sendApiRequest from "@/axios/requestApi";
import Deposit from "@/components/Deposit";
import SelectCoin from "@/components/SelectCoin";
import WithDraw from "@/components/WithDraw";
import { apiConfig } from "@/constant/apiConfig";
import { UrlInternal } from "@/constant/endpointRoute";
import { dataUserSelect } from "@/state/authSlice";
import { useAppSelector } from "@/state/hooks";
import { formatPrice } from "@/utils/formatPrice";
import { Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

interface Props {
  dataBalance: any;
}

function EstimateBalance({ dataBalance }: Props) {
  const dataUser = useAppSelector(dataUserSelect);

  const navigate = useNavigate();
  const [isDeposit, setIsDeposit] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);

  const defaultCoin = dataUser?.balances[0]?.asset_type;
  const [coinSelected, setCoinSelected] = useState(defaultCoin);

  const getDataBalance = useMemo(() => {
    return dataBalance?.balances?.find(
      (dt: any) => dt.asset_type === (coinSelected || defaultCoin)
    );
  }, [coinSelected, dataBalance]);

  const DataInfo = [
    {
      label: "My Holdings",
      value: dataBalance?.totalStakingUsd || 0,
    },
    {
      label: "Total Profit",
      value: dataBalance?.totalProfitStakingUsd || 0,
      routes: UrlInternal.HISTORY_EARN_PROFIT,
    },
    {
      label: "Referral Profit",
      value: dataBalance?.totalProfitReferral || 0,
      routes: UrlInternal.HISTORY_EARN_PROFIT,
      searchParams: "REFERRAL_PROFIT_GENERAL",
    },
    {
      label: "Points",
      value: dataBalance?.point,
    },
  ];

  return (
    <>
      <WithDraw isVisible={isWithdraw} setCancel={() => setIsWithdraw(false)} />
      <Deposit isVisible={isDeposit} setCancel={() => setIsDeposit(false)} />

      <div className="flex lg:gap-[194px] gap-[32px] mb-[50px] xl:px-0 px-[16px] lg:flex-row flex-col">
        <div className="max-w-[480px] w-full">
          <div className="flex items-center gap-[11px] mb-[10px]">
            <div className="font-montserrat600 sm:text-[18px] text-[14px] text-[#B0F127]">
              Your Estimated Balance
            </div>
            {/* <img src={ImgRight} alt="right" /> */}
          </div>
          <div className="flex gap-[13px] items-end leading-none">
            <div className="sm:text-[36px] text-[24px] font-montserrat600">
              {formatPrice(getDataBalance?.balance || 0)}
            </div>
            <div className="flex gap-[14px]">
              <SelectCoin
                height={20}
                onChange={(value) => {
                  setCoinSelected(value);
                }}
                value={coinSelected || defaultCoin}
                className="custom-select"
                dropdownMatchSelectWidth={false}
              >
                {dataUser?.balances?.map((dt: any, i: number) => (
                  <Select.Option key={i} value={dt.asset_type}>
                    <div className="text-[#fff] min-w-[5px] max-w-[20px] sm:text-[16px] text-[14px]">
                      {dt.asset_type}
                    </div>
                  </Select.Option>
                ))}
              </SelectCoin>

              <div className="flex gap-[4px] items-center">
                <img src={ApproximateEquals} alt="ApproximateEquals" />
                <div className="font-montserrat600 sm:text-[16px] text-[14px]">
                  ${formatPrice(getDataBalance?.balanceUSD || 0)}
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-[12px] sm:mt-[36px] mt-[16px]">
            <button
              onClick={() => setIsDeposit(true)}
              type="button"
              className="sm:flex-initial flex-1 lg:w-[96px] w-full sm:h-[50px] h-[36px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606]  sm:text-[16px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
            >
              Deposit
            </button>
            <button
              onClick={() => setIsWithdraw(true)}
              type="button"
              className="sm:flex-initial flex-1 lg:w-[96px] w-full sm:h-[50px] h-[36px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606]  sm:text-[16px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
            >
              Withdraw
            </button>
            <button
              onClick={() => navigate(UrlInternal.CONVERT)}
              type="button"
              className="sm:flex-initial flex-1 lg:w-[96px] w-full sm:h-[50px] h-[36px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127]  sm:text-[16px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              Convert
            </button>
            <button
              onClick={() => navigate(UrlInternal.HISTORY_EARN_PROFIT)}
              type="button"
              className="sm:flex-initial flex-1 lg:w-[96px] w-full sm:h-[50px] h-[36px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127]  sm:text-[16px] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer"
            >
              History
            </button>
          </div>
        </div>

        {/* <div className="flex items-center justify-between w-full sm:gap-[36px] gap-[20px] sm:flex-row flex-col"> */}
        <div className="grid grid-cols-[max-content_max-content] max-lg:grid-cols-[1fr_1fr_1fr_1fr] max-sm:grid-cols-[1fr_1fr] w-full lg:gap-x-[144px] lg:gap-y-[36px] gap-y-[31px]">
          {DataInfo?.map((v, index) => {
            return (
              <div key={index} className="">
                <div
                  className="md:text-[16px] text-[14px] text-[#C0C0C0] font-[400] flex items-center justify-center lg:justify-start md:gap-2 gap-[5px] cursor-pointer"
                  onClick={() => {
                    if (v?.routes) {
                      navigate({
                        pathname: v?.routes,
                        search: createSearchParams({
                          tab: v?.searchParams ?? "",
                        }).toString(),
                      });
                    }
                  }}
                >
                  {v?.label}
                  {index === 0 && (
                    <>
                      <span className="cursor-pointer hover:opacity-80 max-w-4 lg:max-w-[21px]">
                        <img src={Eye} />
                      </span>
                      <span className="cursor-pointer hover:opacity-80 max-w-4 lg:max-w-[21px">
                        <img src={Calculator} />
                      </span>
                    </>
                  )}
                  {(index === 1 || index === 2) && (
                    <>
                      <span className="cursor-pointer hover:opacity-80 max-w-4 lg:max-w-[21px">
                        <img src={CaretRight} />
                      </span>
                    </>
                  )}
                </div>
                <div className="text-[25px] md:text-[30px] font-[600] lg:text-left text-center">
                  {index !== 3 && <span className="text-[#B0F127]">$</span>}
                  {formatPrice(v?.value)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default EstimateBalance;
