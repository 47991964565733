import NotFound from "@/components/NotFound";
import { UrlInternal } from "@/constant/endpointRoute";
import { coinsSelect } from "@/state/coinSlice";
import { useAppSelector } from "@/state/hooks";
import { formatPrice } from "@/utils/formatPrice";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface Props {
  data: any[];
}

const tableHead = ["Coins", "Balance", "Action"];

function TableAsset({ data }: Props) {
  const listCoins = useAppSelector(coinsSelect);
  const navigate = useNavigate();

  return (
    <div className="xl:px-0 px-[16px]">
      <div className="sm:text-[32px] text-[24px] font-montserrat700 leading-none">
        Asset
      </div>
      {data?.length > 0 ? (
        <>
          {/**table pc */}
          <div className="mt-[36px] mb-[50px] sm:block hidden">
            <div className="border-0 border-b border-[#252525] border-solid pb-[11px] flex">
              {tableHead.map((dt, i) => (
                <div
                  key={i}
                  className="flex-1 text-center text-[#5F5F5F] text-[16px] w-[20%]"
                >
                  {dt}
                </div>
              ))}
            </div>

            {data?.map((dt: any, i: number) => (
              <div key={i}>
                <div className="text-center text-[#fff] text-[16px] border-0 border-b border-[#252525] border-solid w-full flex items-center py-[15.5px]">
                  <div className="px-[15px] w-[33.3333%]">
                    <div className="flex justify-center items-center gap-[10px]">
                      <img
                        src={
                          listCoins?.find(
                            (item: any) => item?.assetSymbol === dt?.asset_type
                          )?.assetIcon
                        }
                        alt="img"
                        className="w-[28px] aspect-square"
                      />
                      <div className="min-w-[50px]">{dt?.asset_type}</div>
                    </div>
                  </div>
                  <div className="px-[15px] w-[33.3333%]">
                    {formatPrice(dt?.balance, 4)}
                  </div>
                  <div className="px-[15px] w-[33.3333%]">
                    <div className="flex gap-[12px] justify-center">
                      <button
                        onClick={() => navigate(UrlInternal.CONVERT)}
                        className="text-[14px] font-montserrat600 h-[32px] w-[100px] text-center border border-solid border-[#B0F127] bg-transparent text-[#B0F127] rounded-[48px] cursor-pointer"
                      >
                        Convert
                      </button>
                      <button
                        onClick={() =>
                          navigate({
                            pathname: UrlInternal.HOME,
                            search: `?menu=Earn`,
                          })
                        }
                        className="text-[14px] font-montserrat600 h-[32px] w-[100px] text-center border border-solid border-[#fff] bg-transparent text-[#fff] rounded-[48px] cursor-pointer"
                      >
                        Earn
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/**end table pc */}
          {/**table mobile */}
          <div className="mb-[28px] sm:hidden">
            {data?.map((dt: any, i: number) => (
              <div
                className="py-[20px] border-0 border-solid border-b border-[#2F2F2F]"
                key={i}
              >
                <div className="flex items-center justify-between">
                  <div className="">
                    <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                      Coins
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <img
                        src={
                          listCoins?.find(
                            (item: any) => item?.assetSymbol === dt?.asset_type
                          )?.assetIcon
                        }
                        alt="img"
                        className="w-[28px] aspect-square"
                      />
                      <div>{dt?.asset_type}</div>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                      Balance
                    </div>
                    <div>{formatPrice(dt?.balance, 2)}</div>
                  </div>
                  <div className="flex gap-[12px] flex-col">
                    <button
                      onClick={() => navigate(UrlInternal.CONVERT)}
                      className="text-[12px] font-montserrat600 h-[32px] w-[80px] text-center border border-solid border-[#B0F127] bg-transparent text-[#B0F127] rounded-[48px] cursor-pointer"
                    >
                      Convert
                    </button>
                    <button
                      onClick={() =>
                        navigate({
                          pathname: UrlInternal.HOME,
                          search: `?menu=Earn`,
                        })
                      }
                      className="text-[12px] font-montserrat600 h-[32px] w-[80px] text-center border border-solid border-[#fff] bg-transparent text-[#fff] rounded-[48px] cursor-pointer"
                    >
                      Earn
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/**end table mobile */}
        </>
      ) : (
        <NotFound className="!py-[30px]" />
      )}
    </div>
  );
}

export default TableAsset;
