import ServiceImage from "@/assets/images/Service.png";
import ServiceMobileImage from "@/assets/images/ServiceMobile.png";

function Service() {
  const scrollToSection = (url: string | undefined) => {
    if (!url) return; // Kiểm tra nếu url là undefined
    const element = document.getElementById(url);
    if (element) {
      const topOffset = 100; // Khoảng cách offset
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({
        top: elementPosition - topOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="bg-[#B0F127] w-full" id="Service">
      <div className="max-w-[1364px] px-4 py-0 md:pt-0 pt-[65px] w-full flex flex-col md:flex-row items-center justify-between m-auto md:gap-3 gap-[51px] md:flex">
        <div className="w-full max-w-[695px] hidden md:block">
          <img src={ServiceImage} alt="" />
        </div>
        <div className="flex-1 md:pl-[26px]">
          <div className="text-[35px] md:text-[54px] font-[600] text-[#060606] md:text-left text-center">
            We are DeFylo
          </div>
          <div className="text-[18px] md:text-[20px] font-[600] text-[#2F2F2F] md:text-left text-center md:mt-[33px] mt-[20px] mb-[20px]">
            The World’s First Investment Platform for Small Investors
          </div>
          <div className="text-[18px] md:text-[20px] font-[600] text-[#2F2F2F] md:text-left text-center">
            Start with just 500 USDT and receive stable weekly returns —
            hassle-free and secure.
          </div>

          <div
            className="flex items-center justify-center h-[60px] md:w-[193px] text-center bg-[#060606] text-[#B0F127] text-[18px] mt-[46px] font-montserrat700 rounded-[48px] cursor-pointer"
            onClick={() => scrollToSection("Earn")}
          >
            Start earning
          </div>
        </div>
        <div className="w-full max-w-[695px] md:hidden">
          <img src={ServiceMobileImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Service;
