// src/store/authSlice.ts
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "..";

interface AuthState {
  isAuth: boolean;
  token: string | null;
  dataUser: any;
}

const initialState: AuthState = {
  isAuth: false,
  token: null,
  dataUser: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleSignIn: (state, action: PayloadAction<AuthState>) => {
      state.isAuth = action.payload.isAuth;
      state.token = action.payload.token;
      state.dataUser = action.payload.dataUser;
    },
    handleSignOut: (state) => {
      state.isAuth = false;
      state.token = null;
      state.dataUser = {};
    },
    updateDataUser: (state, action: PayloadAction<AuthState>) => {
      state.dataUser = action.payload;
    },
  },
});

export const { handleSignIn, handleSignOut,updateDataUser } = authSlice.actions;

export const isAuthSelect = (state: RootState) => state.auth.isAuth;
export const tokenSelect = (state: RootState) => state.auth.token;
export const dataUserSelect = (state: RootState) => state.auth.dataUser;

export default authSlice.reducer;
