import { Input, InputProps } from "antd";

interface Props extends InputProps {
  children?: React.ReactNode;
}

function InputBase({ ...rest }: Props) {
  return (
    <Input
      className="!bg-transparent border border-solid border-[#FFFFFF59] h-[60px] rounded-[48px] px-[25px] text-[#fff] text-[18px] placeholder:text-[#FFFFFFCC] hover:border-[#FFFFFF59]
      focus-within:border-[#FFFFFF59]"
      {...rest}
    />
  );
}

export default InputBase;
