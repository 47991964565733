const Loader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full z-[9999999] flex items-center justify-center bg-black bg-opacity-50">
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
