import sendApiRequest from "@/axios/requestApi";
import Earning from "@/components/Earning";
import GetProfit from "@/components/GetProfit";
import NotFound from "@/components/NotFound";
import { apiConfig } from "@/constant/apiConfig";
import { coinsSelect } from "@/state/coinSlice";
import { useAppSelector } from "@/state/hooks";
import { formatPrice } from "@/utils/formatPrice";
import moment from "moment";
import { useEffect, useState } from "react";

const tableHead = [
  "Earn Date",
  "Coins",
  "Amount",
  "APR",
  "Duration",
  "Status",
  "Action",
];

function TableEarn() {
  const listCoins = useAppSelector(coinsSelect);
  const [dataTable, setDataTable] = useState<any>({});
  const [isGetProfit, setIsGetProfit] = useState<any>({
    open: false,
    data: {},
  });
  const [isEarning, setIsEarning] = useState<any>({
    open: false,
    data: {},
  });

  const getListEarn = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.earnProfit.getList,
        body: {
          page: 1,
          size: 30,
          querySearch: "",
          listStatus: "DURING,REQUEST_WITHDRAW",
          // listUserStakingStatus: tab === dataValueTab.profit ? "ENDED" : null,
        },
      });
      if (resp.result) {
        setDataTable(resp.data);
      }
    } catch (error) {
      setDataTable({});
    }
  };

  useEffect(() => {
    getListEarn();
  }, []);

  return (
    <div className="xl:px-0 px-[16px]">
      <Earning
        isVisible={isEarning.open}
        setCancel={() => setIsEarning({ open: false, data: {} })}
        data={{
          ...isEarning.data,
          stakingExpectedProfit: isEarning.data?.expectedProfit,
          stakingStatus: isEarning.data?.status,
          stakingId: isEarning.data?.id,
        }}
        onSuccess={() => getListEarn()}
      />
      <GetProfit
        isVisible={isGetProfit.open}
        setCancel={() => setIsGetProfit({ open: false, data: {} })}
        data={{
          ...isGetProfit.data,
          stakingExpectedProfit: isGetProfit.data?.expectedProfit,
          stakingStatus: isGetProfit.data?.status,
          stakingId: isGetProfit.data?.id,
        }}
        onSuccess={() => getListEarn()}
      />

      <div className="sm:text-[32px] text-[24px] font-montserrat700 leading-none">
        Earn
      </div>

      {dataTable?.datas?.length > 0 ? (
        <>
          {/**table pc */}
          <div className="mt-[36px] mb-[50px] sm:block hidden">
            <div className="border-0 border-b border-[#252525] border-solid pb-[11px] flex">
              {tableHead.map((dt, i) => (
                <div
                  key={i}
                  className="flex-1 text-center text-[#5F5F5F] text-[16px] w-[20%]"
                >
                  {dt}
                </div>
              ))}
            </div>

            {dataTable?.datas?.map((dt: any, i: number) => (
              <div key={i}>
                <div className="text-center text-[#fff] text-[16px] border-0 border-b border-[#252525] border-solid w-full flex items-center py-[15.5px]">
                  <div className="px-[15px] w-[14.2857143%]">
                    <div className="max-w-[100px] mx-auto text-left">
                      {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                    </div>
                  </div>
                  <div className="px-[15px] w-[14.2857143%]">
                    <div className="flex justify-center items-center gap-[10px]">
                      <img
                        src={
                          listCoins?.find(
                            (item: any) => item?.assetSymbol === dt?.assetType
                          )?.assetIcon
                        }
                        alt="img"
                        className="w-[28px] aspect-square"
                      />
                      <div>{dt?.assetType}</div>
                    </div>
                  </div>
                  <div className="px-[15px] w-[14.2857143%]">
                    {formatPrice(dt?.stakingAmount, 4)}
                  </div>
                  <div className="px-[15px] w-[14.2857143%]">
                    {formatPrice(dt?.apr * 100)}%
                  </div>
                  <div className="px-[15px] w-[14.2857143%]">
                    {dt?.daysDuration} days
                  </div>
                  <div className="px-[15px] w-[14.2857143%]">Earning</div>
                  <div className="px-[15px] w-[14.2857143%]">
                    {dt?.status === 1 &&
                    new Date(dt?.endTime).getTime() > new Date().getTime() ? (
                      <button
                        onClick={() =>
                          setIsEarning({
                            open: true,
                            data: dt,
                          })
                        }
                        className="text-[14px] font-montserrat600 h-[32px] w-[100px] text-center border border-solid border-[#B0F127] bg-transparent text-[#B0F127] rounded-[48px] cursor-pointer"
                      >
                        Earning
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          setIsGetProfit({
                            open: true,
                            data: dt,
                          })
                        }
                        className="text-[14px] font-montserrat600 h-[32px] w-[100px] text-center border border-solid border-[#fff] bg-transparent text-[#fff] rounded-[48px] cursor-pointer"
                      >
                        Get profit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/**end table pc */}
          {/**table mobile */}
          <div className="mb-[50px] sm:hidden">
            {dataTable?.datas?.map((dt: any, i: number) => (
              <div
                className="py-[20px] border-0 border-solid border-b border-[#2F2F2F]"
                key={i}
              >
                <div className="flex">
                  <div className="flex-1">
                    <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                      Coins
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <img
                        src={
                          listCoins?.find(
                            (item: any) => item?.assetSymbol === dt?.assetType
                          )?.assetIcon
                        }
                        alt="img"
                        className="w-[28px] aspect-square"
                      />
                      <div>{dt?.assetType}</div>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                      Earn date
                    </div>
                    <div className="text-[14px]">
                      {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                    </div>
                  </div>
                </div>
                <div className="mt-[15px] bg-[#141414] rounded-[12px] p-4 flex flex-col gap-[15px] leading-none">
                  <div className="flex items-center justify-between">
                    <div className="text-[#C0C0C0] text-[13px]">Amount</div>
                    <div>{formatPrice(dt?.stakingAmount, 4)}</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[#C0C0C0] text-[13px]">APR</div>
                    <div>{formatPrice(dt?.apr * 100)}%</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[#C0C0C0] text-[13px]">Duration</div>
                    <div>{dt?.daysDuration} days</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="text-[#C0C0C0] text-[13px]">Status</div>
                    <div>Earning</div>
                  </div>
                  <div>
                    {dt?.status === 1 &&
                    new Date(dt?.endTime).getTime() > new Date().getTime() ? (
                      <button
                        onClick={() =>
                          setIsEarning({
                            open: true,
                            data: dt,
                          })
                        }
                        className="text-[14px] font-montserrat600 h-[32px] w-full text-center border border-solid border-[#B0F127] bg-transparent text-[#B0F127] rounded-[48px] cursor-pointer"
                      >
                        Earning
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          setIsGetProfit({
                            open: true,
                            data: dt,
                          })
                        }
                        className="text-[14px] font-montserrat600 h-[32px] w-full text-center border border-solid border-[#fff] bg-transparent text-[#fff] rounded-[48px] cursor-pointer"
                      >
                        Get profit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/**end table mobile */}
        </>
      ) : (
        <NotFound className="!py-[30px]" />
      )}
    </div>
  );
}

export default TableEarn;
