import { AxiosRequestConfig } from "axios";
import axiosClient from "@/axios/axiosClient";
import { envConfig } from "@/constant/envConfig";

export type HttpProps = {
  method: "get" | "post" | "put" | "patch" | "delete";
  endPoint?: string;
  body?: object;
  type?: "normal" | "option";
  fullUrl?: string;
  requiredToken?: boolean;
  headers?: any;
};

interface CustomRequestConfig extends AxiosRequestConfig {
  requiredToken?: boolean;
}

const sendApiRequest = async <T,>({
  method,
  endPoint = "",
  body = {},
  fullUrl,
  requiredToken = true,
  headers,
}: HttpProps): Promise<T> => {
  if (fullUrl) {
    const resp = await axiosClient
      .request({
        url: fullUrl,
        method,
        requiredToken,
      } as CustomRequestConfig)
      .catch((err: any) => err);

    if (!resp.data) throw resp;
    return resp.data;
  }

  axiosClient.defaults.baseURL = envConfig.API_URL;
  const resp = await axiosClient.request({
    url: endPoint,
    method,
    requiredToken,
    ...{
      [method === "get" ? "params" : "data"]: body,
    },
    headers,
  } as CustomRequestConfig);

  if (!resp.data) throw resp;
  return resp.data;
};

export default sendApiRequest;
