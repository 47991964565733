export const scrollToSection = (url: string | undefined) => {
  if (!url) return; // Kiểm tra nếu url là undefined
  const element = document.getElementById(url);
  if (element) {
    const topOffset = 100;
    const elementPosition =
      element.getBoundingClientRect().top + window.pageYOffset;

    window.scrollTo({
      top: elementPosition - topOffset,
      behavior: "smooth",
    });
  }
};
