import ImgNF from "@/assets/images/nodata.png";

interface Props {
  className?: string;
}

function NotFound({ className }: Props) {
  return (
    <div
      className={`flex justify-center items-center flex-col xs:pt-[113px] pt-[42px] pb-[20px] ${className}`}
    >
      <img src={ImgNF} alt="img" className="xs:max-w-full max-w-[229px]" />
      <div className="text-[#FFFFFFBF] xs:text-[28px] text-[24px] font-montserrat500">
        No data found
      </div>
    </div>
  );
}

export default NotFound;
