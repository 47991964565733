import ModalBase from "@/components/ModalBase";
import Silver from "@/assets/images/membership/m_silver.png";
import Gold from "@/assets/images/membership/m_gold.png";
import Diamond from "@/assets/images/membership/m_diamond.png";
import Black from "@/assets/images/membership/m_black.png";
import { formatPrice } from "@/utils/formatPrice";

interface Props {
  isVisible: boolean;
  setCancel: () => void;
  data: any;
  onSubmit: (data: any) => void;
}

function ModalBuyNowNft({ isVisible, setCancel, data, onSubmit }: Props) {
  console.log("data", data);

  return (
    <ModalBase isVisible={isVisible} setCancel={setCancel} title="" isIconClose>
      <div>
        <div className="text-[#F4F5F7] text-[24px] font-montserrat700 text-center">
          Confirm payment
        </div>
        <img
          src={data?.image}
          alt="img"
          className="rounded-[24px] w-full my-[18px]"
        />
        <div className="leading-none">
          <div className="flex items-center justify-between mb-[12px]">
            <div className="text-[#C0C0C0]">NFT Name</div>
            <div className="font-montserrat700 text-[18px]">
              {data?.productName}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-[#C0C0C0]">Price</div>
            <div className="font-montserrat700 text-[18px] text-[#B0F127]">
              {formatPrice(data?.sellPrice)} {data?.sellAssetType}
            </div>
          </div>
        </div>
        <div className="flex gap-[20px] mt-[28px]">
          <button
            onClick={() => {
              setCancel?.();
            }}
            type="button"
            className="w-full h-[60px] bg-transparent text-[#B0F127] border border-solid border-[#B0F127] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onSubmit(data);
            }}
            type="button"
            className="w-full h-[60px] bg-[#B0F127] border border-solid border-[#B0F127] text-[#060606] flex-1 text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            Confirm
          </button>
        </div>
      </div>
    </ModalBase>
  );
}

export default ModalBuyNowNft;
