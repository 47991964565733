import ModalBase from "@/components/ModalBase";
import Silver from "@/assets/images/membership/m_silver.png";
import Gold from "@/assets/images/membership/m_gold.png";
import Diamond from "@/assets/images/membership/m_diamond.png";
import Black from "@/assets/images/membership/m_black.png";

interface Props {
  isVisible: boolean;
  setCancel: () => void;
  type: string;
}

function ModalBuySuccessNft({ isVisible, setCancel, type }: Props) {
  const handleImg = () => {
    switch (type) {
      case "Black":
        return Black;
      case "Gold":
        return Gold;
      case "Diamond":
        return Diamond;
      default:
        return Silver;
    }
  };

  return (
    <ModalBase isVisible={isVisible} setCancel={setCancel} title="" isIconClose>
      <div>
        <div className="text-[#F4F5F7] text-[24px] font-montserrat700 text-center">
          Buy NFT Successfully
        </div>
        <img src={handleImg()} alt="img" className="mx-auto" />
      </div>
    </ModalBase>
  );
}

export default ModalBuySuccessNft;
