import sendApiRequest from "@/axios/requestApi";
import EstimateBalance from "./EstimateBalance";
import InfoMe from "./InfoMe";
import TableAsset from "./TableAsset";
import TableEarn from "./TableEarn";
import TableMembershipNFT from "./TableMembershipNFT";
import { apiConfig } from "@/constant/apiConfig";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import ImgArrowLeft from "@/assets/img/arrow-left.png";

function MyAccount() {
  const [dataBalance, setDataBalance] = useState<any>({});
  const navigate = useNavigate();
  const getListStatistic = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.user.getStatistic,
      });
      if (resp?.result) {
        setDataBalance(resp.data);
      }
    } catch (error) {
      console.log();
      setDataBalance({});
    }
  };

  useEffect(() => {
    getListStatistic();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="sm:hidden flex items-center justify-between mb-[33px] mx-[16px]">
        <div
          className="min-w-[24px] cursor-pointer"
          // onClick={() => navigate(UrlInternal.HOME)}
          onClick={() => {
            navigate(UrlInternal.HOME, {
              state: {
                isBack: true,
              },
            });
          }}
        >
          <img src={ImgArrowLeft} alt="img" />
        </div>
        <div className="sm:text-[32px] text-[20px] font-montserrat700 text-center">
          My page
        </div>
        <div className="min-w-[24px]"></div>
      </div>

      <div className="max-w-[1280px] mx-auto my-[24px]">
        <InfoMe />
        <EstimateBalance dataBalance={dataBalance} />
        <TableAsset data={dataBalance?.balances} />
        <TableEarn />
        <TableMembershipNFT />
      </div>
    </>
  );
}

export default MyAccount;
