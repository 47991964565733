import moment from "moment";
import ImgUsdt from "@/assets/img/usdt.png";
import ImgBnb from "@/assets/img/bnb.png";
import ImgCopy from "@/assets/img/Copy.png";
import Collapse from "./Collapse";
import { message } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatPrice } from "@/utils/formatPrice";

interface Props {
  data: any;
}

function TableWithdraw({ data }: Props) {
  const handleStatus = (status: string) => {
    switch (status) {
      case "WAITING":
        return <div className="text-[#F1BB27]">Waiting</div>;
      case "COMPLETE":
        return <div className="text-[#B0F127]">Complete</div>;

      default:
        return;
    }
  };

  return (
    <>
      {/**table pc */}
      <div className="mt-[25px] mb-[30px] md:block hidden">
        <div className="border-0 border-b border-[#5F5F5F] border-solid pb-[11px] flex">
          <div className="flex text-center text-[#5F5F5F] text-[16px] flex-1">
            <div className="w-[12%]">Date</div>
            <div className="w-[12%]">Coins</div>
            <div className="w-[12%]">Network</div>
            <div className="w-[11%]">Fee</div>
            <div className="w-[15%]">Receive Amt.</div>
            <div className="w-[12%]">From</div>
            <div className="w-[12%]">To</div>
            <div className="w-[14%]">TxID</div>
            <div className="w-[12%]">Status</div>
          </div>
        </div>

        {data?.map((dt: any, i: number) => (
          <div key={i}>
            <div className="text-center text-[#fff] text-[16px] border-0 border-b border-[#5F5F5F] border-solid w-full flex items-center py-[15.5px] break-words">
              <div className="lg:px-0 px-[10px] w-[12%]">
                <div className="max-w-[100px] mx-auto text-left">
                  {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
              </div>
              <div className="flex gap-[10px] items-center lg:px-0 px-[10px] w-[12%] justify-center">
                <img
                  src={dt?.assetIcon}
                  alt="usdt"
                  className="max-w-[28px] w-full aspect-square"
                />
                <div>{dt?.symbol}</div>
              </div>
              <div className="flex gap-[10px] items-center lg:px-0 px-[10px] w-[12%] justify-center">
                <img
                  src={dt?.networkIcon}
                  alt="bnb"
                  className="max-w-[28px] w-full aspect-square"
                />
                <div>{dt?.networkSymbol}</div>
              </div>
              <div className="lg:px-0 px-[10px] w-[11%]">
                {formatPrice(dt?.withdrawFee, 4)}
              </div>
              <div className="lg:px-0 px-[10px] w-[15%]">
                {formatPrice(dt?.receivedAmount)}
              </div>
              <div className="lg:px-0 px-[10px] w-[12%]">
                {dt?.fromAddress && (
                  <>
                    {dt?.fromAddress?.substring(0, 4)}...
                    {dt?.fromAddress?.substring(dt?.fromAddress?.length - 4)}
                  </>
                )}
              </div>
              <div className="lg:px-0 px-[10px] w-[12%]">
                {dt?.toAddress && (
                  <>
                    {dt?.toAddress?.substring(0, 4)}...
                    {dt?.toAddress?.substring(dt?.toAddress?.length - 4)}
                  </>
                )}
              </div>
              <div className="lg:px-0 px-[10px] w-[14%] lg:flex gap-[5px] items-center">
                {dt?.txHash && (
                  <>
                    <div
                      className="text-[#27DDF1] underline cursor-pointer"
                      onClick={() => window.open(dt?.txHashUrl)}
                    >
                      {" "}
                      {dt?.txHash?.substring(0, 4)}...
                      {dt?.txHash?.substring(dt?.txHash?.length - 4)}
                    </div>
                    <CopyToClipboard
                      text={dt?.txHash}
                      onCopy={() => message.success("Copied")}
                    >
                      <img
                        src={ImgCopy}
                        alt="copy"
                        className="cursor-pointer mx-auto"
                      />
                    </CopyToClipboard>
                  </>
                )}
              </div>
              <div className="lg:px-0 px-[10px] w-[12%]">
                {handleStatus(
                  dt?.status === "WAITING" ? "WAITING" : "COMPLETE"
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/**end table pc */}
      {/**table mobile */}
      <div className="mt-[26px] mb-[28px] md:hidden">
        {data?.map((dt: any, i: number) => (
          <Collapse key={i} dt={dt} />
        ))}
      </div>
      {/**end table mobile */}
    </>
  );
}

export default TableWithdraw;
