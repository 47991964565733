import AvaSilver from "@/assets/images/mypage/ava_silver.png";
import AvaGold from "@/assets/images/mypage/ava_gold.png";
import AvaDiamond from "@/assets/images/mypage/ava_diamond.png";
import AvaBlack from "@/assets/images/mypage/ava_black.png";
import RankSilver from "@/assets/images/mypage/silver.png";
import RankGold from "@/assets/images/mypage/gold.png";
import RankDiamond from "@/assets/images/mypage/diamond.png";
import RankBlack from "@/assets/images/mypage/black.png";
import AvaActive from "@/assets/images/mypage/ava-active.png";

import ImgCopy from "@/assets/img/Copy-white.png";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { UrlInternal } from "@/constant/endpointRoute";
import { dataUserSelect, updateDataUser } from "@/state/authSlice";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { message } from "antd";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import CheckRank from "@/components/CheckRank";
import ImgRightAgency from "@/assets/img/right-agency.svg";
import { envConfig } from "@/constant/envConfig";

const dataAva = [AvaSilver, AvaGold, AvaDiamond, AvaBlack];
const dataRank = [RankSilver, RankGold, RankDiamond, RankBlack];

function InfoMe() {
  const dataUser = useAppSelector(dataUserSelect);
  const refLink = `${window.location.origin}${UrlInternal.SIGNUP}?referralCode=${dataUser?.referralCode}`;
  const [dataLevel, setDataLevel] = useState<any>({});
  // const levelUser: any = 4;
  const levelUser = dataUser?.membershipLevel;
  const dispatch = useAppDispatch();

  const getDetailUser = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.user.details,
      });
      if (resp?.result) {
        console.log("resp", resp);
        getDataLevel(resp.data.membershipLevel);
        dispatch(updateDataUser(resp.data));
      }
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    getDetailUser();
  }, []);

  const getDataLevel = async (membershipLevel: number) => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.user.getLevel,
      });
      if (resp?.result) {
        const dataFilter = resp.data.find(
          (item: any) => item?.level === membershipLevel
        );
        // const dataFilter = resp.data.find(
        //   (item: any) => item?.level === levelUser
        // );
        setDataLevel(dataFilter);
      }
    } catch (error) {
      setDataLevel({});
    }
  };

  // useEffect(() => {
  //   getDataLevel();
  // }, []);

  const checkPositionWrapperAva = () => {
    switch (levelUser) {
      case 4:
        return "top-[-45px]";
      case 3:
        return "top-[-30px]";
      case 2:
        return "top-[-20px]";
      default:
        return "top-[-10px]";
    }
  };
  const checkPositionAva = () => {
    switch (levelUser) {
      case 4:
        return "md:top-[50px] top-[30px]";
      case 3:
        return "md:top-[42px] top-[26px]";
      case 2:
        return "md:top-[30px] top-[18px]";
      default:
        return "md:top-[30px] top-[20px]";
    }
  };

  const checkMaxWidthMobile = () => {
    switch (levelUser) {
      case 4:
        return "max-w-[95px]";
      case 3:
        return "max-w-[120px]";
      case 2:
        return "max-w-[120px]";
      default:
        return "max-w-[80px]";
    }
  };

  return (
    <>
      {dataUser?.isAgency && (
        <div className="md:flex hidden justify-end mt-[38px]">
          <div
            className="inline-flex items-center gap-[11px] justify-end cursor-pointer"
            onClick={() => window.open(envConfig.DEFYLO_AGENCY)}
          >
            <div className="text-[#EFB90B] font-montserrat600">
              Go to agency page
            </div>
            <img src={ImgRightAgency} alt="img" />
          </div>
        </div>
      )}

      <div
        className={`bg-gradient-mypage rounded-[50px] sm:mb-[80px] mb-[12px] md:mt-[12px] mt-10 ${
          !dataUser?.isAgency && "md:mt-[50px] mb-[40px]"
        }`}
      >
        <div className="sm:mx-[40px] mx-[16px] relative sm:py-[24px] pt-[16px] pb-[27px] flex lg:items-center lg:flex-row flex-col">
          <div className="flex md:gap-[30px] gap-[9px] md:static relative lg:pb-0 pb-[42px] lg:mb-0 mb-[16px] md:w-auto w-full">
            <div className="w-full h-[1px] absolute bottom-0 left-0 bg-[#547907] lg:hidden"></div>

            {levelUser > 0 ? (
              <div
                className={`${
                  levelUser === 4 ? "md:min-w-[152px]" : "md:min-w-[182px]"
                  // levelUser === 4 ? "md:max-w-[152px]" : "md:max-w-[182px]"
                } w-full ${checkMaxWidthMobile()}`}
              >
                <div
                  className={`absolute left-0 ${
                    levelUser === 4 || levelUser === 3
                      ? "md:bottom-[-13px]"
                      : "md:bottom-0"
                  } ${checkPositionWrapperAva()}`}
                >
                  <div className="relative z-10">
                    <img
                      src={dataAva[levelUser - 1]}
                      alt="ava"
                      className={`md:max-w-max ${checkMaxWidthMobile()}`}
                    />
                    <img
                      src={AvaActive}
                      alt="img"
                      className={`absolute left-[50%] -translate-x-1/2 md:w-[80px] w-[55px] z-[-1] aspect-square ${checkPositionAva()}`}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <img
                src={AvaActive}
                alt="img"
                className={`md:w-[80px] min-w-[55px] z-[-1] aspect-square object-cover`}
              />
            )}
            <div className="relative lg:pr-[36px]">
              <div className="w-[1px] h-10 absolute right-0 top-1/2 -translate-y-1/2 bg-[#547907] lg:block hidden"></div>

              <div className="text-[#B0F127] font-montserrat700 sm:text-[24px] text-[16px]">
                {dataUser?.email}
              </div>
              <div className="xs:flex items-center sm:mt-[8px] mt-[2px] gap-[4px] whitespace-nowrap">
                <div className="text-[#C0C0C0] font-montserrat500">
                  Current rank:
                </div>
                <div className="flex gap-[4px] items-center sm:text-[16px] text-[14px]">
                  <div>{dataLevel?.gradeName}</div>
                  <CheckRank rank={levelUser} />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:pl-[36px] flex md:gap-[32px] gap-[8px] justify-between md:items-center md:flex-row flex-col">
            <div className="md:block flex items-center justify-between">
              <div className="text-[#C0C0C0] md:mb-[8px] md:text-[16px] text-[14px]">
                UID:
              </div>
              <div className="flex items-center gap-2">
                <div className="md:text-[18px] text-[14px]">
                  {dataUser?.secretKeyBeInvited?.substring(0, 8)}...
                  {dataUser?.secretKeyBeInvited?.substring(
                    dataUser?.secretKeyBeInvited.length - 10,
                    dataUser?.secretKeyBeInvited.length
                  )}
                </div>
                <CopyToClipboard
                  text={dataUser?.secretKeyBeInvited}
                  onCopy={() => message.success("Copied")}
                >
                  <img
                    src={ImgCopy}
                    alt="copy"
                    className="cursor-pointer mx-auto"
                  />
                </CopyToClipboard>
              </div>
            </div>
            <div className="md:block flex items-center justify-between">
              <div className="text-[#C0C0C0] md:mb-[8px] md:text-[16px] text-[14px]">
                Ref code:
              </div>
              <div className="flex items-center gap-2">
                <div className="md:text-[18px] text-[14px]">
                  {dataUser?.referralCode}
                </div>
                <CopyToClipboard
                  text={dataUser?.referralCode}
                  onCopy={() => message.success("Copied")}
                >
                  <img
                    src={ImgCopy}
                    alt="copy"
                    className="cursor-pointer mx-auto"
                  />
                </CopyToClipboard>
              </div>
            </div>
            <div className="md:block flex items-center justify-between">
              <div className="text-[#C0C0C0] md:mb-[8px] md:text-[16px] text-[14px]">
                Ref link:
              </div>
              <div className="flex items-center gap-2">
                <div className="md:text-[18px] text-[14px]">
                  {refLink?.substring(0, 8)}...
                  {refLink?.substring(refLink.length - 10, refLink.length)}
                </div>
                <CopyToClipboard
                  text={refLink}
                  onCopy={() => message.success("Copied")}
                >
                  <img
                    src={ImgCopy}
                    alt="copy"
                    className="cursor-pointer mx-auto"
                  />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>

      {dataUser?.isAgency && (
        <div className="flex md:hidden justify-end mb-10 px-4">
          <div
            className="inline-flex items-center gap-[11px] justify-end cursor-pointer"
            onClick={() => window.open(envConfig.DEFYLO_AGENCY)}
          >
            <div className="text-[#EFB90B] font-montserrat600 text-[14px]">
              Go to agency page
            </div>
            <img src={ImgRightAgency} alt="img" />
          </div>
        </div>
      )}
    </>
  );
}

export default InfoMe;
