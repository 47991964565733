import { formatPrice } from "@/utils/formatPrice";

interface Props {
  value: number;
  valueTarget: number;
  isComingSoon?: boolean;
  content?: string;
}

function Progress({
  value = 0,
  valueTarget = 0,
  isComingSoon,
  content,
}: Props) {
  return (
    <div className={`md:text-left text-right`}>
      {isComingSoon ? (
        <>
          <div className="h-[14px] border-[2px] border-solid border-[#5F5F5F] rounded-[50px]">
            <div
              className={`bg-[#5F5F5F] h-full`}
              style={{
                width: `40%`,
              }}
            />
          </div>
          <div
            className={`text-[13px] font-montserrat500 text-[#5F5F5F] mt-[3px] ${content}`}
          >
            0/ 0
          </div>
        </>
      ) : (
        <>
          <div className="h-[14px] border-[2px] border-solid border-[#B0F127] rounded-[50px]">
            <div
              className={`bg-[#B0F127] h-full`}
              style={{
                width: `${(value / valueTarget) * 100}%`,
              }}
            />
          </div>
          <div
            className={`text-[13px] font-montserrat500 text-[#B0F127] mt-[3px] ${content}`}
          >
            {formatPrice(value)}/ {formatPrice(valueTarget)}
          </div>
        </>
      )}
    </div>
  );
}

export default Progress;
