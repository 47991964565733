import Home from "@/pages/Home";
import { ConfigProvider } from "antd";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "swiper/css";
import sendApiRequest from "./axios/requestApi";
import Loader from "./components/Loader";
import { apiConfig } from "./constant/apiConfig";
import { UrlInternal } from "./constant/endpointRoute";
import DefaultLayout from "./layout/DefaultLayout";
import LayoutPrimary from "./layout/LayoutPrimary";
import ForgotId from "./pages/Auth/ForgotId";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import Convert from "./pages/Convert";
import ConvertHistory from "./pages/ConvertHistory";
import HistoryEarnProfit from "./pages/HistoryEarnProfit";
import MyAccount from "./pages/Mypage/MyAccount";
import PrivateRoute from "./Routes/PrivateRoute";
import { setListCoin } from "./state/coinSlice";
import { isLoadingSelect } from "./state/globalSlice";
import { useAppDispatch, useAppSelector } from "./state/hooks";
import MembershipNft from "./pages/MembershipNft";

function App() {
  const isLoading = useAppSelector(isLoadingSelect);
  const dispatch = useAppDispatch();

  const getListCoins = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.coins.getList,
      });
      if (resp?.result) {
        dispatch(setListCoin(resp.data));
      }
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    getListCoins();
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            trackMinWidth: 56,
            trackHeight: 36,
            handleSize: 28,
            trackPadding: 2,
          },
        },
      }}
    >
      {isLoading && <Loader />}
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Home />} />

          <Route
            path={UrlInternal.HISTORY_EARN_PROFIT}
            element={<HistoryEarnProfit />}
          />

          <Route path={UrlInternal.CONVERT} element={<Convert />} />
          <Route
            path={UrlInternal.CONVERT_HISTORY}
            element={<ConvertHistory />}
          />

          <Route element={<PrivateRoute />}>
            <Route path={UrlInternal.MYPAGE}>
              <Route path="" index element={<MyAccount />} />
            </Route>
          </Route>

          <Route
            path={UrlInternal.MEMBERSHIP_NFT}
            element={<MembershipNft />}
          />
        </Route>

        <Route element={<LayoutPrimary />}>
          <Route path={UrlInternal.LOGIN} element={<Login />} />
          <Route path={UrlInternal.SIGNUP} element={<SignUp />} />
          <Route path={UrlInternal.FORGOT_ID} element={<ForgotId />} />
          <Route
            path={UrlInternal.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}

export default App;
