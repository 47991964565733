import banner from "@/assets/images/banner.png";
import { UrlInternal } from "@/constant/endpointRoute";
import { isAuthSelect } from "@/state/authSlice";
import { useAppSelector } from "@/state/hooks";
import { scrollToSection } from "@/utils/scrollToSection";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();
  const isAuth = useAppSelector(isAuthSelect);
  // const scrollToSection = (url: string | undefined) => {
  //   if (!url) return; // Kiểm tra nếu url là undefined
  //   const element = document.getElementById(url);
  //   if (element) {
  //     const topOffset = 100; // Khoảng cách offset
  //     const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;

  //     window.scrollTo({
  //       top: elementPosition - topOffset,
  //       behavior: 'smooth',
  //     });
  //   }
  // };

  return (
    <div
      className="relative md:pt-[45px] md:pb-[239px] max-w-[1364px] px-4 md:mb-[89px] mb-[51px] md:mt-[89px] mt-[11px] md:py-0 w-full flex flex-col md:flex-row items-center justify-between m-auto gap-3"
      id="about"
    >
      <div className="flex-1 md:pl-[26px]">
        <div className="font-BlackOpsOne text-[26px] md:text-[50px] md:text-left text-center uppercase">
          <div className="w-max">
            <span className="text-[#B0F127]">DeFi x</span> Real Assets.
          </div>
          <div className="w-max">
            <span className="text-[#B0F127]">AI-Powered</span> Arbitrage.
          </div>
        </div>
        <div className="md:max-w-[480px] text-[16px] md:text-[18px] font-[400] text-[#C0C0C0] md:text-left text-center md:mt-[28px] mt-[16px]">
          Empowering Your Financial Freedom with Simple, Secure, and High-Reward
          Staking.
        </div>
        <div className="flex flex-col md:flex-row gap-6 md:mt-[47px] my-10">
          <div
            className="flex items-center justify-center h-[60px] w-full md:w-[193px] text-center bg-[#B0F127] text-[#060606] text-[18px] font-montserrat700 rounded-[48px] cursor-pointer"
            onClick={() => scrollToSection("Earn")}
          >
            Start earning
          </div>
          {!isAuth && (
            <div
              className="flex items-center justify-center h-[60px] w-full md:w-[193px] text-center bg-transparent text-[18px] font-montserrat700 rounded-[48px] cursor-pointer border border-solid border-[#fff]"
              onClick={() => navigate(UrlInternal.SIGNUP)}
            >
              Sign up now
            </div>
          )}
        </div>
      </div>
      <div className="w-full max-w-[830px] md:mr-[-50px] xl:absolute top-0 right-0">
        <img src={banner} alt="" />
      </div>
    </div>
  );
}

export default Banner;
