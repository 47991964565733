import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./authSlice";
import globalSlice from "./globalSlice";
import coinsSelect from "./coinSlice";
import filtersSelect from "./filterSlice";
import withdrawSelect from "./withdrawSlice";
import dataBalanceSlice from "./dataBalanceSlice";
// import rootReducer from "./reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  global: globalSlice,
  auth: authSlice,
  coins: coinsSelect,
  filters: filtersSelect,
  withdraw: withdrawSelect,
  balance: dataBalanceSlice,
  // auth: persistReducer({ key: "auth", storage }, authSlice),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);

export const clearAllData = () => {
  persistor.purge();
};
